import React, {useEffect, useState, useRef} from "react";
import Tab from "react-bootstrap/Tab";
import {Col, Row} from "reactstrap";
import { Loading } from "../LoadingComponent";
import credentials from "../../config/credentials";
import { trackPromise, usePromiseTracker } from "react-promise-tracker"
import {Typography} from "@mui/material";
import ReactMarkDownComponent from "./ReactMarkDownComponent";


function displayContent(data) {
  return (
    <div>
      <ReactMarkDownComponent input={data}/>
    </div>
  )
}

export default function DynamicArticlesComponent() {

  const [content, setContent] = React.useState('')
  const [dateUpdate, setDateUpdate] = React.useState('01-Jan-2023')

  useEffect( () => {
    let articleId = window.location.pathname.split('/')[3]
    if (articleId) {
      const fetchContent = fetch(`${credentials.API_URL}/article-list/?aid=${articleId}`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      })
      trackPromise(
        fetchContent.then(response=>response.json())
          .then(data => {
            setContent(data.article[0].content)
            setDateUpdate(data.article[0].date_created)
          })
      )
    }
  }, [])

  return (
    <div>
      <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h4 className="page-header-content" style={{ color: '#b60811'}}></h4>
                  <hr className="fancy-line" />
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                    <div style={{height: "10px", fontSize: "10px", color: "gray"}}>
                      <i className="far fa-star" style={{fontSize:"10px", paddingRight: "10px"}}>  </i>
                      {dateUpdate} - 5 min read
                    </div>
                  </div>
                  <br />
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12} className="">
                        <div style={{padding: "30px", paddingTop: "5px"}}>
                          {content ?
                            <div>{displayContent(content)}</div>
                          :
                            <div>
                              <Loading/>
                            </div>
                          }
                        </div>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}