import Home from "./../components/SearchPartnerEB/SearchEBComponent";
import { connect } from "react-redux";
import {sideMenuAction, vehicleAction} from "./../store/actions";

const mapStateToProps = state => {
  const {
    authReducer: { isLoggedIn },
    vehicleReducer: {
      searchItemSuccess,
      searchItemError,
      searchItemLoader
    }
  } = state;
  return {
    searchItemSuccess,
    searchItemError,
    searchItemLoader,
    filterReducer: state.filterReducer,
    sideMenuReducer: state.sideMenuReducer,
    isLoggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchItem: payload => dispatch(vehicleAction.searchItem(payload)),
    sideMenuAction: payload => dispatch(sideMenuAction.setSideMenu(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
