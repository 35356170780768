import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
} from 'reactstrap';
import { withCookies } from 'react-cookie';
import {Link} from "react-router-dom";
import DownlineRecruitsCards from "../DownlineRecruitsCardsComponent"
import LazyLoad from "react-lazyload";
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Modal from 'react-modal';


class Downline extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
        recruitEmail: "",
        error: false,
        message: "",
        redirect: false,
        downLineRecruits: {},
        isPaneOpen: false,
        isPaneOpenLeft: false,
        recruitPerformance: [{}]
    };

    this.onOpenSidePane = this.onOpenSidePane.bind(this)
    this.onTitle = this.onTitle.bind(this)

  }

  componentDidMount() {

    const { history, location, isLoggedIn } = this.props;
    const token = localStorage.getItem('token')
    if (!isLoggedIn) {
      history.push("/login", location);
    }
    let headers = { Authorization: `token ${token}` };
    this.props.getUserProfile && this.props.getUserProfile(headers);
    this.props.getAgentProfile && this.props.getAgentProfile(headers);
    document.body.scrollTop = 0
    window.scrollTo(0,0);
    Modal.setAppElement(this.el);
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.getAgentProfileSuccess && prevProps.getAgentProfileSuccess !== this.props.getAgentProfileSuccess) {
      const statusCode = this.props.getAgentProfileSuccess.status
      if (statusCode === 204) {
        this.setState({isAgent: false})
      } else if (statusCode === 200) {
        let response = this.props.getAgentProfileSuccess.response
        this.setState({isAgent: true,
                              downLineRecruits: response.partner_recruits})
      }
    }
  }

  onOpenSidePane = (e) => {
    const { downLineRecruits } = this.state
    let dLevel1 = downLineRecruits["recruits_level_1"]
    let recruitPerformance = dLevel1.find(o => o[e])[e]
    this.setState({ isPaneOpenLeft: true, recruitEmail: e, recruitPerformance: recruitPerformance })
  }

  onTitle = (e) => {
    this.setState({recruitEmail: e})
  }


  render(){
    const { downLineRecruits, isAgent, recruitPerformance } = this.state
    let downlineLevel1 = [{}]

    if (isAgent) {
      downlineLevel1 = downLineRecruits["recruits_level_1"]
      // let res = downlineLevel1.map(x => Object.keys(x)[0]);
    }
    const noOfRecruits = downlineLevel1.length

    return(
     <div ef={ref => this.el = ref}>
       <div className="container cpanel">
         <Breadcrumb>
           <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
           <BreadcrumbItem><Link to="" >Account</Link></BreadcrumbItem>
           <BreadcrumbItem active>My Downline</BreadcrumbItem>
         </Breadcrumb>

         <div className="row">
           <div className="page-header col-12">
             <h4 className="page-header-content">My Downline</h4>
             <hr className="fancy-line" />
           </div>
           <div className="container">
              <div className="row">
                <Col sm={6} className="">
                <ListGroup style={{margin: "15px"}}>
                  <ListGroupItem style={{backgroundColor: '#2e6399', textAlign: 'center'}}>
                    <ListGroupItemHeading style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>Total Recruits</ListGroupItemHeading>
                    <ListGroupItemText style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
                      {noOfRecruits}
                    </ListGroupItemText>
                  </ListGroupItem>
                </ListGroup>
                </Col>
                <Col sm={6} className="">
                <ListGroup style={{margin: "15px"}}>
                  <ListGroupItem style={{backgroundColor: '#2e6399', textAlign: 'center'}}>
                    <ListGroupItemHeading style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>Profit</ListGroupItemHeading>
                    <ListGroupItemText style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
                    $0
                    </ListGroupItemText>
                  </ListGroupItem>
                </ListGroup>
                </Col>
              </div>
              <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
              </div>
            </div>
           <div className="col-12" style={{textAlign: "center", fontWeight: 900, color: 'gray', paddingTop: '20px'}}>
             <p> RECRUITS - LEVEL 1 </p>
           </div>
           <div className="container" style={{paddingBottom: "40px"}}>
             <div className="row">
               {downlineLevel1.map((d, i) => {
                 return (
                   <LazyLoad height={200} offset={100}>
                     <DownlineRecruitsCards d={d} i={i} onOpen={this.onOpenSidePane} onTitle={this.onTitle}>
                     </DownlineRecruitsCards>
                   </LazyLoad>
                 );
               })}
             </div>
             <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
             </div>
           </div>
         </div>
       </div>

       <SlidingPane
         isOpen={ this.state.isPaneOpenLeft }
         from='left'
         width='60%'
         onRequestClose={ () => this.setState({ isPaneOpenLeft: false }) }>
         <div>
           <div>
             {this.state.recruitEmail}
           </div>
         <hr/>
         <div style={{fontSize: "14px", textAlign: "center", fontWeight: 900, paddingBottom: "10px"}}>
             ORDERS
         </div>
         {recruitPerformance.length > 0 ?
           <div>
             {recruitPerformance.map((rec, idx) => {
               return (
                 customSlideTable(rec["order_number"], rec["compensation_status"])
               )
             })}
           </div>
         :
           <div style={{fontSize: "14px"}}>
             No records
           </div>
         }
         </div>
       </SlidingPane>
     </div>
      );
    }
}
export default withCookies(Downline);

const customSlideTable = (text1, text2) =>
  {
    return text2 &&
    <div className='parent'>
      <div className='fixed' style={{textAlign: 'center'}}>
         #{text1}
      </div>
      <div className='fluid'>
          <span style={{float: 'left'}}>Commission </span> <span style={{float: 'right'}}>{text2}</span>
      </div>
    </div>
  };

