import PasswordResetComponent from './../components/PasswordResetComponent';
import { connect } from "react-redux";
import { authAction } from "./../store/actions";

const mapStateToProps = state => {
    const {
        authReducer: {
            passwordResetConfirmSuccess,
            passwordResetConfirmError,
            passwordResetConfirmLoader
        }
    } = state;
    return {
        passwordResetConfirmSuccess,
        passwordResetConfirmError,
        passwordResetConfirmLoader
    };
};

const mapDispatchToProps = dispatch => {
    return {
        passwordResetConfirm: payload =>
          dispatch(authAction.passwordResetConfirm(payload))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordResetComponent);