import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function CompatibleCardsComponent(props) {
  return (
    <div className="col-lg-2 col-md-2 col-3 mt-2">
      <Card variant="outlined" sx={{ minWidth: 175, border: "2px solid #797979", borderRadius: "8px"}}>
        <CardContent>
          {/*<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>*/}
          {/*  {props.idx}*/}
          {/*</Typography>*/}
          <Typography sx={{ mb: 1.5, fontWeight: 'bold' }} variant="h7" component="div">
            {props.kn}
          </Typography>
          <Typography sx={{ mb: 1.5, fontWeight: 'medium' }} color="text.secondary">
            {props.models[0]} ...
          </Typography>
          <Typography
            variant="body2"
            style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
          >
            {props.vars[0]} ...
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={e => props.populate(e,
              props.kn, props.models, props.vars, props.trims)}
            size="small">Edit</Button>
        </CardActions>
      </Card>
    </div>
  );
}