import React, { Component } from "react";
import {Alert, Form, FormGroup, Input, Label} from 'reactstrap';
import {Helmet} from "react-helmet";
import PartsHomeComponent from "./PartsHomeComponent";
import {Col, Row, Container} from "reactstrap";
import TreeMenuComponent from "./TreeMenuComponent";
import SearchResGrid from "./Search/searchResGrid";

class AutoParts extends Component{
  constructor(props) {
    super(props);
    this.autoRequestRef = React.createRef()
    this.state = {
        userName: "",
        userEmail: "",
        contactNumber: "",
        userRequest: "",
        error: false,
        message: "",
        categories: {},
        currentSearchResults: [],
        keyword: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTreeSelect = this.handleTreeSelect.bind(this);
  }

  componentDidMount() {
    document.body.scrollTop = 0
    window.scrollTo(0,0);
    let payload = {parts: "true", catID: null}
    this.props.getProductCategories(payload)
    let URLParam = this.props.match.params.catSlug
    if (URLParam === "Vehicle Parts & Accessories" || URLParam === undefined) {
      return
    }
    let payloadURL = `?parts-cat=${URLParam}`
    this.props.getAllProducts(payloadURL)

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { getProductCategoriesSuccess, getAllProductsSuccess } = this.props
    if (getProductCategoriesSuccess &&
      prevProps.getProductCategoriesSuccess !== getProductCategoriesSuccess) {
      this.setState({
        categories: getProductCategoriesSuccess
      })
    }
    if (getAllProductsSuccess &&
      prevProps.getAllProductsSuccess !== getAllProductsSuccess) {

      this.setState({
        currentSearchResults: getAllProductsSuccess,
        keyword: this.props.match.params.catSlug
      })
    }
    if (prevProps.match.params !== this.props.match.params) {
      let payloadURL = `?parts-cat=${this.props.match.params.catSlug}`
      this.props.getAllProducts(payloadURL)
      this.setState({
        keyword: this.props.match.params.catSlug
      })
    }
  }

  handleChange(event) {
      this.setState({value: event.target.value});
      const target = event.target;
      const name = target.name;
      this.setState({
          [name]: target.value
      })
  }

  handleTreeSelect(event, value) {
    if (event.target.textContent === ""){
      return
    }
    const { history } = this.props;
    history.push({
      pathname: `/auto-parts/${event.target.textContent}`,
    });
  }

  handleSubmit(event) {
    event.preventDefault()
    const { userName, userEmail, contactNumber, userRequest } = this.state

    if (userName === "" || userEmail === "" || contactNumber === "" || userRequest === "") {
      this.autoRequestRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      this.setState({error: true, message: "Missing information - Email, Name, Phone number and Request required"})
      return;
    }

    this.sendRequest(process.env.REACT_APP_EMAILJS_TEMPLATEID, this.state.userName, this.state.userEmail,
        process.env.REACT_APP_EMAILJS_RECEIVER, this.state.userRequest, this.state.contactNumber)
    alert('Thank you, ' + userName + ' . A wholesale request was submitted. ' +
      'One of agents will be in touch with you on (' + contactNumber +  ') within 12hrs to discuss further')

  }

  sendRequest(templateId, userName, userEmail, receiverEmail, userRequest, contactNumber) {
    window.emailjs.send(
      'sendgrid',
      templateId,
        {
          userName,
          userEmail,
          receiverEmail,
          contactNumber,
          userRequest
        })
        .then(res => {
          this.setState({
              userName: "",
              userEmail: "",
              contactNumber: "",
              userRequest: ""})
        })

        .catch(err => console.error('Failed to send feedback. Error: ', err))
  }

  renderPartsResults = (currentSearchResults, keyword) => {
     if (currentSearchResults.length === 0) {
       let urlParam = this.props.match.params.catSlug
       if (urlParam === undefined || urlParam === "Vehicle Parts & Accessories") {
         return (
           <div>
             <PartsHomeComponent/>
           </div>
         )
       } else {
         return (
           <div className="search-res-metadata-grid">
             Oops, no exact matches found for <span style={{color: "#ff4800"}}>"{keyword}"</span> </div>
         )
       }
     } else {
        return (
          <div>
            <div className="search-res-metadata-grid">
              Match results found for{" "}
              <span style={{color: "#ff4800"}}>"{keyword}"</span>
            </div>
            <div className="row">
              {currentSearchResults.map((data, index ) => {
                if (data) {
                  return (
                    <SearchResGrid d={data} i={index} key={index}>
                    </SearchResGrid>
                  )
                }
                return null;
              })}
            </div>
          </div>
        )
     }
  }


  render(){

    const { currentSearchResults, keyword } = this.state;
    let { categories } = this.state
    let displayCat = false
    if (Object.keys(categories).length > 0){
      categories = categories["Vehicle Parts & Accessories"]["Car Parts & Accessories"]
      displayCat = true
    }

    return(

      <div>
        <Helmet>
          <meta name="description"
                content="Find best deals on Auto, Vehicle Parts and much more." />
          <title>Magari World - Spares</title>
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">

              <div className="static-info-page">
                <div className="" >
                  <h4 className="page-header-content" style={{ textAlign: 'center' }}>
                    Spare parts catalogue</h4>
                  <hr />
                  <br />
                  <Container>
                    <Row>
                      <Col xs="12" sm="3" className="category-nav-col">
                        { displayCat &&
                          <div>
                            <div>
                              <h5 style={{ textAlign: 'left', padding: '16px 8px', fontWeight: 800}}>
                                Select Category</h5>
                            </div>
                            <TreeMenuComponent
                              categories={categories}
                              onTreeSelect={this.handleTreeSelect}
                            />
                          </div>
                        }
                      </Col>
                      <Col xs="12" sm="9">
                        <div style={{textAlign: 'left'}}>
                          <div className="row">
                            {this.renderPartsResults(currentSearchResults, keyword)}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <br />
                  <br />
                </div>
              </div>
           </div>
           </div>
          </div>
        </section>
      </div>
      );
    }
}
export default AutoParts;
