import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Link, withRouter} from 'react-router-dom';
import { withCookies } from 'react-cookie';
import VisaLogo from '../../assets/images/visa.png';
import onImage from '../../assets/images/no-image.jpg';
import CarDetail from './CarDetailComponent';
import CarBuyDetail from './CarBuyDetailComponent';
import CarBuyAck from './CarBuyAckComponent';
import SomeComponent from './ReviewOrder'
import ConfirmOrder from './ConfirmOrder'
import search from './../helpers/SearchMethod'
import Stepper from 'react-stepper-horizontal';
import {compose} from "redux";
import {Helmet} from "react-helmet";


class Car extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: "",
            upc: "",
            id: 0,
            title: "",
            description: "",
            structure: "",
            date_created: "",
            date_updated: "",
            recommended_products: [],
            attributes: [],
            categories: [],
            product_class: "",
            stockrecords: "",
            images: [],
            price: "",
            PriceObj: {},
            availability: "",
            options: [],
            children: [],
            specs: [],
            specsOther: [],
            port: "",
            countries: [],
            destinationCountry: "",
            destinationPort: "",
            destinationCode: "",
            basket: {},
            MultiView: 0,
            message: "",
            userEmail: "",
            firstName: "",
            lastName: "",
            contactNumber: "",
            line1: "",
            line2: "",
            city: "",
            province: "",
            country: "Albania",
            email: "",
            userTitle: "",
            activeStep: 1,
            showModal: false,
            isModalOpen: false,
            modalMessage: "",
            shippingChargeOption: {},
            totalCost: 0,
            userSelectedDestination: {}
        }
    }


    componentDidMount() {
        this._isMounted = true;
        const { location: { state } } = this.props;
        const self = this;
        if (state && state.destinationCountry && state.destinationPort) {
            self.setState({ destinationCountry: state.destinationCountry, destinationPort: state.destinationPort })
        }
        this.props.getCountries && this.props.getCountries();

        const { MultiView } = this.props;
        if (this.props.location.pathname === "/checkout") {
            const basket = this.props.location.state.results
            const destinationPort = this.props.location.state.destinationPort
            const destinationCode = this.props.location.state.destinationCode
            const shippingChargeOption = this.props.location.state.shippingChargeOption
            const userSelectedDestination = this.props.location.state.userSelectedDestination
            const totalCost = this.props.location.state.totalCost
            this.setState({ MultiView });
            this.setState({ basket: { ...basket },
              destinationPort: destinationPort, destinationCode: destinationCode,
              shippingChargeOption: shippingChargeOption, totalCost: totalCost,
              userSelectedDestination: userSelectedDestination })
        }
        if (this.props.location.pathname === "/review-order"){
             this.setState({
            ...this.props.location.state
            })
            this.setState({
              MultiView: 3,
              activeStep: 2
            })
        }
        if (this.props.location.pathname === "/confirm-order"){
          this.setState({
            MultiView: 4,
            activeStep: 3
          });
        }



    }

    toggle = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      showModal: false,
    });
    }

    onChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [`${name}`]: value });
    }


    handleKeyPress(target) {
  }

    onBuy = (MultiView, mobile) => {
      const { history } = this.props;
      history.push({
          pathname: '/review-order',
          state: this.state
      });
      this.setState({ MultiView, message: "", activeStep: 1 });
    }

  onMyAccount = () => {
    const { history } = this.props;
    history.push({
        pathname: '/profile'
    })
  }

  onContinueShopping = () => {
    const { history } = this.props;
    history.push({
      pathname: '/'
    })
  }

  onLogin = () => {
    const { history } = this.props;
    history.push({
        pathname: '/login'
    })
  }

  onConfirm = (MultiView) => {

    const token = localStorage.getItem('token')
    const shippingCharge = this.state.shippingChargeOption.value
    let { getCountriesSuccess } = this.props;

    const selectedCountry = search(this.state.userSelectedDestination.name, getCountriesSuccess["results"], "name").url
    const obj = {
      "currency": "USD",
      "incl_tax": shippingCharge,
      "excl_tax": shippingCharge,
      "tax": "0.0"
      }
    const shippingAddress = {
      title: this.state.userTitle,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      line1: this.state.line1,
      line2: this.state.line2,
      line3: "",
      line4: "",
      state: this.state.province,
      postcode: "",
      phone_number: this.state.contactNumber,
      tel_number: this.state.contactNumber,
      notes: "",
      country: selectedCountry
      }
    const billingAddress = {
      title: this.state.userTitle,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      line1: this.state.line1,
      line2: this.state.line2,
      line3: "",
      line4: "",
      state: this.state.province,
      postcode: "",
      phone_number: this.state.contactNumber,
      notes: "",
      country: selectedCountry
      }
    const paymentObj = {
      cash: {
        enabled: true,
        amount: this.state.totalCost
      }
    }

    this.props.placeOrder && this.props.placeOrder({
      dataPayload: {
        basket: this.state.basket[0].basket,
        total: this.state.totalCost,
        shipping_charge: JSON.stringify(obj),
        shipping_method_code: this.state.destinationCode,
        shipping_address: JSON.stringify(shippingAddress),
        billing_address: JSON.stringify(billingAddress),
        payment: JSON.stringify(paymentObj)},
      sessionToken: token })

  }

    componentDidUpdate(prevProps) {
      let { getProductsSuccess, addProductToBasketSuccess, placeOrderSuccess, placeOrderError } = this.props;
      if (getProductsSuccess && prevProps.getProductsSuccess !== getProductsSuccess) {
        this.setState({ url: getProductsSuccess.url });
      } else if (addProductToBasketSuccess && prevProps.addProductToBasketSuccess !== addProductToBasketSuccess) {
        this.setState({ MultiView: 2, message: "" });
      } else if (placeOrderSuccess && prevProps.placeOrderSuccess !== placeOrderSuccess) {
        const { history } = this.props
        history.push({
          pathname: '/confirm-order',
          state: this.state
        })
      } else if (placeOrderError && prevProps.placeOrderError !== placeOrderError) {
        if (placeOrderError.includes("401")) {
          this.setState({ showModal: true, isModelOpen: true, modalMessage: "401" });
        } else {
          this.setState({ showModal: true, isModalOpen: true, modalMessage: placeOrderError})
        }
      }
    }

    onBuyOrder = (MultiView, mobile) => {
      const { url, destinationCountry, destinationPort } = this.state;
      const { history, cookies, location } = this.props;
      const mv = mobile ? 1 : 0;
      if (cookies.get('email') === undefined) {
        history.push("/login", { redirect: location, destinationCountry, destinationPort });
        return;
      }
      if (destinationCountry === "" || destinationPort === "") {
        this.setState({ message: "Destination missing", MultiView: mv });
        return;
      }
      else {
        this.props.addProductToBasket && this.props.addProductToBasket({ endpointAndQuantity: {url, quantity: 1},
                                                                           sessionToken: this.props.allCookies });
      }
    }


    render() {
      var { state } = this;
      let { getCountriesSuccess, getSpecificationsSuccess, getPriceSuccess, placeOrderLoader } = this.props;
      let finalData = null;
      const actionStep  = state.activeStep
      if ( getCountriesSuccess && getSpecificationsSuccess && getPriceSuccess) {
        finalData = {
          message: state.message,
          destinationCountry: state.destinationCountry,
          destinationPort: state.destinationPort,
          PriceObj: getPriceSuccess,
          countries: getCountriesSuccess,
        }
      }
      return (
        <div>
          <Helmet>
            <meta name="description"
                  content="Sign up to CHICARGOS for the best import deals on Vehicles, Auto Parts and Electronics and much more. " />
            <title>CHICARGOS -  Browse available vehicle for import</title>
          </Helmet>
          <div className="container cpanel" style={{backgroundColor: '#f8f9fa'}}>
          {state.MultiView === 0 &&
            <div>
              <Breadcrumb>
                <BreadcrumbItem><Link to="/">Vechile</Link></BreadcrumbItem>
              </Breadcrumb>

              <CarDetail details={finalData ? finalData : state} VisaLogo={VisaLogo} onImage={onImage} onBuy={this.onBuy} onChange={this.onChange} />
            </div>}
            <div className="stepper-background">
              {/*<div className="stepper-spacer">*/}
              {/*</div>*/}
              <Stepper steps={ [{title: 'Shipping'}, {title: 'Review'}, {title: 'Order'}] }
                       activeStep={ actionStep }
                       size={30}
                       circleFontSize={14}
                       titleFontSize={14}
                       circleTop={0}
                       activeColor={'#989798'}
                       completeColor={'#980000'}
              />
            </div>

          {state.MultiView === 1 &&
            <div>
                <CarBuyDetail onBuy={this.onBuy} details={finalData ? finalData : state} VisaLogo={VisaLogo} onImage={onImage} onChange={this.onChange} handleKeyPress={this.handleKeyPress()} />
            </div>
          }
          {
            state.MultiView === 2 &&
            <CarBuyAck />
          }
          {
            state.MultiView === 3 &&
            (<SomeComponent someProp={state} onConfirm={this.onConfirm} toggle={this.toggle} onLogin={this.onLogin} onLoading={placeOrderLoader} onCountries={getCountriesSuccess}/>)
          }
          {
            state.MultiView === 4 &&
            <ConfirmOrder onMyAccount={this.onMyAccount} onContinueShopping={this.onContinueShopping}/>
          }
        </div>
        </div>
      );
    }
}

export default compose(withCookies, withRouter)(Car);

