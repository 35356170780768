import React from 'react'
import {Link} from 'react-router-dom';

const Activate = (props) => {
  return (
    <div className="row">
      <div className="offset-1 col-10 offset-lg-1 col-lg-10">
        <div className="container jumbotron text-center">
          <h5 style={{color:"#000000", marginBottom:"20px", fontWeight: 800}}>
            Account Created
          </h5>
          <h7 style={{color:"#0055d4"}}>
            Verify your email address to access all of Magari World features
          </h7>
          <div style={{marginTop:"16px"}}>
            <i className="far fa-envelope" style={{fontSize:"65px"}}></i>
          </div>
          <div style={{marginTop:"15px", fontSize:"14px", textAlign: "center"}}
             className="page-information">
            Verification email was sent to
            <br/>
            <p style={{textDecoration:"underline" ,color:"#6fa8dd" }}>
              {props.email}
            </p>
            <br/>
            Open the email and click the activation link. It may take up to 15 minutes to receive the email
          </div>
          <div style={{fontWeight: "500", textAlign: "center", fontSize:"14px", marginBottom: "16px"}}>
            Tip: Check your spam folder in case the email was incorrectly identified.
            <p> Didn't receive the email? </p>
            <a href="/resend-activation-link" className="a-links"> Resend Email </a>
          </div>
          <div className="col text-center row justify-content-center">
            <Link
              to="/"
              className="btn btn-primary linkInquiryBtn">
              Continue
            </Link>
          </div>
        </div>
      </div>
   </div>
  )
}

export default Activate
