import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';

function convertData(data) {
  const result = { id: '1', name: 'Select Trim', generation: 'top', children: [] };
  let parentId = 2;
  let childId = 1;
  let grandChildId = 1;

  for (const key in data) {
    const children = [];
    for (const item of data[key]) {
      const grandChildren = [];
      for (const year of item._source.year_list) {
        grandChildren.push({
          id: `${year}`, name: year, generation: 'last', docID: `${item._id}` });
        grandChildId++;
      }
      children.push({
        id: `parent-${parentId}${childId}`,
        name: `${item._source.cars_type} - ${item._source.engine}`,
        generation: 'top',
        docID: `${item._id}`,
        children: grandChildren
      });
      childId++;
      grandChildId = 1;
    }
    const name = key.replace(/'/g, '');
    result.children.push({ id: parentId.toString(), name, generation: 'top', children });
    parentId++;
    childId = 1;
  }
  return result;
}

function searchDocId(obj, id, results = []) {
  if (obj.docID === id) {
    if (obj.id.split("-")[0] === "parent"){
      console.log("parent")
    } else {
      results.push(obj.name);
    }
  }
  if (obj.children) {
    obj.children.forEach(child => searchDocId(child, id, results));
  }
  return results;
}


export default function FitmentTreeView({trimData, checked, setChecked}) {
  const [treeData, setTreeData ] = useState({})
  const [fitmentTrims, setFitmentTrims] = useState(trimData);

  useEffect(() => {
    setFitmentTrims(trimData);
    let processedTrims = convertData(trimData)
    setTreeData(processedTrims)
  }, [trimData]);

  const handleToggle = (id, docID) => {

    let manySelected = []
    let elementType = id.split("-")[0]
    if (elementType === 'parent') {

      let children = searchDocId(treeData, docID)
      manySelected.push(children)
    }
    setChecked((prevChecked) => {
      const docIndex = prevChecked.findIndex((item) => item.docID === docID);
      if (docIndex === -1) {
        // if docID not in array, add new object
        let selectedIds = [id]
        if (manySelected && manySelected.length) {
          selectedIds.push(manySelected)
        }
        return [...prevChecked, { docID, ids: selectedIds }];
      } else {
        const currentIndex = prevChecked[docIndex].ids.indexOf(id);
        const newChecked = [...prevChecked];
        if (currentIndex === -1) {
          newChecked[docIndex].ids.push(id);
        } else {
          newChecked[docIndex].ids.splice(currentIndex, 1);
        }
        return newChecked;
      }
    });
  };


  useEffect(()=> {
    let processed = convertData(fitmentTrims)
    setTreeData(processed)
  }, [])

  const renderTreeItems = (data) => {
    // const isItemChecked = checked.includes(data.id);
    const docChecked = checked.find((item) => item.docID === data.docID);
    const isItemChecked = docChecked ? docChecked.ids.includes(data.id) : false;

    return (
      <TreeItem
        key={data.id}
        nodeId={data.id}
        label={
          <React.Fragment >
            <Checkbox
              style={{ display: data.generation === 'top' ? 'none': null }}
              checked={isItemChecked}
              onClick={(event) => {
                event.stopPropagation();
                handleToggle(data.id, data.docID);
              }}
            />
            {data.name}
          </React.Fragment>
        }
      >
        {Array.isArray(data.children)
          ? data.children.map((child) => renderTreeItems(child))
          : null}
      </TreeItem>
    );
  };

  return (
    <div>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {renderTreeItems(treeData)}
      </TreeView>
    </div>
  );
}

