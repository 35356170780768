import React, {useEffect, useRef, useState} from 'react';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import StudioHeader from "./StudioHeader";
import StudioLoader from "./StudioLoader";
import {Col, Row, Table} from "reactstrap";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import ResponsiveAppBar from "./ResponsiveAppBar"
import TextField from "@mui/material/TextField";
import PartsDetailComponent from "./PartsDetailComponent";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';

import FileUploadComponent from "./FileUploadComponent";
import FitmentTreeView from "./FitmentTreeView"
import CompatibleCardsComponent from "./CompatibleCardsComponent";
import { Loading } from "../LoadingComponent"


function countIds(arr) {
  let count = 0;
  for (let i = 0; i < arr.length; i++) {
    count += arr[i].ids.length;
  }
  return count;
}


export default function AddSparesContentComponent(props) {
  const [value, setValue] = React.useState(0);
  const [prodTitle, setProdTitle] = useState('')
  const [prodCat, setProdCategory] = useState('')
  const [dateCreated, setDateCreated] = useState('')
  const itemRef = useRef(null);

  const [open, setOpen] = React.useState(false);
  const [updateDisabled, setUpdateDisabled] = React.useState(true)
  const [courseId, setCourseId] = useState(null)
  const [coverImg, setCoverImg] = useState('https://www.open.edu/openlearn/pluginfile.php/3277384/tool_ocwmanage/articletext/0/become_a_student_inline.jpg')
  const [gallery, setGallery]  = useState({
    0: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
    1: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
    2: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
    3: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg'
  })
  const [images, setImages] = useState([  {
    original: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
    thumbnail: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
  },
    {
      original: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
      thumbnail: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
    }])
  const [title, setTitle] = React.useState('');
  const [author, setAuthor] = React.useState('');
  const [notify, setNotify] = React.useState(false)
  const [error, setError] = useState(
    {
      contact: false,
      mileage: false,
      location: false,
      price: false,
      title: false,
      engine: false,
      description: false,
      condition: false
    }
  )

  const [openDiag, setOpenDiag] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const [metaChanged, setMetaChange] = useState(false);
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [publish, setPublish] = useState('draft');
  const [partNumber, setPartNumber] = useState('')
  const [bodyType, setBodyType] = useState('')
  const [colour, setColour] = useState('')
  const [condition, setCondition] = useState('')
  const [compMakes, setCompMake] = useState([])
  const [compSelection, setCompSelection] = useState({})
  const [compModels, setCompModels] = useState([])
  const [compVariants, setCompVariants] = useState([])
  const [selectedMake, setSelectedMake] = useState('')
  const [selectedTrimCount, setSelectedTrimCount] = useState(0)
  const [selectedModels, setSelectedModels] = useState([])
  const [selectedVar, setSelectedVar] = useState([])
  const [year, setYear] = useState('')
  const [contact, setContact] = useState('')
  const [location, setLocation] = useState('')
  const [price, setPrice] = useState('')
  const [fitmentTrims, setFitmentTrims] = useState({})
  const [checked, setChecked] = useState([]);
  const [prePopulate, setPrePopulate] = useState(false)
  const mounted = useRef();
  const mountedFitment = useRef();
  const mountedModels = useRef();
  const mountedVar = useRef();

  const handleCheckedChange = (newChecked) => {
    setChecked(newChecked);
    let count = countIds(checked)
    setSelectedTrimCount(count)
  };

  const handleCompCard = (preSelectedCard, make, models, variants, trims) => {

    let payload = {"fetchProp": "prepopulate",
      "filterProps": {
        "make": make,
        "models": models,
        "variants": variants
      }
    }

    props.retrieveFitment(payload)
    setChecked(trims);
    let count = countIds(trims);
    setSelectedTrimCount(count);
    setOpenDiag(true);
    setPrePopulate(true)
    setSelectedMake(make)
    setSelectedModels(models);
    setSelectedVar(variants);
  };

  const handleClickOpen = () => {
    setPrePopulate(false)
    setSelectedMake('');
    setSelectedModels([]);
    setSelectedVar([]);
    setChecked([])
    setOpenDiag(true);
  };

  const handleCloseDiag = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenDiag(false);
    }
  };

  const handleSetCompatibility = (event, reason) => {
    let payload = {"action": "add", "productID": courseId, "fitment": checked}
    props.setCompatibility(payload)
  };

  const handleChangePublish = (event) => {
    if (event.target.checked) {
      if (!location || !contact || !price || !prodTitle || !description){
        setError({
          ...error,
          location:!location,
          contact: !contact,
          price: !price,
          title: !prodTitle,
          description: !description
        })
        setNotify(true)
        return
      }
      setPublish('live');
    } else {
      setPublish('draft')
    }
    setUpdateDisabled(false)
    setMetaChange(true)
  };

  const handleFormChange = (event) => {
    if (event.target.id === 'title') {
      setTitle(event.target.value);
    } else if (event.target.id === 'author') {
      setAuthor(event.target.value)
    } else if (event.target.id === 'subtitle') {
      setSubtitle(event.target.value)
      setMetaChange(true)
    } else if (event.target.id === 'description') {
      setDescription(event.target.value)
      setError({
        ...error,
        description: false,
      })
      setMetaChange(true)
    } else if (event.target.id === 'prodTitle') {
      setProdTitle(event.target.value)
      setMetaChange(true)
    } else if (event.target.id === 'partNumber') {
      setPartNumber(event.target.value)
    } else if (event.target.id === 'year') {
      setYear(event.target.value)
    }
    setUpdateDisabled(false)
  };

  const handleChange = (event) => {
    setSelectedMake(event.target.value);
    setSelectedModels([])
    setSelectedVar([])
    // handleCompRequest("models")
  };

  const handleModelChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedModels(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleVarChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedVar(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    if (prePopulate){
      return
    }
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      // do componentDidUpdate logic
      props.getCompatibility(`?qry=model&mk=${selectedMake}`)
    }
  }, [selectedMake])

  useEffect( () => {
    console.log("CHECK HAS CHANGED", checked)
  }, [checked])

  useEffect(() => {
    if (prePopulate){
      return
    }
    if (!mountedModels.current) {
      // do componentDidMount logic
      mountedModels.current = true;
    } else {
      // do componentDidUpdate logic
      setSelectedVar([])
      setFitmentTrims({})
      if (selectedModels.length === 0) {
        setCompVariants([])
        return
      }
      let payload = {"fetchProp": "variants",
        "filterProps": {
          "make": selectedMake,
          "models": selectedModels
        }
      }
      props.retrieveFitment(payload)
    }
  }, [selectedModels])

  useEffect(() => {
    if (prePopulate){
      return
    }
    if (!mountedVar.current) {
      mountedVar.current = true
    } else {
      setChecked([])
      setFitmentTrims({})
      setSelectedTrimCount(0)
      if (selectedModels.length === 0 || selectedVar.length === 0) {
        return
      }
      let payload = {"fetchProp": "body",
        "filterProps": {
          "make": selectedMake,
          "models": selectedModels,
          "variants": selectedVar
        }
      }
      props.retrieveFitment(payload)
    }
  }, [selectedVar])

  useEffect(() => {
    let contentID = props.match.params.cid
    let lectureID = props.match.params.lid
    let contentObj = {cid: contentID, lid: lectureID}
    if (props.postContentSuccess && props.postContentSuccess) {
      props.getContent(contentObj)
    }
  }, [props.postContentSuccess && props.postContentSuccess.data])

  useEffect(() => {
    if (props.createLectureSuccess && props.createLectureSuccess) {
      setOpenForm(false)
    }
  }, [props.createLectureSuccess && props.createLectureSuccess])

  useEffect(() => {
    if (props.postContentLoader === true) {
      setOpen(true)
      setUpdateDisabled(true)
    }
  }, [props.postContentLoader])

  useEffect(() => {
    if (props.deleteContentSuccess !== null ) {
      let contentObj = {cid: courseId, lid: undefined}
      if (props.match.params.lid) {
        props.history.push(`/vendor/list-auto-spares/${courseId}`)
      } else {
        props.getContent(contentObj)
      }
      setValue(1)
    }
  }, [props.deleteContentSuccess && props.deleteContentSuccess.data])

  useEffect(() => {
    if (props.getContentError !== null) {
      if (props.getContentError.endsWith('401')) {
        props.history.push("/profile")
        // setErrMsg('Unauthorized error - Login and try again')
      }
    }
  }, [props.getContentError && props.getContentError])

  useEffect(() => {
    if (props.getContentSuccess && props.getContentSuccess) {
      setProdTitle(props.getContentSuccess.data.prod_title)
      setContact(props.getContentSuccess.data.prod_contact)
      setLocation(props.getContentSuccess.data.prod_location)
      setPrice(props.getContentSuccess.data.prod_price)
      try {
        let specObj = JSON.parse(props.getContentSuccess.data.specs.partNumber)
        setPartNumber(specObj.partNumber)
        setCondition(specObj.condition)
      } catch (e) {
        console.log("Forget about it")
      }
      setYear(props.getContentSuccess.data.specs.year)
      setBodyType(props.getContentSuccess.data.specs.bodyType)
      setColour(props.getContentSuccess.data.specs.colour)
      setSubtitle(props.getContentSuccess.data.prod_subtitle)
      setDescription(props.getContentSuccess.data.prod_description)
      setProdCategory(props.getContentSuccess.data.cat_full_name)
      setPublish(props.getContentSuccess.data.prod_live)
      setDateCreated(props.getContentSuccess.data.date_created)
      setCoverImg(props.getContentSuccess.data.cover_image)
      setCompSelection(props.getContentSuccess.data.compatible_list)
      setGallery(props.getContentSuccess.data.gallery_images)
      let imagesForGallery = []
      for (let [key, value] of Object.entries(props.getContentSuccess.data.gallery_images)) {
        imagesForGallery.push({original: value, thumbnail: value})
      }
      setImages(imagesForGallery)
      if (Object.keys(props.getContentSuccess.data).length > 0) {
        if (props.match.params.lid) {
          let contentObj = {}
          for (let [key, value] of Object.entries(props.getContentSuccess.data.editors)) {
            if (key.endsWith("_raw")) {
              let con = convertFromRaw(JSON.parse(value))
              let con_conv = EditorState.createWithContent(con)
              let ed_key = key.split("_")[0]
              contentObj[ed_key] = con_conv
              contentObj[key] = value
            }
          }
          setUpdateDisabled(true)
        }
        setOpen(false)
      }
    }

  }, [props.getContentSuccess && props.getContentSuccess.data])

  useEffect( () => {
    if (props.setCompatibilitySuccess && props.setCompatibilitySuccess) {
      setCompSelection(props.setCompatibilitySuccess)
      setOpenDiag(false);
    }
  }, [props.setCompatibilitySuccess])


  useEffect(() => {
    if (props.getCompatibilitySuccess && props.getCompatibilitySuccess) {
      let query = props.getCompatibilitySuccess.query
      if (query === "makes"){
        setCompMake(props.getCompatibilitySuccess.data)
      }
      if (query === "model"){
        setCompModels(props.getCompatibilitySuccess.data)
      }
    }

  }, [props.getCompatibilitySuccess])

  useEffect( () => {
    if (!mountedFitment.current) {
      // do componentDidMount logic
      mountedFitment.current = true;
    } else if (props.retrieveFitmentSuccess && props.retrieveFitmentSuccess) {
      if (Array.isArray(props.retrieveFitmentSuccess)) {
        setCompVariants(props.retrieveFitmentSuccess)
      } else {
        if ('models' in props.retrieveFitmentSuccess) {
          setCompModels(props.retrieveFitmentSuccess.models)
          setCompVariants(props.retrieveFitmentSuccess.vars)
          setFitmentTrims(props.retrieveFitmentSuccess.trims)
          setPrePopulate(false)
        } else {
          setFitmentTrims(props.retrieveFitmentSuccess)
        }
      }
    }
  }, [props.retrieveFitmentSuccess])

  useEffect(() => {
    let contentID = props.match.params.cid
    let lectureID = props.match.params.lid
    let contentObj = {cid: contentID, lid: lectureID}
    setCourseId(contentID)
    if (contentID !== null) {
      props.getContent(contentObj)
      let make_query = "?qry=makes"
      props.getCompatibility(make_query)
    }
    if (lectureID !== undefined){
      setValue(2)
    }

  }, [])

  useEffect(() => {
    if (itemRef && itemRef.current) {
      window.scrollTo({
        top: itemRef.current.offsetTop - 300,
      });
    }
  },[value]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitContent = () => {
    setNotify(false)
    let contentID = props.match.params.cid
    let contentObj = {
      cid: contentID,
    }
    let parts_spec = {"partNumber": partNumber, "condition": condition}
    contentObj['subtitle'] = subtitle
    contentObj['description'] = description
    contentObj['publish'] = publish
    contentObj['pTitle'] = prodTitle
    contentObj['pContact'] = contact
    contentObj['pLocation'] = location
    contentObj['sBody'] = bodyType
    contentObj['sColour'] = colour
    contentObj['sYear'] = year
    contentObj['pPrice'] = price
    contentObj['sPartNumber'] = JSON.stringify(parts_spec)

    props.postContent(contentObj)
  }

  return (
    <div>
      <ResponsiveAppBar
        disableButton={updateDisabled}
        onSubmit={handleSubmitContent}
      />

      {props.postContentLoader && props.postContentLoader ?
        <Box sx={{width: '100%'}}>
          <LinearProgress/>
        </Box> :
        null
      }

      <div className="container" style={{backgroundColor: "white", marginTop: "64px"}}>
        <div>
          <Box
            className="studio-box"
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'block', minHeight: 364}}
          >
            {props.getContentLoader && props.getContentLoader ?
              <Box sx={{width: '100%'}}>
                <LinearProgress/>
              </Box> :
              null
            }
            <StudioLoader onClose={handleClose} open={open}/>
            <div>
              <StudioHeader
                title="Listing Information"
                info="This is the auto spares details section"
              />
            </div>
            {notify ?
              <div style={{paddingBottom: "40px"}}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  One of the required field is empty — <strong>check your input</strong>
                </Alert>
              </div>
              :
              null
            }
            <div>
              <Table bordered striped size="md" >
                <tbody>
                <tr>
                  <td className="tbl-header">Publish:</td>
                  <td className="tbl-data">
                    <Switch
                      size="large"
                      checked={publish === 'live'}
                      onChange={handleChangePublish}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tbl-header">Date created:</td>
                  <td className="tbl-data">{dateCreated}</td>
                </tr>
                <tr>
                  <td className="tbl-header">Category:</td>
                  <td className="tbl-data">{prodCat}</td>
                </tr>
                </tbody>
              </Table>
            </div>
            <div style={{padding: "16px 0px 16px 0px"}}>
              <h5 className="page-header-content">Details</h5>
              <Row>
                <Col>
                  <TextField
                    error={error["price"]}
                    fullWidth
                    id="price"
                    label="Price"
                    value={price}
                    onChange={e =>
                    {setPrice(e.target.value); setUpdateDisabled(false); setError({
                      ...error,
                      price: false,
                    })}}
                    variant="outlined"
                    style={{margin: "8px 0px", backgroundColor: "white"}}
                    helperText="Field required"
                  />
                </Col>
                <Col>
                  <TextField
                    error={error["contact"]}
                    fullWidth
                    id="contact"
                    label="Contact number"
                    value={contact}
                    onChange={e =>
                    {setContact(e.target.value); setUpdateDisabled(false); setError({
                      ...error,
                      contact: false,
                    })}}
                    variant="outlined"
                    style={{margin: "8px 0px", backgroundColor: "white"}}
                    helperText="Field required"
                  />
                </Col>
              </Row>
              <Row style={{paddingBottom: "16px"}}>
                <Col>
                  <TextField
                    error={error["location"]}
                    fullWidth
                    id="subtitle"
                    label="Enter listing subtitle - e.g condition"
                    value={subtitle}
                    onChange={handleFormChange}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 100 }}
                    style={{margin: "8px 0px", backgroundColor: "white"}}
                    helperText="Field required"
                  />
                </Col>
                <Col>
                  <TextField
                    error={error["location"]}
                    fullWidth
                    id="location"
                    label="Item location"
                    value={location}
                    onChange={e =>
                    {setLocation(e.target.value); setUpdateDisabled(false); setError({
                      ...error,
                      location: false,
                    })}}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    style={{margin: "8px 0px", backgroundColor: "white"}}
                    helperText="Field required"
                  />
                </Col>
              </Row>
            </div>
            <div style={{padding: "16px 0px 32px 0px"}}>
              <Row>
                <Col sm="12">
                  <div>
                    <h5 className="page-header-content">Specification</h5>
                    <TextField
                      error={error["title"]}
                      fullWidth
                      id="prodTitle"
                      label="Enter vehicle title - (max 150 characters)"
                      value={prodTitle}
                      onChange={handleFormChange}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 150 }}
                      style={{margin: "16px 0px", backgroundColor: "white"}}
                      helperText="Field required"
                    />
                    <Row style={{paddingBottom: "16px"}}>
                      <Col style={{paddingLeft: "0px"}}>
                        <TextField
                          fullWidth
                          id="partNumber"
                          label="Part Number"
                          value={partNumber}
                          onChange={handleFormChange}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 100 }}
                          style={{margin: "8px 0px", backgroundColor: "white"}}
                          helperText="Ref / OEM Number"
                        />
                      </Col>
                      <Col style={{paddingRight: "0px"}}>
                        <TextField
                          fullWidth
                          id="condition"
                          label="Condition"
                          value={condition}
                          onChange={e =>
                          {setCondition(e.target.value); setUpdateDisabled(false); setError({
                            ...error,
                            condition: false,
                          })}}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          style={{margin: "8px 0px", backgroundColor: "white"}}
                          helperText="The condition of the item"
                        />
                      </Col>
                    </Row>

                    <hr/>
                    <Row style={{padding: "16px 0px 0px 0px"}}>
                      <h5 className="page-header-content">Compatibility</h5>
                    </Row>

                    <Row>
                      {props.setCompatibilityLoader && props.setCompatibilityLoader ?
                        <Box sx={{width: '100%'}}>
                          <Loading/>
                        </Box> :
                        null
                      }
                      {Object.keys(compSelection).map((keyName, i) => (
                        <CompatibleCardsComponent
                          idx={i}
                          kn={keyName}
                          models={compSelection[keyName].model}
                          vars={compSelection[keyName].var}
                          trims={compSelection[keyName].trims}
                          populate={handleCompCard}
                        />
                      ))
                      }
                    </Row>
                    <Row style={{padding: "16px 0px"}}>
                      <Button onClick={handleClickOpen}>Add Compatibility</Button>
                      <Dialog
                        fullWidth={true}
                        maxWidth={"xl"}
                        disableEscapeKeyDown
                        open={openDiag}
                        onClose={handleCloseDiag}
                      >
                        <DialogTitle>Select - {selectedTrimCount} trims selected </DialogTitle>
                        <DialogContent>
                            <Row>
                              <Col>
                                {props.setCompatibilityLoader && (
                                  <div>
                                    <Loading/>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row style={{padding: "0px 0px 32px 0px"}}>
                              <Col style={{paddingLeft: "0px"}}>
                                <FormControl fullWidth style={{margin: "24px 0px"}}>
                                  <InputLabel id="body type">Make</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="compMake"
                                    disabled={props.setCompatibilityLoader}
                                    value={selectedMake}
                                    label="compMake"
                                    className="mui-select"
                                    onChange={handleChange}
                                  >
                                    { compMakes.map((mk) => (
                                      <MenuItem style={{ display: 'flex', flexDirection: 'row' }}
                                                value={mk}>{mk}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Col>
                              <Col >
                                <FormControl fullWidth style={{margin: "24px 0px"}}>
                                  <InputLabel id="demo-simple-select-label">Models</InputLabel>
                                  <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="models"
                                    multiple
                                    disabled={props.setCompatibilityLoader}
                                    value={selectedModels}
                                    label="model"
                                    className="mui-select"
                                    onChange={handleModelChange}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    // menuProps={MenuProps}
                                  >
                                    {compModels.map((compModel) => (
                                      <MenuItem
                                        style={{ display: 'flex', flexDirection: 'row' }}
                                        key={compModel}
                                        value={compModel}>
                                        <Checkbox checked={selectedModels.indexOf(compModel) > -1} />
                                        <ListItemText primary={compModel} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText>Required</FormHelperText>
                                </FormControl>
                              </Col>
                              <Col style={{paddingRight: "0px"}}>
                                <FormControl fullWidth style={{margin: "24px 0px"}}>
                                  <InputLabel id="demo-simple-select">Variants</InputLabel>
                                  <Select
                                    labelId="variants-select-label"
                                    id="variants"
                                    multiple
                                    disabled={props.setCompatibilityLoader}
                                    value={selectedVar}
                                    onChange={handleVarChange}
                                    label="model"
                                    className="mui-select"
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selectedVar) => selectedVar.join(', ')}
                                  >
                                    {compVariants.map((compVar) => (
                                      <MenuItem style={{ display: 'flex', flexDirection: 'row' }}
                                                key={compVar} value={compVar}>
                                        <Checkbox checked={selectedVar.indexOf(compVar) > -1} />
                                        <ListItemText primary={compVar} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Col>
                            </Row>
                            <Row style={{paddingBottom: "32px"}}>
                              <FitmentTreeView
                                trimData={fitmentTrims}
                                checked={checked}
                                setChecked={handleCheckedChange}
                              />
                              <br/>
                              <hr/>
                            </Row>
                          {/*</Box>*/}
                        </DialogContent>
                        <DialogActions>
                          <Button className="mui-buttons"
                                  autoFocus
                                  onClick={handleCloseDiag}>Cancel
                          </Button>
                          <Button
                            className={`mui-buttons ${ (props.setCompatibilityLoader 
                              || selectedVar.length === 0 
                              || selectedModels.length === 0 || selectedMake === '' 
                              || checked.length === 0) ? 'disabled' : '' }`}
                            disabled={(props.setCompatibilityLoader
                              || selectedVar.length === 0
                              || selectedModels.length === 0 || selectedMake === ''
                              || checked.length === 0)}
                            autoFocus
                            onClick={handleSetCompatibility}
                          >
                            Save
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Row>
                    <Row style={{padding: "16px 0px 0px 0px"}}>
                      <h5 className="page-header-content">Description</h5>
                    </Row>
                    <TextField
                      error={error["description"]}
                      fullWidth
                      id="description"
                      label="Enter parts description incl part numbers"
                      value={description}
                      onChange={handleFormChange}
                      multiline
                      variant="outlined"
                      rows={3}
                      maxRows={10}
                      style={{margin: "8px 0px 32px 0px", backgroundColor: "white"}}
                      helpText="Field required"
                    />
                    <div>
                      <h5 className="page-header-content">Photo upload</h5>
                      <div className="row">
                        {Object.keys(gallery).map((keyName, i) => (
                          <div className="col-lg-3 col-md-3 col-6 mt-4">
                            <div>
                              <img className="my-img-thumbnail"
                                   alt="Product img"
                                   height={"150px"}
                                   src={gallery[i]} />
                            </div>
                            <FileUploadComponent
                              sendRequest={props.uploadImage}
                              uploadSuccess={props.uploadImageSuccess}
                              uploadImageError={props.uploadImageError}
                              uploadImageLoader={props.uploadImageLoader}
                              updateCoverImg={setCoverImg}
                              courseId={courseId}
                              displayOrder={i}
                              saveDisabled={updateDisabled}
                            />
                          </div>
                        ))}
                      </div>

                    </div>
                  </div>
                  <hr style={{borderTop: "1px solid #00000040"}}/>
                  <Box
                    sx={{
                      display: 'block',
                      flexWrap: 'wrap',
                      '& > :not(style)': {
                        // minHeight: 300,
                      },
                    }}
                  >
                    <div style={{padding: '8px 16px'}}>
                      <h5 className="page-header-content">Preview</h5>
                    </div>
                    <PartsDetailComponent
                      images={images}
                      price={price}
                      prodTitle={prodTitle}
                      subtitle={subtitle}
                      partNumber={partNumber}
                      condition={condition}
                      description={description}
                      contact={contact}
                      location={location}
                    />
                  </Box>
                </Col>
              </Row>
            </div>
          </Box>
        </div>
      </div>
      <Box sx={{ width: 500 }}>
      </Box>
    </div>

  )
}
