import React, {Component} from 'react';
import BlogBlueprint from './../BlogBlueprintComponent'
import CoverImage from './../../assets/single-elements/scenic-landscape.jpeg'



class CorperateNationalReps extends Component {

  render() {
    const title = "CHICARGOS Careers"
    const bImage = CoverImage
    const subHeading = "National Marketing Manager"
    const pubDate = "09 Jan - 3 min read"
    return (
      <div>
        <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                <div className="" style={{ textAlign: 'center' }}>
                  <BlogBlueprint
                    title={title}
                    bImage={bImage}
                    subHeading={subHeading}
                    publishingDate={pubDate}
                  >
                  </BlogBlueprint>
                  <p className="page-information" style={{fontSize: '14px', paddingTop: '0px'}}>
                   We are looking for highly intelligent Marketing & Sales manager with a passion for marketing to help
                    launch our global digital e-commerce solution.This is a fantastic opportunity for someone looking
                    for their next step up and who wants to have more autonomy and responsibility.
                  </p>
                  <p className="page-information" style={{fontSize: '14px', paddingTop: '0px'}}>
                    As part of the Senior Management Team, the Marketing & Sales manager is responsible for
                    delivering the company MLM strategies. In this position,
                    you will be responsible for all aspects of our marketing operations in the region (national level).
                  </p>
                  <p className="how-to-steps" style={{fontSize: '16px'}}>Key Responsibilities:</p>
                  <p className="page-information" style={{fontSize: '14px', paddingTop: '0px'}}>
                    <p style={{textAlign: "left"}}>
                      <ul>
                        <li style={{paddingBottom: '5px'}}>
                          Be Target driven
                        </li>
                        <li style={{paddingBottom: '5px'}}>
                          Planning and executing the full marketing mix, your varied role will incorporate utilising all
                          channels to promote CHICARGOS brand, including social media, events & tradeshows, web, print
                          collateral, and PR
                        </li>
                        <li style={{paddingBottom: '5px'}}>
                          Recruit supporters on The CHICARGOS support programme, train them and work as a team to brainstorm new
                          and innovative growth strategies
                        </li>
                        <li style={{paddingBottom: '5px'}}>
                          Work as part of a team to generate sales with new and existing customers
                        </li>
                        <li style={{paddingBottom: '5px'}}>
                          Establish business relationships on behalf of CHICARGOS with other players in the industry
                        </li>
                        <li style={{paddingBottom: '5px'}}>
                          Market research and the Distribution of campaign materials
                        </li>
                      </ul>
                    </p>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>Requirements:</p>
                    <p style={{textAlign: "left"}}>
                      <ul>
                        <li style={{paddingBottom: '5px'}}>
                          A minimum of 3 years in marketing
                        </li>
                        <li style={{paddingBottom: '5px'}}>
                        Salary will be based on your experience
                        </li>
                      </ul>
                    </p>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>How to apply:</p>
                    <p>
                      Send your CV to support@seetutors.co.uk
                    </p>
                </p>
         </div>
          </div>
        </div>
         </div>
        </div>
      </section>
      </div>
    )
  }

}

export default CorperateNationalReps;
