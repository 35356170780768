import React from 'react';
import {
  Col,
  ListGroup,
  ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from 'reactstrap';


class NavElements extends React.Component {

  render() {
  const { currentPage, onOpenSidePane } = this.props

  return <div>
    <div className="row">
      <Col className="">
      <ListGroup style={{marginBottom: "15px", cursor:'pointer'}} >
        <ListGroupItem
          style={{backgroundColor: currentPage === "About" ? '#980000': '#2e6399',
            textAlign: 'center'}}
          onClick={onOpenSidePane.bind(this, "a", "About")}
        >
          <ListGroupItemHeading style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
            About
          </ListGroupItemHeading>
          <ListGroupItemText style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
            <img
              className="link-icons"
              src={require("../../assets/single-elements/icons8-about-52.png")}
              alt="icons"
            />
          </ListGroupItemText>
        </ListGroupItem>
      </ListGroup>
      </Col>
      <Col className="">
      <ListGroup style={{marginBottom: "15px", cursor:'pointer'}}>
        <ListGroupItem
          style={{backgroundColor: currentPage === "How" ? '#980000': '#2e6399',
            textAlign: 'center'}}
          onClick={onOpenSidePane.bind(this, "a", "How")}
        >
          <ListGroupItemHeading style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
            How to
          </ListGroupItemHeading>
          <ListGroupItemText style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
            <img
              className="link-icons"
              src={require("../../assets/single-elements/icons8-administrative-tools-52.png")}
              alt="icons"
            />
          </ListGroupItemText>
        </ListGroupItem>
      </ListGroup>
      </Col>
    </div>
    <div className="row">
      <Col className="">
      <ListGroup style={{marginBottom: "15px", cursor:'pointer'}}>
        <ListGroupItem
          style={{backgroundColor: currentPage === "Compensation" ? '#980000': '#2e6399',
            textAlign: 'center'}}
          onClick={onOpenSidePane.bind(this, "a", "Compensation")}
        >
          <ListGroupItemHeading style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
            Reward
          </ListGroupItemHeading>
          <ListGroupItemText style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
            <img
              className="link-icons"
              src={require("../../assets/single-elements/icons8-money-52.png")}
              alt="icons"
            />
          </ListGroupItemText>
        </ListGroupItem>
      </ListGroup>
      </Col>
      <Col className="">
      <ListGroup style={{marginBottom: "15px", cursor:'pointer'}}>
        <ListGroupItem
          style={{backgroundColor: currentPage === "Tools" ? '#980000': '#2e6399',
            textAlign: 'center'}}
          onClick={onOpenSidePane.bind(this, "a", "Tools")}
        >
          <ListGroupItemHeading style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
            Tools
          </ListGroupItemHeading>
          <ListGroupItemText style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
            <img
              className="link-icons"
              src={require("../../assets/single-elements/icons8-full-tool-storage-box-50.png")}
              alt="icons"
            />
          </ListGroupItemText>
        </ListGroupItem>
      </ListGroup>
      </Col>
    </div>
  </div>

  }
}

export default NavElements;