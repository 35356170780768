import React, { Component } from "react";
// import ImgAndForm from "./ImgAndForm";
import ShopByMake from "./../containers/ShopByMakeContainer";
import BestDeals from "./../containers/BestDealsContainer";
import ImgAndForm from "./../containers/ImgAndFormContainer";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ShopByMake,
      search: {}
    };

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(search) {
    this.props.history.push(`${search}`);
  }


  render() {
    return (
      <div style={{ backgroundColor: "grey" }}>
        {/* <ImgAndForm onSearch={this.onSearch} /> */}
        <section className="gen-section">
          <div className="containers">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <ImgAndForm onSearch={this.onSearch} />

                <BestDeals />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
