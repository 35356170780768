import React from 'react';
import {Alert, Table,Button,Card, CardBody, Form, FormGroup, Label, Input, Col ,CardHeader } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {Ports} from '../../shared/port_country';

const CarDetail  = ({details, onBuy,onChange,onImage, VisaLogo}) => {
     
        const renderOptions = (data) => { 
            // return data.map((datum, i) => {
            //     return (
            //         <option key={i} value={datum.name}>
            //             {datum.name}
            //         </option>
            //     );
            // });
        }

        const port = () => {
            const p = Ports.filter(e => e[details.destinationCountry]);

            return p.length === 0  ?  <option selected disabled>(Select Destination Country first)</option>
            : [<option  value="">Select Destination Port</option>,
                p[0][details.destinationCountry].map((datum,i) => {
                return (
                    <option key={i} value={datum}>
                        {datum}
                    </option>
                );
            })];   
        }
    
        return (
            <div>
                <div className="row d-sm-none" style={{ margin: "0px"}}>
                    <Button onClick={onBuy.bind(this,1,true)} color="primary" block style={{marginBottom: "10px"}}>Buy it now</Button>
                   
                </div>
               <div className="row">
                   <h5 className="offset-md-5 col-md-7 d-none d-md-block">{details.title}</h5>
                    <div className="col-md-5 col-sm-12">
                    { details.images.length > 0 ?<ImageGallery showPlayButton={false}  items={details.images.map((i,k) => { return {original: i.remote_image, thumbnail:i.remote_image} } )}/>
                    : <img src={onImage} alt="car" height="250" width="350"/>}
                    </div>

                    <div className="col-md-7 d-none d-md-block">
                        <Card className="bg-light">
                            <CardBody style={{color:"#6fa8dd"}}>
                                {details.PriceObj && <h4>{details.PriceObj.incl_tax}  {details.PriceObj.currency}</h4>} 
                                <Button onClick={onBuy.bind(this,1,false)} color="primary" block>Buy it now</Button>
                                <br/>
                                <Form>
                                    <FormGroup>
                                       {details.message && <Alert color="danger">
                                                        {details.message}
                                                    </Alert>}
                                    </FormGroup>
                                    <FormGroup row>
                                    <Label for="dCountry" sm={5} style={{fontWeight:"bold"}}>Destination Country</Label>
                                    <Col sm={7}>
                                        <Input type="select" name="destinationCountry" onChange={onChange} value={details.destinationCountry} id="dCountry" ><option value="">Select Destination Country</option>{renderOptions(details.countries)}</Input>
                                    </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                    <Label for="dCountry" sm={5}  style={{fontWeight:"bold"}}>Destination Port</Label>
                                    <Col sm={7}>
                                        <Input type="select" name="destinationPort" onChange={onChange} value={details.destinationPort} id="dCountry" >{port()}</Input>
                                    </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        
                        </Card>


                        <div style={{marginTop:"45px"}}>
                            <span className="float-left" style={{position:"relative", top:"10px"}}>Payment: </span>  
                            <span className="float-right">Bank Transfer   &nbsp; &nbsp; <img src={VisaLogo} alt="visa" height="50" width="100"/></span>                     
                        </div>
                </div>

               </div>

               <div className="row">
                    <Card style={{width:"100%"}}>
                       <CardHeader style={{color:"#6fa8dd"}}><h5>Specs</h5></CardHeader>
                       <CardBody>
                       <Table className="borderless spec" >
                            {/*<tbody>*/}
                            {/*{*/}
                            {/*    details.specs.map((s,i) => <tr key={i} ><td style={{padding:"0.25rem", textTransform: "capitalize"}}>{s[0].replace("_"," ")}</td><td style={{padding:"0.25rem"}}>{s[1]}</td></tr>)*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    details.specsOther.map((s,i) => <tr key={i} ><td style={{padding:"0.25rem", textTransform: "capitalize"}}>{s.Name.replace("_"," ")}</td><td style={{padding:"0.25rem"}}>{s.Value.join(", ")}</td></tr>)*/}
                            {/*}*/}

                            {/*</tbody>*/}
                        </Table>
                       </CardBody>
                    </Card>
               </div>
               <div className="row">
                    <Card style={{width:"100%"}}>
                       <CardHeader style={{color:"#6fa8dd"}}><h5>Description</h5></CardHeader>
                       <CardBody style={{color:"#6fa8dd"}}>{details.description_clean}</CardBody>
                    </Card>
               </div>
            </div>


        );
    }


export default CarDetail;