import {
  SORT_BY_ALPHABET, SORT_BY_PRICE, FILTER_BY_PRICE, FILTER_BY_VALUE, FILTER_BY_CAT, FILTER_BY_CLASS
} from "../constants";

export class searchAction {

  static sortByPrice(payload) {
    return {
      type: SORT_BY_PRICE,
      payload
    };
  }

  static filterByPrice(payload) {
    return {
      type: FILTER_BY_PRICE,
      payload
    };
  }

  static sortByAlphabet(payload) {
    return {
      type: SORT_BY_ALPHABET,
      payload
    };
  }

  static filterByValue(payload) {
    return {
      type: FILTER_BY_VALUE,
      payload
    }
  }

  static filterByCat(payload) {
    return {
      type: FILTER_BY_CAT,
      payload
    }
  }

  static filterByClass(payload) {
    return {
      type: FILTER_BY_CLASS,
      payload
    }
  }
}
