import React, { Component } from "react";
// import ShopByMakeContainer from "./../../containers/ShopByMakeContainer";
import ItemDetailsComponent from "./ItemDetailsComponent";

class Home extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
    //   this.props.searchVehicle &&
        // this.props.searchVehicle(this.props.match.params);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      const id = nextProps.match.params.id
       this.props.getItem &&
        this.props.getItem(id);
    }
  }

  componentDidMount() {
    // this.props.searchVehicle &&
    //   this.props.searchVehicle(this.props.match.params);
  }

  render() {
    return (
      <div>
        <section className="gen-section filter_space_Details">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="searchResult" style={{paddingTop: '0px'}}>
                  <ItemDetailsComponent
                    {...this.props}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
