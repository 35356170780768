import { FILTER_COLLAPSE } from "../constants";

export default function filterReducer(state = false, action) {
  switch (action.type) {
    // Register User
    case FILTER_COLLAPSE:
      return action.payload;

    default:
      return state;
  }
}
