import React, {Component} from "react";

class ChapterThreeComponent extends Component {

  render() {
    return (
      <div style={{paddingBottom: '20px'}}>
        <div className="supporter-program-text">
          <div style={{ paddingBottom: '20px' }}>
            <div style={{ fontSize: "20px", fontWeight: 600 }}>
              Join the community and get involved?
            </div>
            <div className="training-headings">
              Be part of the CHICARGOS supporter community and exchange ideas with others.
              <br/><a href="https://www.facebook.com/groups/632446920907261/?source_id=102070134610567"
                 className="link-text"> Click here to join our facebook group
              </a>
            </div>
            <div style={{ paddingTop: '10px' }}>
              Subscribe to our social media handles to stay up to date with any developments
              <br/><a href="https://www.instagram.com/chicargos_auto/" className="link-text">
                 Click here to follow us on Instagram
              </a>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default ChapterThreeComponent;