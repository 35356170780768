import React, { Component } from "react";
import { Loading } from "../LoadingComponent";
import {Link, withRouter} from "react-router-dom";
import "react-alice-carousel/lib/alice-carousel.css"
import { withCookies } from "react-cookie";
import { compose } from "redux";
import _ from 'lodash';
import {Helmet} from "react-helmet";
import { Event } from "../Tracking/Event"

import {isEmpty} from "lodash";

import {
  Button,
 BreadcrumbItem, Breadcrumb
} from "reactstrap";
import {Ports} from '../../shared/port_country';
import VehicleDetailComponent from "../Vendor/VehicleDetailComponent";
import PartsDetailComponent from "../Vendor/PartsDetailComponent";


class ProductDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.handleAddToCartUnauthenticated = this.handleAddToCartUnauthenticated.bind(this);
    this.handleAddToCartAuthenticated = this.handleAddToCartAuthenticated.bind(this);
    this.search = this.search.bind(this);
    this.buyNowRef = React.createRef();
    this.handleBreadCrumb = this.handleBreadCrumb.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleHide = this.handleHide.bind(this);

    const { cookies } = props;
    this.state = {
      selectedIndex: 0,
      selectedValue: 'RORO',
      selectedPort: [],
      countries: [],
      ports: [],
      insurance: true,
      currSlide: 0,
      shippingCharge: 0,
      shippingCode: [],
      email: cookies.get("email") || "",
      userLoggedIn: !!cookies.get("email"),
      vehicleID: "",
      message: "",
      selectedAttr: "",
      destinationCountry: "Kenya",
      destinationPort: "Mombasa",
      url: "",
      shippingOptionDetail: "RORO: Your vehicle will Roll ON / Roll OFF ship vessel",
      optionsData: [],
      hasOptions: false,
      selectedOption: {},
      addToBagNotification: false,
      count: 1,
      demo: 'demo',
      partsProduct: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.state.email) {
      const email = nextProps.cookies.get("email");
      this.setState({
        email: email || "",
        userLoggedIn: !!email
      });
    }

  }

  componentDidUpdate(prevProps) {
    let { getVehicleDetail, addProductToBasketSuccess, addProductToBasketError, } = this.props;

    if (addProductToBasketError !== null && prevProps.addProductToBasketError !== addProductToBasketError) {
      if (addProductToBasketError === "AjaxError: ajax error 406") {
        this.buyNowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.setState({message: "You have items awaiting checkout. Please proceed to checkout the outstanding items first or remove them from your basket"})
      } else if(addProductToBasketError === "AjaxError: ajax error 401") {
          this.props.history.push(`/login`);
      } else if(addProductToBasketError === "Item is unavailable") {
        this.buyNowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.setState({message: "This product is out of stock "})
      } else {
        this.setState({message: addProductToBasketError})
      }
    }


    if (getVehicleDetail && prevProps.getVehicleDetail !== getVehicleDetail) {
      this.setState({ url: getVehicleDetail.url})
      if (getVehicleDetail.options === undefined) {
        this.setState({hasOptions: false})
      }
      else {
        if (getVehicleDetail.options.length === 0) {
          this.setState({hasOptions: false})
        }
        else {
          this.setState({
            optionsData: getVehicleDetail.options,
            hasOptions: true
          })
        }
      }
    } else if (addProductToBasketSuccess && prevProps.addProductToBasketSuccess !== addProductToBasketSuccess) {
      this.props.history.push(`/my-basket`);
    }
  }

  responsive = {
      0: { items: 2 },
      300: { items: 3 },
      400: { items: 3 },
      500: { items: 4 },
      600: { items: 4 },
      700: { items: 4 },
      800: { items: 4 },
      1024: { items: 4 },
      2048: { items: 5 },
  }

  stagePadding = {
      paddingLeft: 0,
      paddingRight: 40,
  }

  body_type_suv = [
      'convertible','sports', '4x4', 'double cab','pick-up', 'touring', 'pickup', 'two door saloon gt shell', '3 door',
      'suv', 'stationwagon', 'low loader', 'pick up with canopy', 'four wheel drive', 'double cab pickup', 'mpv',
      'light 4x4 utility',

  ]

  body_type_sedan = [
      'four wheel drive hatchback', '5 door hatchback', 'sport back', 'hatch','2 door saloon', 'grand coupe', 'saloon',
      'hatchback', '5 door hatch', 'cabriolet // convertible', '4 door saloon', 'roadster','sedan', 'estate',
  ]


  componentDidMount() {
      document.body.scrollTop = 0
      window.scrollTo(0,0);

      const { getVehicle, getCountries, getPromotionalApiData, getShippingMethods } = this.props;
      let id = window.location.pathname.split('/')[2];
      console.log(this.props.match.params.parts)
      console.log("-----------------------------------")
      getVehicle(id);
      getCountries();
      getPromotionalApiData();
      getShippingMethods();
      this.setState({vehicleID: id})
      if (this.props.match.params.parts === 'parts') {
        this.setState({partsProduct: true})
      }
  };

  slideTo = (i) => this.setState({ currSlide: i });

  handleBreadCrumb(type, value) {

  if (type === "cat") {
    if (value === "Men") {
      this.props.history.push("/search/cat-search-men");
    }
    if (value === "Women") {
      this.props.history.push("/search/cat-search-women");
    }
  }
  }

  handleMakeChange(e) {
      this.setState({ [e.target.name]: e.target.value,  });
  }

  handlePorts = (e) => {
    this.setState({
      selectedPort: e.target.value,
      shippingCharge: 700
    }, () => {this.updateShippingCode()})
  }

  updateShippingCode = () => {
      const { getShippingMethodsSuccess } = this.props
      const shippingCodesFromMethods = getShippingMethodsSuccess.map(shippingObject => shippingObject.code)
      let veh_type = 'other'
      if (!(typeof this.state.selectedPort === 'string')) {
          return;
      }
      let selectedPort = this.state.selectedPort.split(" ").join("-").toLowerCase();
      let portMatches = _.filter(shippingCodesFromMethods, function (s) {
          return s.indexOf(selectedPort) !== -1;
      });
      if (this.props.getVehicleDetail.specs.body_type !== null ) {
          if (new RegExp(this.body_type_sedan.join("|")).test(this.props.getVehicleDetail.specs.body_type.toLowerCase())) {
              veh_type = 'sedan'
          } else if (new RegExp(this.body_type_suv.join("|")).test(this.props.getVehicleDetail.specs.body_type.toLowerCase())) {
              veh_type = 'suv'
          }
      }

      let codeMatch = _.filter(portMatches, function (s) {
          return s.indexOf(veh_type) !== -1;
      });

      this.setState({
          shippingCode: codeMatch,
          message: ""
      }, () => {this.logIt()})
  }

  logIt()  {
  }

  handleCountryChange(e) {
    let Port = Ports.filter(x => x[e.target.value]);
    if(Port.length > 0)
      Port = Port[0][e.target.value]
    this.setState({ countries: e.target.value, ports : Port || []   });
  }

  handleRadios(val) {
    if (val === 'RORO'){
      this.setState({
        selectedIndex: 0, selectedValue: 'RORO', shippingOptionDetail: "RORO: Your vehicle will Roll ON / Roll OFF ship vessel" }, () => {this.logIt()})
      if (this.state.countries.length > 0) {
        this.setState({ shippingCharge: 700})
      }
    }
    else {
      this.setState({ selectedIndex: 1, selectedValue: 'Container', shippingOptionDetail: "Container: Your vehicle will be shipped in a container" })
      if (this.state.countries.length > 0) {
        this.setState({ shippingCharge: 1200})
        }
      }
  }

  handleOptionsChange(event) {
    event.preventDefault();
    let selectedValue = event.target.options[event.target.selectedIndex].text
    let selectedOpt = this.state.optionsData.filter(function(item) {
      return item.name === selectedValue
    })
    this.setState({selectedOption: selectedOpt[0], message: ""})
  }

  handleBuyNow (prodId, prodPrice, prodTitle, mainImage) {
    Event("PRODUCT", "Product added to cart", "PRODUCT_DETAIL_PAGE")
    const { count } = this.state
    const payload = {
      prodId: prodId,
      prodTitle: prodTitle,
      prodPrice: prodPrice,
      numItems: count,
      mainImage: mainImage
    }
    if (this.state.selectedOption) {
      payload["prodOpt"] = this.state.selectedOption
    }
    if (this.props.isLoggedIn) {
      return <Button className='buyNow' size="sm" onClick={() => this.handleAddToCartAuthenticated(payload)}>
        Buy Now
      </Button>
    } else {
      return <Button className='buyNow' size="sm" onClick={() => this.handleAddToCartUnauthenticated(payload)}>
        Buy Now
      </Button>
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  handleValidation (payload) {
    const itemCount = payload.numItems || 1
    let available = null
    if (this.props.getVehicleDetail) {
      available = this.props.getVehicleDetail.get_stock_record.quantity || null
      if (available === null || (parseInt(available) < 1)) {
        this.setState({message: "Sorry, item is out of stock"})
        return;
      } else if (parseInt(available) < itemCount) {
        const notEnoughStock = `Sorry, only ${available} available`
        this.setState({message: notEnoughStock})
        return;
      }
      else {

        this.setState({
          addToBagNotification: true
        })
      }
    }
    const { hasOptions, selectedOption } = this.state;
    if (hasOptions){
      if (isEmpty(selectedOption)) {
        this.setState({message: "Error: Select size"})
        return
      }
    }
  }

  handleAddToCartUnauthenticated (payload) {
    let errors = this.handleValidation(payload)
    if (errors) {
    }
    this.props.addCartProduct(payload)

  }

  handleAddToCartAuthenticated (payload) {
    let errors = this.handleValidation(payload)
    if (errors) {
    }
    // let optionsBaseURL = credentials.API_URL
    // let prodUrl = optionsBaseURL + `/products/${payload['prodId']}/`
    // let optionsUrl = payload['prodOpt']['url']
    this.props.addProductToBasket && this.props.addProductToBasket({
      endpointAndQuantity: JSON.stringify(payload)
    })
  }

  search (nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
      if (myArray[i].code === nameKey) {
        return myArray[i];
      }
    }
  }

  handleHide= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      addToBagNotification: false
    })
  };

  handleClick = (e) => {
    // Use updater function when new state is derived from old
    const maxItems = 25
    const minItems = 1
    const currentCount = this.state.count

    if (e === "Increase") {
      if (currentCount < maxItems) {
        this.setState(prev => ({ count: prev.count + 1 }));
      } else {
        return
      }
    } else {
      if (currentCount === minItems) {
        return
      }
      this.setState(prev => ({ count: prev.count - 1 }));
    }

  };

  handleRenderDetails = () => {
    if (this.props.getVehicleDetail && this.props.getPriceSuccess) {
      let getVehicleDetail = this.props.getVehicleDetail
      let prodPrice = this.props.getPriceSuccess.incl_tax
      if (this.state.partsProduct) {
        let jsonOther = JSON.parse(getVehicleDetail.specs.other)
        debugger;
        return <div>
          <PartsDetailComponent
            images={getVehicleDetail.images}
            price={prodPrice}
            prodTitle={getVehicleDetail.prodTitle}
            subtitle={getVehicleDetail.subtitle_clean}
            partNumber={jsonOther.partNumber}
            condition={jsonOther.condition}
            description={getVehicleDetail.description_clean}
            contact={getVehicleDetail.contact}
            location={getVehicleDetail.location}
          />
        </div>
      }
      else {
        return <div>
          <VehicleDetailComponent
            images={getVehicleDetail.images}
            price={prodPrice}
            prodTitle={getVehicleDetail.prodTitle}
            subtitle={getVehicleDetail.subtitle_clean}
            model={getVehicleDetail.specs.body_type}
            year={getVehicleDetail.specs.model_year}
            engineSize={getVehicleDetail.specs.engine_size}
            fuel={getVehicleDetail.specs.fuel_type}
            mileage={getVehicleDetail.specs.mileage}
            colour={getVehicleDetail.specs.colour}
            transmission={getVehicleDetail.specs.transmission}
            description={getVehicleDetail.description_clean}
            contact={getVehicleDetail.contact}
            location={getVehicleDetail.location}
          />

        </div>
      }
    }
  }



  render() {
      const { getPriceSuccess, getVehicleDetailLoader, getVehicleDetail, getPromotionalApiDataSuccess,
        getStockRecordSuccess, addProductToBasketLoader } = this.props;
      let prodPrice = null;
      if (getPriceSuccess) {
        prodPrice = getPriceSuccess.incl_tax
      }
      let searched = [];
      let _images = [];
      let descr = [];
      let prodID = null;
      let prodTitle = null;
      let category = null;

      if(getVehicleDetail) {
          const { id, images, description_clean, title } = getVehicleDetail;
          _images = images;
          descr = description_clean.split(' - ');
          prodID = id
          prodTitle = title
          // mainImage = _images[0].original

      }
      category = getVehicleDetail && getVehicleDetail.categories[0]
      if (getPromotionalApiDataSuccess) {
          const { most_searched: { sedan } } = getPromotionalApiDataSuccess;

          // TODO
          // logic to determine upselling category
          searched = [...sedan];
      }

      let availability = 0

      if (getStockRecordSuccess) {
        availability = getStockRecordSuccess.results[0].num_in_stock - getStockRecordSuccess.results[0].num_allocated
      }

      const pageTitle = getVehicleDetail && getVehicleDetail.title
      const fullTitle = "Magari World: " + pageTitle
      const productClass = getVehicleDetail && getVehicleDetail.product_class
      const classValue = productClass === 'cars' ? '1' : '2'

      return (
        <div ref={topElement => (this.topElement = topElement)}>
          <Helmet>
            <title>{ fullTitle }</title>
            <meta name="description" content="Magari World - Zambia No.1 Auto Marketplace" />
          </Helmet>
          <div className="inner-container" id="contactContainer">
            <div className="row">

              {getVehicleDetailLoader ? (
                <div className="offset-5 offset-lg-6" style={{paddingTop: '100px', paddingBottom: '1220px'}}>
                  <Loading />
                </div>
              ) : (
                  <div className="container">
                    <Breadcrumb style={{margin: "32px 0px"}}>
                      <BreadcrumbItem>
                        <Link to="/" className="listedLink">
                          Home
                        </Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link
                            to="#"
                            className="listedLink"
                            onClick={() => this.handleBreadCrumb('cat', category )}
                        >
                          { category }
                          </Link>{" "}
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        {/*<Link to="#">*/}
                        { getVehicleDetail && getVehicleDetail.title }
                        {/*</Link>*/}
                      </BreadcrumbItem>
                    </Breadcrumb>
                    <div>
                      {this.handleRenderDetails()}
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  export default compose(
      withCookies,
      withRouter
  )(ProductDetailsComponent);
