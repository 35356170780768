import { SIDEMENU_TOGGLE } from "../constants";

export class sideMenuAction {
  static setSideMenu(payload) {
    return {
      type: SIDEMENU_TOGGLE,
      payload
    };
  }
}
