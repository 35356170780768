import { FILTER_COLLAPSE } from "../constants";

export class filterAction {
  // Get Vehicle Make Count
  static setFilterCollapse(payload) {
    return {
      type: FILTER_COLLAPSE,
      payload
    };
  }
}
