import React, { Component } from "react";
import SearchResult from "./SearchResultComponent";

class Home extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      this.props.searchVehicle &&
        this.props.searchVehicle(this.props.match.params);
    }
  }

  componentDidMount() {
    this.props.searchVehicle &&
      this.props.searchVehicle(this.props.match.params);
  }

  render() {
    const { searchVehicleLoader, searchVehicleSuccess } = this.props;
    return (
      <div>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding" style={{paddingLeft: "0px", paddingRight: "0px"}}>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="searchResult" style={{paddingTop: "0px"}}>
                  <SearchResult
                    searchVehicleLoader={searchVehicleLoader}
                    searchVehicleSuccess={searchVehicleSuccess}
                    url={this.props.match.params}
                    {...this.props}
                  />
                  </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
