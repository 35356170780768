import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Breadcrumb, BreadcrumbItem, Button} from "reactstrap";
import {Link} from "react-router-dom";
import credentials from "../../config/credentials";


class PromoToolsComponent extends Component {
  constructor(props) {
    super(props);

    this.onDownloadFile = this.onDownloadFile.bind(this)
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      file: 'png',
      fileName: 'promo',
      loading: 'false'
    };
  }

  componentDidMount() {
    const { history, location, isLoggedIn } = this.props;
    if (!isLoggedIn) {
      history.push("/login", location);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  onDownloadFile(query) {

    const url = `${credentials.API_URL}/pdf-file/?type=${query}`;

    const token = localStorage.getItem('token')
    const fetchPromise = fetch(url, {
     method: 'GET',
     headers: {"Content-Type": "application/json",
               "Authorization": `token ${token}`}
    });
    fetchPromise.then((response) => response.blob())
      .then((blob) => {
      const fileName = "chicargos-promo-" + query
      this.setState({
        fileName: fileName})

      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.state.fileName}.${this.state.file}`);

      // 3. Append to html page
      document.body.appendChild(link);

      // 4. Force download
      link.click();

      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
      })
  }


  render() {

    return (
      <div>
        <Helmet>
          <title>{ "seetutors.co.uk Supporter - Affiliate Programme - Promo Tools" }</title>
          <meta name="description" content="UK Premier import service - Promotional Tools" />
        </Helmet>
        <div className="container cpanel">
          <Breadcrumb>
            <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to="/profile" >Account</Link></BreadcrumbItem>
            <BreadcrumbItem active>Promotion Tools</BreadcrumbItem>
          </Breadcrumb>

          <div className="row">
            <div style={{paddingBottom: '30px'}} className="page-header col-12">
              <h4 className="page-header-content">Promotion Tools</h4>
              <hr className="fancy-line" />
              <br/>
              <div className="col-12" style={{color: '#b0b0b0', fontSize:'12px'}}>
                PLEASE NOTE: MEDIA MATERIAL AVAILABLE TO REGISTERED SUPPORTERS ONLY
              </div>
             <br/>
              <div className="row">
                <div className="col-12 col-sm-4 col-md-4" style={{paddingBottom: '40px'}}>
                  <div>
                    <div style={{color: '#424242'}}>
                      CHICARGOS Business cards
                      <p style={{color: '#b0b0b0', marginBottom: '5px', fontSize: "80%"}}>Size: 3.5inc x 2inc</p>
                    </div>
                    <img
                       style={{height: '200px', paddingTop: '10px', paddingBottom: '15px'}}
                       src={require("../../assets/single-elements/chicargos-business-card.jpeg")}
                       className="mx-auto car-img img-responsive card-img-top"
                       alt="Chicargos business cards"
                     />
                  </div>
                  <div>
                    <div className="a-links" style={{fontSize: '80%', paddingBottom: '5px'}}> 0.01 MB - PNG File </div>
                    <Button
                      className="download-button"
                      size="sm"
                      onClick={() => this.onDownloadFile("bc")}>
                        Download
                    </Button>
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-md-4" style={{paddingBottom: '40px'}}>
                  <div>
                    <div style={{color: '#424242'}}>
                      CHICARGOS Flyers
                      <p style={{color: '#b0b0b0', marginBottom: '5px', fontSize: "80%"}}>Size: A5</p>
                    </div>
                    <img
                       style={{height: '200px', paddingTop: '10px', paddingBottom: '15px'}}
                       src={require("../../assets/single-elements/chicargos-A5-flyer.jpeg")}
                       className="mx-auto car-img img-responsive card-img-top"
                       alt="Chicargos business cards"
                     />
                  </div>
                  <div>
                    <div className="a-links" style={{fontSize: '80%', paddingBottom: '5px'}}> 0.1 MB - PNG File </div>
                    <Button
                      className="download-button"
                      size="sm"
                      onClick={() => this.onDownloadFile("a5")}>
                        Download
                    </Button>
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-md-4" style={{paddingBottom: '40px'}}>
                  <div>
                    <div style={{color: '#424242'}}>
                      CHICARGOS Vinyl Signage
                      <p style={{color: '#b0b0b0', marginBottom: '5px', fontSize: "80%"}}>Size: 1.7m x 0.7</p>
                    </div>
                    <img
                       style={{height: '200px', paddingTop: '10px', paddingBottom: '15px'}}
                       src={require("../../assets/single-elements/chicargo-vinyl-image.jpeg")}
                       className="mx-auto car-img img-responsive card-img-top"
                       alt="Chicargos business cards"
                     />
                  </div>
                  <div>
                    <div className="a-links" style={{fontSize: '80%', paddingBottom: '5px'}}> 1.2 MB - PNG File </div>
                    <Button
                      className="download-button"
                      size="sm"
                      onClick={() => this.onDownloadFile("v-banner")}>
                        Download
                    </Button>
                  </div>
                </div>
              </div>
              <br/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PromoToolsComponent;
