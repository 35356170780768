import { connect } from "react-redux"
import {vehicleAction} from "../store/actions";
import CreateSparesListingComponent from "../components/Vendor/CreateSparesListingComponent"


const mapStateToProps = state => {
  const {
    vehicleReducer: {getProductTypeSuccess, getProductTypeError, getProductTypeLoader, createProductSuccess,
      createProductError, createProductLoader}
  } = state
  return {
    getProductTypeSuccess, getProductTypeError, getProductTypeLoader,createProductSuccess,
    createProductError, createProductLoader
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProductType: payload =>
      dispatch(vehicleAction.getProductType(payload)),
    createProduct: payload =>
      dispatch(vehicleAction.createProduct(payload))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSparesListingComponent);
