import React from "react";
import {

  Card,
  CardBody,
  CardImg,
  CardSubtitle,
} from "reactstrap";


import {withRouter} from "react-router-dom";



const DownlineRecruitsCards = (props) => {

   props.onTitle.bind(this)
   return(
     <div onClick={props.onOpen.bind(this,Object.keys(props.d)[0])} className="col-lg-2 col-md-4 col-6 mt-4" key={props.i}>
       <Card>
         <CardImg
           top
           width="100%"
           className="mx-auto car-img img-responsive"
           src={require("../assets/single-elements/gender-neutral-avatar.png")}
           alt="Card image cap"
         />
         <CardBody style={{ textAlign: 'center' }}>
           <div className="car-body-wrapper cards-body">
             <CardSubtitle>
                {/*eslint-disable-next-line */}
               <a href="#" className="link-text">
                 {Object.keys(props.d)[0]}
               </a>{' '}
             </CardSubtitle>
           </div>
         </CardBody>
       </Card>
      </div>
    );

}

export default withRouter(DownlineRecruitsCards);