import React, { useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { } from '@mui/material/Select';
import Button from '@mui/material/Button';
import {Helmet} from "react-helmet";
import {Loading} from "../LoadingComponent";
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';

import {
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {Link} from "react-router-dom";


export default function CreateCourseComponent(props) {

  const msgRef = React.useRef()

  const [productTypesList, setProductTypesList] = useState([])
  const [productLevelList, setProductLevelList] = useState([])
  const [productSubjectList, setProductSubjectList] = useState([])
  const [product, setProduct] = React.useState('');
  const [level, setLevel] = useState('');
  const [subject, setSubject] = useState('');
  const [subjectId, setSubjectId] = useState('');
  const [prodName, setProdName] = useState('');
  const [error, setError] = useState(false);
  // const [message, setMessage] = useState('');


  const [message, setMessage] = React.useState('')

  useEffect(() => {
    props.getProductType("vehicles")
  }, []);

  useEffect(() => {
    if (props.getProductTypeSuccess && props.getProductTypeSuccess) {
      let prodTypes = Object.keys(props.getProductTypeSuccess.data)
      setProductTypesList(prodTypes)
    }
    if (props.createProductSuccess && props.createProductSuccess) {
      let pId = props.createProductSuccess.data.product_id
      props.history.push(`/vendor/add-content/${pId}`)
    }
    if (props.createProductError && props.createProductError) {
      if (props.createProductError.endsWith('401')) {
        setMessage("Ooops, it look like you're not logged. Sign in and try again")
      }
      if (props.createProductError.endsWith('403')) {
        setMessage("Ooops, your seller account has not been approved yet. Contact customer service")
        window.scrollTo({top: 0, behavior: 'smooth'});
        // document.body.scrollTop = 0
        // window.scrollTo(0,0);
      }
    }
  }, [props])

  const handleCreateCourse = () => {
    if (product === '' || level === '' || subject === '' || prodName === ''){
      setMessage("Please enter a course name")
    } else {
      let newPayload = {subId: subjectId, name: prodName, prodClass: 'vehicles', region: 'ke'}
      props.createProduct(newPayload)
    }
  }

  const handleChange = (event) => {
    setProduct(event.target.value);
    setSubject('')
    setLevel('')
    setProdName('')
    let prodLevels = Object.keys(props.getProductTypeSuccess.data[event.target.value])
    setProductLevelList(prodLevels)
  };

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
    let prodSubjects = props.getProductTypeSuccess.data[product][event.target.value]
    setProductSubjectList(prodSubjects)
  }

  const handleSubjectChange = (event, child) => {
    setSubject(event.target.value);
    setSubjectId(child.props.id)
    setMessage('')
  }

  const handleProdNameChange = (event) => {
    setMessage('')
    setProdName(event.target.value);
  };


  return (
    <div>
      <Helmet>
        <title>Create Vehicle Listing</title>
        <meta name="description" content="Magari World" />
      </Helmet>
      <div className="container cpanel">
        <Breadcrumb>
          <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
          <BreadcrumbItem><Link to="/vendor-dashboard" >Dashboard</Link></BreadcrumbItem>
          <BreadcrumbItem active>Create vehicle listing</BreadcrumbItem>
        </Breadcrumb>
        <div className="row" style={{paddingTop: '20px', paddingBottom: '8px'}}>
          <div className="page-header col-12">
            <h4 className="page-header-content">List a vehicle for sale</h4>
          </div>
        </div>
        <div id="contactContainer">
          <div className="row">
            {props.getProductTypeLoader ? (
              <div className="offset-5 offset-lg-6" style={{paddingTop: "64px", paddingBottom: "800px"}}>
                <Loading />
              </div>
            ) : (
              <div className="container" style={{padding: "0px 16px", textAlign: "center"}}>
                {message ? (
                    <div ref={msgRef}>
                      <Alert severity="error">{message}</Alert>
                    </div>
                ) : (
                  null
                )
                }
                <Box sx={{ maxWidth: "600px", margin: "0 auto", marginBottom: "64px"}}>
                  <h6 className="headings-create-product">Select vehicle type</h6>
                  <FormControl fullWidth style={{paddingBottom: "16px"}}>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={product}
                      label="Course Type"
                      className="mui-select"
                      onChange={handleChange}
                    >
                      { productTypesList.map((productType) => (
                        <MenuItem value={productType}>{productType}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  { product ? (
                    <div>
                      <h6 className="headings-create-product">Select Make</h6>
                      <FormControl fullWidth style={{paddingBottom: "16px"}}>
                        <InputLabel id="demo-simple-level-label">Make</InputLabel>
                        <Select
                          labelId="demo-simple-select-level"
                          id="demo-simple-select"
                          value={level}
                          label="Course Type"
                          onChange={handleLevelChange}
                        >
                          { productLevelList.map((productLevel) => (
                            <MenuItem value={productLevel}>{productLevel}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ): (null)
                  }
                  { level ? (
                    <div>
                      <h6 className="headings-create-product">Select Model</h6>
                      <FormControl fullWidth style={{paddingBottom: "16px"}}>
                        <InputLabel id="demo-simple-level-label">Model</InputLabel>
                        <Select
                          labelId="demo-simple-select-level"
                          id="demo-simple-select"
                          value={subject}
                          label="Subject"
                          onChange={handleSubjectChange}
                        >
                          { productSubjectList.map((productSubject) => (
                            <MenuItem id={productSubject.subj_id}
                                      value={productSubject.subj_name}>
                              {productSubject.subj_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    </div>
                  ): (null)
                  }
                  { subject ? (
                    <div>
                      <h6 className="headings-create-product">Enter full vehicle title</h6>
                      <p style={{fontSize: '0.75rem'}}>Use this format: Year → Make → Model → Variant</p>
                      <TextField
                        id="outlined-basic"
                        label="e.g 2019 Mercedes Benz E320 CDI AMG"
                        fullWidth
                        value={prodName}
                        onChange={handleProdNameChange}
                        variant="outlined" />
                      <div style={{padding: "32px 24px"}}>
                        <Button variant="contained"  className="mui-buttons" onClick={() => handleCreateCourse()}>
                          Create listing
                        </Button>
                      </div>
                    </div>
                  ): (null)}
                </Box>
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  )
}