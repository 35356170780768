import React, {Component} from 'react';

class HowToBuy extends Component {
  render() {
    return (
      <div>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h4 className="page-header-content" style={{ color: '#b60811'}}>HOW TO BUY</h4>
                  <hr className="fancy-line" />
                  <br />
                  <div className="col-lg-12">
                    <div className="row mt-4 bottom-out-space">
                      <div className="col-lg-12 help-cards">
                        <div className="inner-how-to-cards">
                        <div className="a-column">
                          <h6 className="info-graphics-heading">Step 1</h6>
                          <div className="row">
                            <div className="col-12" style={{paddingBottom: '20px', paddingLeft: '0px', paddingRight: '0px'}} >
                              <div className="mx-auto car-img img-responsive card-img-top how-to-steps">SEARCH FOR PRODUCT</div>
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px', paddingLeft: '0px', paddingRight: '0px'}} >
                              <div className="mx-auto car-img img-responsive card-img-top how-to-steps">SELECT SHIPPING OPTIONS & CLICK BUY NOW </div>
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px', paddingLeft: '0px', paddingRight: '0px'}} >
                              <div className="mx-auto car-img img-responsive card-img-top how-to-steps">PROCEED TO CHECKOUT & COMPLETE DETAILS</div>
                            </div>
                          </div>
                        </div>
                        <div className="b-column">
                          <h6 className="info-graphics-heading"> Order </h6>
                          <div className="row">
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <img
                              src={require("../assets/single-elements/chicargos-search-product.jpg")}
                              className="mx-auto car-img img-responsive card-img-top"
                              alt="how to buy"
                              />
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <img
                              src={require("../assets/single-elements/chicargos-product-detail.jpg")}
                              className="mx-auto car-img img-responsive card-img-top"
                              alt="how to buy detail page"
                              />
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <img
                              src={require("../assets/single-elements/chicargos-cart-page.jpg")}
                              className="mx-auto car-img img-responsive card-img-top"
                              alt="how to buy"
                              />
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div className="col-lg-12 help-cards">
                        <div className="inner-how-to-cards">
                        <div className="a-column">
                          <h6 className="info-graphics-heading">Step 2</h6>
                          <div className="row">
                            <div className="col-12" style={{paddingBottom: '20px', paddingLeft: '0px', paddingRight: '0px'}} >
                              <div className="mx-auto car-img img-responsive card-img-top how-to-steps">CONTACT YOUR BANK AND MAKE PAYMENT</div>
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px', paddingLeft: '0px', paddingRight: '0px'}} >
                              <div className="mx-auto car-img img-responsive card-img-top how-to-steps">BANK DETAILS ARE FOUND ON PRO-FORMA INVOICE</div>
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px', paddingLeft: '0px', paddingRight: '0px'}} >
                              <div className="mx-auto car-img img-responsive card-img-top how-to-steps">CONTACT CHICARGOS TO CONFIRM PAYMENT</div>
                            </div>
                          </div>
                        </div>
                        <div className="b-column">
                          <h6 className="info-graphics-heading"> Payment </h6>
                          <div className="row">
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <img
                              src={require("../assets/single-elements/exukonline-bank.png")}
                              className="mx-auto car-img img-responsive card-img-top"
                              alt="how to buy"
                              />
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <img
                              src={require("../assets/single-elements/exukonline-document.png")}
                              className="mx-auto car-img img-responsive card-img-top"
                              alt="how to buy"
                              />
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <img
                              src={require("../assets/single-elements/exukonline-paid-in-full.png")}
                              className="mx-auto car-img img-responsive card-img-top"
                              alt="how to buy"
                              />
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div className="col-lg-12 help-cards">
                        <div className="inner-how-to-cards">
                        <div className="a-column">
                          <h6 className="info-graphics-heading">Step 3</h6>
                          <div className="row">
                            <div className="col-12" style={{paddingBottom: '20px', paddingLeft: '0px', paddingRight: '0px'}} >
                              <div className="mx-auto car-img img-responsive card-img-top how-to-steps">CHICARGOS WILL CONFIRM DISPATCH DATE</div>
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px', paddingLeft: '0px', paddingRight: '0px'}} >
                              <div className="mx-auto car-img img-responsive card-img-top how-to-steps">SHIP ARRIVES AT PORT</div>
                            </div>
                          </div>
                        </div>
                        <div className="b-column">
                          <h6 className="info-graphics-heading"> Shipment </h6>
                          <div className="row">
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <img
                              src={require("../assets/single-elements/exukonlione-calendar.png")}
                              className="mx-auto car-img img-responsive card-img-top"
                              alt="how to buy"
                              />
                            </div>
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <img
                              src={require("../assets/single-elements/exukonline-shipping-port.jpg")}
                              className="mx-auto car-img img-responsive card-img-top"
                              alt="how to buy"
                              />
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                      <div className="col-lg-12 help-cards">
                        <div className="inner-how-to-cards">
                        <div className="a-column">
                          <h6 className="info-graphics-heading">Step 4</h6>
                          <div className="row">
                            <div className="col-12 how-to-steps" >
                              ENJOY YOUR VEHICLE
                            </div>
                          </div>
                        </div>
                        <div className="b-column">
                          <h6 className="info-graphics-heading"> Delivery </h6>
                          <div className="row">
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <img
                              src={require("../assets/single-elements/exukonline-delivered-car.png")}
                              className="mx-auto car-img img-responsive card-img-top"
                              alt="how to buy"
                              />
                            </div>
                          </div>
                          {/*<img*/}
                          {/*  src={require("../assets/single-elements/exyookay-how-to-edited.png")}*/}
                          {/*  className="how-to-cards-img"*/}
                          {/*  alt="how to buy"*/}
                          {/*/>*/}
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="" style={{ textAlign: 'center' }}>
                  <h3 className="page-header-content">HOW TO PAY</h3>
                  <hr className="fancy-line" />
                  <br />
                  <div className="col-lg-12">
                    <div className="row mt-4 bottom-out-space">
                      <div className="col-lg-12 help-cards">
                        <div className="inner-how-to-cards">
                        <div className="a-column">
                          <h6 className="info-graphics-heading">Option 1</h6>
                          <div className="row">
                            <div className="col-12 how-to-steps" >
                              BANK WIRE TRANSFER
                            </div>
                          </div>
                        </div>
                        <div className="b-column">
                          <h6 className="info-graphics-heading"> Delivery </h6>
                          <div className="row">
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <img
                              src={require("../assets/single-elements/exukonline-bank-transfer-logo.png")}
                              className="mx-auto car-img img-responsive card-img-top"
                              alt="how to buy"
                              />
                            </div>
                          </div>
                          {/*<img*/}
                          {/*  src={require("../assets/single-elements/exyookay-how-to-edited.png")}*/}
                          {/*  className="how-to-cards-img"*/}
                          {/*  alt="how to buy"*/}
                          {/*/>*/}
                        </div>
                        </div>
                      </div>
                      <div className="col-lg-12 help-cards">
                        <div className="inner-how-to-cards">
                        <div className="a-column">
                          <h6 className="info-graphics-heading">Option 2</h6>
                          <div className="row">
                            <div className="col-12 how-to-steps" >
                              BITCOIN
                            </div>
                          </div>
                        </div>
                        <div className="b-column">
                          <h6 className="info-graphics-heading"> Bitcoin </h6>
                          <div className="row">
                            <div className="col-12" style={{paddingBottom: '20px'}}>
                              <img
                              src={require("../assets/single-elements/exukonline-bitcoin.png")}
                              className="mx-auto car-img img-responsive card-img-top"
                              alt="how to buy"
                              />
                            </div>
                          </div>
                          {/*<img*/}
                          {/*  src={require("../assets/single-elements/exyookay-how-to-edited.png")}*/}
                          {/*  className="how-to-cards-img"*/}
                          {/*  alt="how to buy"*/}
                          {/*/>*/}
                        </div>
                        </div>
                      </div>
                   </div>
                  </div>
                  <hr />
                </div>
              </div>

           </div>
           </div>
          </div>
        </section>
      </div>
    )
  }

}

export default HowToBuy;
