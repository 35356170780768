import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { blue, common } from '@mui/material/colors';
import NumberFormat from "react-number-format";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';


export function OrderListComponent(props) {
  const { onClose, selectedValue, open, propLine, address } = props;
  const [scroll, setScroll] = React.useState('paper');

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle style={{fontWeight: "bold"}}>Order Summary</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <div>
            {propLine && JSON.parse(propLine).map((order) => (
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: common["white"], color: blue[50] }}>
                    <img
                      style={{height: "32px"}}
                      src={order.image}
                    />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText>
                  <div>
                    {order.title}
                  </div>
                  <div>
                    Quantity: {order.qty}
                  </div>
                </ListItemText>
                <ListItemText style={{textAlign: "right"}}>
                  <NumberFormat className="col-3-custom basket-page-information"
                                value={((parseFloat(order.price) * order.qty)).toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'£'}/>
                </ListItemText>
              </ListItem>
            ))}
          </div>
          <DialogTitle style={{fontWeight: "bold"}}>Delivery Address</DialogTitle>
          <ListItemText>
            <div style={{padding: "16px 24px"}}>
              {address}
            </div>
          </ListItemText>
        </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

OrderListComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
