import React from "react";
import {Button, Card, CardBody, CardImg, CardSubtitle, CardText} from "reactstrap";
import NumberFormat from "react-number-format";
import {withRouter} from "react-router-dom";

const stringSorten = str => {
  const arrayOfString = str ? str.trim().split(" ") : null;
  if (!arrayOfString) return
  if ((arrayOfString && arrayOfString[0].length > 8) || arrayOfString[0].length > 8) {
    return arrayOfString[0];
  }
  return arrayOfString[0] + " " + arrayOfString[1] + " " + arrayOfString[2];
};

const routeToDetails = (props) => {
  const { history } = props;
  history.push(`${props.d.id}`);

};

const AccoladesPromoElements = (props) => {
  return(
    <div className="col-lg-4 col-md-4 col-sm-4 col-12 mt-4"
         style={{marginBottom: '1.5rem', cursor: 'pointer'}} key={props.i}>
      <Card style={{backgroundColor: '#f3f3f3', borderRadius: '16px', padding: '24px 8px 8px 8px'}}>
        <CardImg
          top
          // width="100%"
          className="mx-auto car-img img-responsive card-img-top-promo"
          style={{ maxHeight: '60px', objectFit: 'contain'}}
          src={props.d.remote_image}
          alt="Card image cap"
        />
        <CardBody style={{ textAlign: 'center', paddingBottom: '0px'}}>
          <div className="car-body-wrapper cards-body">
            <CardSubtitle style={{ paddingBottom: '10px', fontSize: '15px', textAlign: 'center'}}>
              {/*eslint-disable-next-line*/}
              <div className="link-text">
                {props.d.title}
              </div>{' '}
            </CardSubtitle>
          </div>

        </CardBody>
      </Card>
      {/*<hr style={{width: '80%'}}/>*/}
    </div>
  );

}

export default withRouter(AccoladesPromoElements);