import React, {Component} from 'react';
import {Helmet} from "react-helmet";

import {
  Button
} from "reactstrap";

class NotFound extends Component {
  backToHome () {
    const {history} = this.props
    history.push({
      pathname: "/",
    })
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Magari World - Page Not Found</title>
          <meta name="description"
                content="The page you've requested has not been found" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="static-info-page">
                  <br/>
                  <div className="" style={{ textAlign: 'center' }}>
                    <h4 className="page-header-content" style={{ color: '#b60811', fontSize: "30px"}}>
                      <span style={{color: '#bfbfbf'}}>Oops,</span> sorry we couldn't find that page</h4>
                    <br/>
                    <div className="col-lg-6 col-sm-12 offset-lg-3">
                      <div className="page-not-found">
                      </div>
                    </div>
                    <br/>
                    <br/>
                    <div>
                      <Button
                        className="download-button"
                        size="sm"
                        style={{ fontSize: '16px' }}
                        onClick={() => this.backToHome()}
                      >
                        Go to home page
                      </Button>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

}

export default NotFound;
