import AutoParts from './../components/AutoParts';
import { connect } from "react-redux";
import { vehicleAction } from "./../store/actions";

const mapStateToProps = state => {
  const {
    vehicleReducer: {
      getProductCategoriesLoader,
      getProductCategoriesSuccess,
      getProductCategoriesError,
      getAllProductsLoader,
      getAllProductsSuccess,
      getAllProductsError
    }
  } = state;
  return {
    getProductCategoriesLoader,
    getProductCategoriesSuccess,
    getProductCategoriesError,
    getAllProductsLoader,
    getAllProductsSuccess,
    getAllProductsError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProductCategories: payload =>
      dispatch(vehicleAction.getProductCategories(payload)),
    getAllProducts: payload =>
      dispatch(vehicleAction.getAllProducts(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoParts);