
import {withRouter} from "react-router-dom";
import React from "react";


const AgentSuccess = (props) => {
   return(
     <div style={{ maxWidth: '500px', margin: 'auto', padding: '10px',
       textAlign: 'center'}}>
       <i className="fa fa-check-circle" style={{fontSize:"40px", color:"#009800"}}></i>
            <p></p>
       <h4 style={{fontWeight: 800}}>Congratulations!</h4>
       <br/>
       <p>Welcome to the Zed Auto Seller Programme</p>
       Find your online store ID below
       <p> For more information on how list items for sale, click on on the help link</p>
      </div>
    );

}

export default withRouter(AgentSuccess);
