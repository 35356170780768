import React from 'react'
import { Alert } from "reactstrap";

const ResetConfirmation = () => {
  return (
    <div>
      <header className="headerbg d-flex">
        <div className="container my-auto">
          <div className="row">
            <div className="offset-1 col-10 offset-lg-4 col-lg-4">
              <div id="search-form-div" className="container">
                <div className="row">
                  <div className="col-12 my-4">
                    <div className="fat-div">
                    <Alert color="success">
                      Reset Password Confirmation
                    </Alert>
                    <div className="col-12 page-information exp">
                      Your password has been reset. Please click <a href="/login" className="a-links"> here </a> to log in
                    </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </header>

      </div>
  )
}

export default ResetConfirmation
