import React, { Component } from "react";
import ReactMarkdown from "react-markdown";


function Image(props) {
  return <img {...props} style={{maxWidth: '20px'}} />
}

class ReactMarkDownComponent extends Component {
  render() {
    const {input} = this.props
    return (
      <ReactMarkdown
        className="line-breaker"
        remarkPlugins={[]}
        rehypePlugins={[]}
        children={input}
        renderers={{image: Image}}
      />
    )
  }
}

export default ReactMarkDownComponent