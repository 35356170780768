import Home from "./../components/Search/SearchComponent";
import { connect } from "react-redux";
import { vehicleAction, sideMenuAction } from "./../store/actions";

const mapStateToProps = state => {
  const {
    vehicleReducer: {
      searchVehicleSuccess,
      searchVehicleError,
      searchVehicleLoader
    }
  } = state;
  return {
    searchVehicleSuccess,
    searchVehicleError,
    searchVehicleLoader,
    filterReducer: state.filterReducer,
    sideMenuReducer: state.sideMenuReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchVehicle: payload => dispatch(vehicleAction.searchVehicle(payload)),
    sideMenuAction: payload => dispatch(sideMenuAction.setSideMenu(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
