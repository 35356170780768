import React, { useState, useEffect, useReducer } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Loading} from "../LoadingComponent";
import Typography from '@mui/material/Typography';
import DialogActions from "@mui/material/DialogActions";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Alert} from "reactstrap";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';

const FileUploadComponent = ({sendRequest,
                            uploadSuccess,
                            uploadImageError,
                            uploadImageLoader,
                            updateCoverImg,
                            courseId,
                            displayOrder,
                            saveDisabled
                          }) => {

  const [image, setImage] = useState({ preview: "", file: "" });
  const [open, setOpen] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [upload, setUpload] = useState(false)
  const [url, setURL] = useState('')
  const [copied, setCopied] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [copyText, setCopyText] = useState('Copy')
  const [, forceUpdate] = useReducer(x => x + 1, 0);


  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      });
    }
  };

  const handleDialogOpen = () => {
    if (!saveDisabled) {
      setOpenNotification(true)
      return;
    }
    setOpen(true)
  }

  const onUploadAnother = () => {
    setURL('')
    setImage({ preview: "", file: "" })
    setCopied(false)
    setCopyText('Copy')
  }

  useEffect(() => {
    if (upload === true) {
      if (image.file && image.file) {
        const formData = new FormData();
        formData.append("image", image.file);
        formData.append("type", "gallery")
        formData.append("do", displayOrder)
        formData.append("cid", courseId)
        sendRequest(formData)
        setUpload(false)
      }
      else {
        setUpload(false)
      }
    }

  }, [upload]);

  useEffect(()=> {
    if (uploadSuccess && uploadSuccess.data) {
      updateCoverImg(uploadSuccess.data.url)
      setOpen(false)
      window.location.reload();
    }
  }, [uploadSuccess && uploadSuccess.data])

  useEffect(()=> {
    if (uploadImageError && uploadImageError.endsWith("400")) {
      setErrMsg('Oops wrong file format, upload images only (jpg or png)')
    }
    if (uploadImageError && uploadImageError.endsWith("401")) {
      setErrMsg('Unauthorized error - Login and try again')
    }
    else {
      setErrMsg('Something has gone wrong, contact customer support')
    }
    if (uploadImageError && uploadImageError.data) {
      // setURL(uploadSuccess.data.url)
      updateCoverImg(uploadSuccess.data.url)
      setOpen(false)
    }
  }, [uploadImageError])

  useEffect(()=> {
    if (copied){
      setCopyText('Copied')
    }
  }, [copied])


  return (
    <div style={{paddingBottom: '24px'}}>
      <div style={{textAlign: 'center', paddingTop: '16px'}}>

        <Button
          disableElevation
          variant="contained"
          onClick={() => handleDialogOpen()}
          className="mui-buttons"
          endIcon={<FileUploadRoundedIcon/>}
        >
          Upload
        </Button>
      </div>
      <Dialog open={openNotification}
              onClose={()=> setOpen(false)}
              fullWidth={true}
              maxWidth={'md'}>
        <DialogTitle style={{fontWeight: 800, fontSize: "18px"}}>Save your changes</DialogTitle>
        <DialogContent>
          <Alert color="danger">
            Save your changes first before uploading images
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            onClick={()=> setOpenNotification(false)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={()=> setOpen(false)}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle style={{fontWeight: 800, fontSize: "18px"}}>Update Cover Image</DialogTitle>
        <DialogContent>
          {uploadImageError ?
            (<Alert color="danger">
              {errMsg}
            </Alert>)
            :
            (null)}
          {url ?
            (<div>
              <input value={url} size="80" disabled={true}/>
              <CopyToClipboard text={url}
                               onCopy={() => setCopied(true)}>
                <Button>{copyText}</Button>
              </CopyToClipboard>
            </div>)
            :
            (<div>
              <div style={{margin: "8px 0px 24px 0px"}}>
                <Typography>Upload image</Typography>
              </div>
              {uploadImageLoader && (
                <div>
                  <Loading/>
                </div>
              )}
              {image.file && (
                <div>
                  <img alt="not found" width={"250px"} src={URL.createObjectURL(image.file)} />
                  <br />
                  <div style={{margin: "16px 0px 8px 0px"}}>
                    <Button
                      disabled={uploadImageLoader}
                      onClick={()=>setImage({ preview: "", file: "" })}>Remove</Button>
                  </div>

                </div>
              )}
              <br />
              <input
                type="file"
                name="myImage"
                accept="image/*"
                disabled={uploadImageLoader}
                onChange={(event) => {
                  handleChange(event)
                }}
              />
            </div>)}
        </DialogContent>
        <DialogActions>
          {url ? (
              <div>
                <Button
                  disableElevation
                  variant="contained"
                  onClick={onUploadAnother}
                  style={{marginRight: "8px"}}
                >
                  Upload another
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  onClick={()=> setOpen(false)}
                >
                  Close
                </Button>
              </div>
            )
            : (
              <div>
                <Button
                  disableElevation
                  variant="contained"
                  style={{marginRight: "8px"}}
                  onClick={()=> setOpen(false)}
                >Cancel</Button>
                <Button
                  disableElevation
                  variant="contained"
                  disabled={!(image.file && image.file) || uploadImageLoader}
                  onClick={()=>setUpload(true)}>Upload</Button>
              </div>
            )}

        </DialogActions>
      </Dialog>

    </div>
  );
};

export default FileUploadComponent;