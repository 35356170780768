import React, {useEffect, useRef, useState} from 'react';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import ReactHtmlParser from 'react-html-parser';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import StudioHeader from "./StudioHeader";
import StudioLoader from "./StudioLoader";
import {Col, Row, Table} from "reactstrap";
// import Alert from '@mui/material/Alert';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import ResponsiveAppBar from "./ResponsiveAppBar"
import TextField from "@mui/material/TextField";
import VehicleDetailComponent from "./VehicleDetailComponent";

import Paper from '@mui/material/Paper';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';

import FileUploadComponent from "./FileUploadComponent";
import NumberFormat from "react-number-format";



export default function AddContentComponent(props) {
  const [multiEditor, setMultiEditor] = useState({rte1: EditorState.createEmpty()})
  const [value, setValue] = React.useState(0);
  const [prodTitle, setProdTitle] = useState('')
  const [prodCat, setProdCategory] = useState('')
  const [dateCreated, setDateCreated] = useState('')
  const itemRef = useRef(null);

  const [open, setOpen] = React.useState(false);
  const [inputValues, setInputValues] = useState({})
  const [updateDisabled, setUpdateDisabled] = React.useState(true)
  const [lectureId, setLectureId] = useState(undefined)
  const [lectureList, setLectureList] = useState([])
  const [courseId, setCourseId] = useState(null)
  const [coverImg, setCoverImg] = useState('https://www.open.edu/openlearn/pluginfile.php/3277384/tool_ocwmanage/articletext/0/become_a_student_inline.jpg')
  const [gallery, setGallery]  = useState({
    0: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
    1: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
    2: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
    3: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg'
  })
  const [images, setImages] = useState([  {
    original: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
    thumbnail: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
    },
    {
      original: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
      thumbnail: 'https://seetutors.s3.eu-west-2.amazonaws.com/god-25-10-22-21-33-38-exam-revision.jpg',
    }])
  const [title, setTitle] = React.useState('');
  const [author, setAuthor] = React.useState('');
  const [notify, setNotify] = React.useState(false)
  const [error, setError] = useState(
    {
      contact: false,
      mileage: false,
      location: false,
      price: false,
      title: false,
      engine: false,
      description: false
    }
  )

  const [openForm, setOpenForm] = React.useState(false);
  const [metaChanged, setMetaChange] = useState(false);
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [publish, setPublish] = useState('draft');
  const [lectureTitle, setLectureTitle] = useState('')
  const [model, setModel] = useState('')
  const [engineSize, setEngineSize] = useState('')
  const [mileage, setMileage] = useState('')
  const [transmission, setTransmission] = useState('')
  const [fuel, setFuel] = useState('')
  const [bodyType, setBodyType] = useState('')
  const [colour, setColour] = useState('')
  const [yearOptions, setYearOptions] = useState([])
  const [fuelOptions, setFuelOptions] = useState([])
  const [bodyOptions, setBodyOptions] = useState([])
  const [transmissionOptions, setTransmissionOptions] = useState([])
  const [year, setYear] = useState('')
  const [contact, setContact] = useState('')
  const [location, setLocation] = useState('')
  const [price, setPrice] = useState('')

  const handleChangePublish = (event) => {
    if (event.target.checked) {
      if (!location || !contact || !mileage || !mileage || !price || !prodTitle || !engineSize || !description){
        setError({
          ...error,
          location:!location,
          contact: !contact,
          mileage: !mileage,
          price: !price,
          title: !prodTitle,
          engine: !engineSize,
          description: !description
        })
        setNotify(true)
        return
      }
      setPublish('live');
    } else {
      setPublish('draft')
    }
    setUpdateDisabled(false)
    setMetaChange(true)
  };


  const handleFormChange = (event) => {
    if (event.target.id === 'title') {
      setTitle(event.target.value);
    } else if (event.target.id === 'author') {
      setAuthor(event.target.value)
    } else if (event.target.id === 'subtitle') {
      setSubtitle(event.target.value)
      setMetaChange(true)
    } else if (event.target.id === 'description') {
      setDescription(event.target.value)
      setError({
        ...error,
        description: false,
      })
      setMetaChange(true)
    } else if (event.target.id === 'prodTitle') {
      setProdTitle(event.target.value)
      setMetaChange(true)
    } else if (event.target.id === 'model') {
      setModel(event.target.value)
    } else if (event.target.id === 'year') {
      setYear(event.target.value)
    }
    setUpdateDisabled(false)
  };


  const filterCurrentLecture = (resObj) => {
    let currLectureId = [parseInt(props.match.params.lid)]
    return resObj.filter(function (item) {
      return currLectureId.indexOf(item.lecture_id) > -1;
    })
  }

  useEffect(() => {
    let contentID = props.match.params.cid
    let lectureID = props.match.params.lid
    let contentObj = {cid: contentID, lid: lectureID}
    if (props.postContentSuccess && props.postContentSuccess) {
      props.getContent(contentObj)
    }
  }, [props.postContentSuccess && props.postContentSuccess.data])

  useEffect(() => {
    if (props.createLectureSuccess && props.createLectureSuccess) {
      setLectureList(props.createLectureSuccess.data.lecture_list)
      setOpenForm(false)
    }
  }, [props.createLectureSuccess && props.createLectureSuccess])

  useEffect(() => {
    if (props.postContentLoader === true) {
      setOpen(true)
      setUpdateDisabled(true)
    }
  }, [props.postContentLoader])

  useEffect(() => {
    if (props.deleteContentSuccess !== null ) {
      let contentObj = {cid: courseId, lid: undefined}
      if (props.match.params.lid) {
        props.history.push(`/vendor/add-content/${courseId}`)
      } else {
        props.getContent(contentObj)
      }
      setValue(1)
    }
  }, [props.deleteContentSuccess && props.deleteContentSuccess.data])

  useEffect(() => {
    if (props.getContentError !== null) {
      if (props.getContentError.endsWith('401')) {
        props.history.push("/profile")
        // setErrMsg('Unauthorized error - Login and try again')
      }
    }
  }, [props.getContentError && props.getContentError])

  useEffect(() => {
    if (props.getContentSuccess && props.getContentSuccess) {
      setProdTitle(props.getContentSuccess.data.prod_title)
      setContact(props.getContentSuccess.data.prod_contact)
      setLocation(props.getContentSuccess.data.prod_location)
      setPrice(props.getContentSuccess.data.prod_price)
      setYearOptions(props.getContentSuccess.data.specs.yearOptions)
      setTransmissionOptions(props.getContentSuccess.data.specs.transmissionOptions)
      setFuelOptions(props.getContentSuccess.data.specs.fuelOptions)
      setBodyOptions(props.getContentSuccess.data.specs.bodyTypeOptions)
      setModel(props.getContentSuccess.data.specs.model)
      setYear(props.getContentSuccess.data.specs.year)
      setTransmission(props.getContentSuccess.data.specs.transmission)
      setMileage(props.getContentSuccess.data.specs.mileage)
      setFuel(props.getContentSuccess.data.specs.fuel)
      setBodyType(props.getContentSuccess.data.specs.bodyType)
      setEngineSize(props.getContentSuccess.data.specs.engineSize)
      setColour(props.getContentSuccess.data.specs.colour)
      setSubtitle(props.getContentSuccess.data.prod_subtitle)
      setDescription(props.getContentSuccess.data.prod_description)
      setProdCategory(props.getContentSuccess.data.cat_full_name)
      setPublish(props.getContentSuccess.data.prod_live)
      setDateCreated(props.getContentSuccess.data.date_created)
      setLectureList(props.getContentSuccess.data.lecture_list)
      if ((props.getContentSuccess.data.lecture_list).length > 0 && props.match.params.lid !== undefined) {
        let currLec = filterCurrentLecture(props.getContentSuccess.data.lecture_list)
        setLectureTitle(currLec[0].title)
      }
      setCoverImg(props.getContentSuccess.data.cover_image)
      setGallery(props.getContentSuccess.data.gallery_images)
      let imagesForGallery = []
      for (let [key, value] of Object.entries(props.getContentSuccess.data.gallery_images)) {
        imagesForGallery.push({original: value, thumbnail: value})
      }
      setImages(imagesForGallery)
      if (Object.keys(props.getContentSuccess.data).length > 0) {
        if (props.match.params.lid) {
          let contentObj = {}
          for (let [key, value] of Object.entries(props.getContentSuccess.data.editors)) {
            if (key.endsWith("_raw")) {
              let con = convertFromRaw(JSON.parse(value))
              let con_conv = EditorState.createWithContent(con)
              let ed_key = key.split("_")[0]
              contentObj[ed_key] = con_conv
              contentObj[key] = value
            }
          }
          setInputValues(props.getContentSuccess.data.lectures)
          setUpdateDisabled(true)
          setMultiEditor(contentObj)
        }
        setOpen(false)
      }
    }

  }, [props.getContentSuccess && props.getContentSuccess.data])

  useEffect(() => {
    let contentID = props.match.params.cid
    let lectureID = props.match.params.lid
    let contentObj = {cid: contentID, lid: lectureID}
    setLectureId(lectureID)
    setCourseId(contentID)
    if (contentID !== null) {
      props.getContent(contentObj)
    }
    if (lectureID !== undefined){
      setValue(2)
    }

  }, [])

  useEffect(() => {
    if (itemRef && itemRef.current) {
      window.scrollTo({
        top: itemRef.current.offsetTop - 300,
      });
    }
  },[value]);

  const handleClose = () => {
    setOpen(false);
  };


  const handleSubmitContent = () => {
    setNotify(false)
    let contentID = props.match.params.cid
    let contentObj = {
      cid: contentID,
    }
    contentObj['subtitle'] = subtitle
    contentObj['description'] = description
    contentObj['publish'] = publish
    contentObj['pTitle'] = prodTitle
    contentObj['pContact'] = contact
    contentObj['pLocation'] = location
    contentObj['sBody'] = bodyType
    contentObj['sEngine'] = engineSize
    contentObj['sMileage'] = mileage
    contentObj['sTransmission'] = transmission
    contentObj['sFuel'] = fuel
    contentObj['sColour'] = colour
    contentObj['sYear'] = year
    contentObj['pPrice'] = price

    props.postContent(contentObj)
  }

  return (
    <div>
      <ResponsiveAppBar
        disableButton={updateDisabled}
        onSubmit={handleSubmitContent}
      />

      {props.postContentLoader && props.postContentLoader ?
        <Box sx={{width: '100%'}}>
          <LinearProgress/>
        </Box> :
        null
      }
      <div className="container" style={{backgroundColor: "white", marginTop: "64px"}}>
        <div>
          <Box
            className="studio-box"
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'block', minHeight: 364}}
            >
            <StudioLoader onClose={handleClose} open={open}/>
            <div>
              <StudioHeader
                title="Vehicle Information"
                info="This is the vehicle details section"
              />
            </div>
            {notify ?
              <div style={{paddingBottom: "40px"}}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  One of the required field is empty — <strong>check your input</strong>
                </Alert>
              </div>
            :
              null
            }


            <div>
              <Table bordered striped size="md" >
                <tbody>
                <tr>
                  <td className="tbl-header">Publish:</td>
                  <td className="tbl-data">
                    <Switch
                      size="large"
                      checked={publish === 'live'}
                      onChange={handleChangePublish}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tbl-header">Date created:</td>
                  <td className="tbl-data">{dateCreated}</td>
                </tr>
                <tr>
                  <td className="tbl-header">Category:</td>
                  <td className="tbl-data">{prodCat}</td>
                </tr>
                </tbody>
              </Table>
            </div>
            <div style={{padding: "16px 0px 16px 0px"}}>
              <h6 className="page-header-content">Details</h6>
              <Row>
                <Col style={{padding: "0px"}}>
                  <TextField
                    error={error["price"]}
                    fullWidth
                    id="price"
                    label="Price"
                    value={price}
                    onChange={e =>
                    {setPrice(e.target.value); setUpdateDisabled(false); setError({
                      ...error,
                      price: false,
                    })}}
                    variant="outlined"
                    style={{margin: "8px 0px", backgroundColor: "white"}}
                    helperText="Field required"
                  />
                </Col>
                <Col>

                </Col>
              </Row>
              <Row>
                <Col style={{padding: "0px"}}>
                  <TextField
                    error={error["contact"]}
                    fullWidth
                    id="contact"
                    label="Contact number"
                    value={contact}
                    onChange={e =>
                    {setContact(e.target.value); setUpdateDisabled(false); setError({
                      ...error,
                      contact: false,
                    })}}
                    variant="outlined"
                    style={{margin: "8px 0px", backgroundColor: "white"}}
                    helperText="Field required"
                  />
                </Col>
                <Col>
                  <TextField
                    error={error["location"]}
                    fullWidth
                    id="location"
                    label="Vehicle location"
                    value={location}
                    onChange={e =>
                    {setLocation(e.target.value); setUpdateDisabled(false); setError({
                      ...error,
                      location: false,
                    })}}
                    variant="outlined"
                    style={{margin: "8px 0px", backgroundColor: "white"}}
                    helperText="Field required"
                  />
                </Col>
              </Row>
            </div>
            <div style={{padding: "16px 0px 32px 0px"}}>
                <Row>
                  <Col sm="12">
                    <div>
                      <h6 className="page-header-content">Specification</h6>
                      <TextField
                        error={error["title"]}
                        fullWidth
                        id="prodTitle"
                        label="Enter vehicle title - (max 150 characters)"
                        value={prodTitle}
                        onChange={handleFormChange}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 150 }}
                        style={{margin: "16px 0px", backgroundColor: "white"}}
                        helperText="Field required"
                      />
                      <Row style={{paddingBottom: "32px"}}>
                        <Col style={{paddingLeft: "0px"}}>
                          <TextField
                            fullWidth
                            id="subtitle"
                            label="Enter listing subtitle - e.g All discs valid"
                            value={subtitle}
                            onChange={handleFormChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 100 }}
                            style={{margin: "24px 0px", backgroundColor: "white"}}
                          />
                        </Col>
                        <Col style={{paddingRight: "0px"}}>
                          <FormControl fullWidth style={{margin: "24px 0px"}}>
                            <InputLabel id="body type">Body Type</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="bodyType"
                              value={bodyType}
                              label="model"
                              className="mui-select"
                              onChange={e => {
                                setBodyType(e.target.value); setUpdateDisabled(false)}}
                            >
                              { bodyOptions.map((body) => (
                                <MenuItem value={body}>{body}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                      <Row style={{paddingBottom: "32px"}}>
                        <Col style={{paddingLeft: "0px"}}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Year</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="year"
                              value={year}
                              label="model"
                              className="mui-select"
                              onChange={e => {
                                setYear(e.target.value); setUpdateDisabled(false)
                              }}
                            >
                              { yearOptions.map((yr) => (
                                <MenuItem value={yr}>{yr}</MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>Required</FormHelperText>
                          </FormControl>
                        </Col>
                        <Col style={{paddingRight: "0px"}}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select">Transmission</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="transmission"
                              value={transmission}
                              label="model"
                              className="mui-select"
                              onChange={e => {
                                setTransmission(e.target.value); setUpdateDisabled(false)}}
                            >
                              { transmissionOptions.map((transm) => (
                                <MenuItem value={transm}>{transm}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                      <Row style={{paddingBottom: "32px"}}>
                        <Col style={{paddingLeft: "0px"}}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select">Fuel</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="transmission"
                              value={fuel}
                              label="model"
                              className="mui-select"
                              onChange={e =>
                              {setFuel(e.target.value); setUpdateDisabled(false)}}
                            >
                              { fuelOptions.map((fl) => (
                                <MenuItem value={fl}>{fl}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col style={{paddingRight: "0px"}}>
                          <TextField
                            error={error["mileage"]}
                            fullWidth
                            id="mileage"
                            label="Enter mileage"
                            value={mileage}
                            onChange={e =>
                            {setMileage(e.target.value); setUpdateDisabled(false); setError({
                              ...error,
                              mileage: false,
                            })}}
                            variant="outlined"
                            style={{margin: "0px", backgroundColor: "white"}}
                            helperText="Field required"
                          />
                        </Col>
                      </Row>
                      <Row style={{paddingBottom: "32px"}}>
                        <Col style={{paddingLeft: "0px"}}>
                          <TextField
                            error={error["engine"]}
                            fullWidth
                            id="engine"
                            label="Engine size"
                            value={engineSize}
                            onChange={e =>
                            {setEngineSize(e.target.value); setUpdateDisabled(false); setError({
                              ...error,
                              engine: false,
                            })}}
                            variant="outlined"
                            style={{margin: "8px 0px", backgroundColor: "white"}}
                            helpText="Field required"
                          />
                        </Col>
                        <Col style={{paddingRight: "0px"}}>
                          <TextField
                            fullWidth
                            id="colour"
                            label="Enter colour"
                            value={colour}
                            onChange={e =>
                            {setColour(e.target.value); setUpdateDisabled(false)}}
                            variant="outlined"
                            style={{margin: "8px 0px", backgroundColor: "white"}}
                          />
                        </Col>
                      </Row>
                      <TextField
                        error={error["description"]}
                        fullWidth
                        id="description"
                        label="Enter vehicle description"
                        value={description}
                        onChange={handleFormChange}
                        multiline
                        variant="outlined"
                        rows={3}
                        maxRows={10}
                        style={{margin: "8px 0px 32px 0px", backgroundColor: "white"}}
                        helpText="Field required"
                      />
                      <hr style={{borderTop: "1px solid #00000040"}}/>
                      <div>
                        <h6 className="page-header-content">Photo upload</h6>
                        <div className="row">
                          {Object.keys(gallery).map((keyName, i) => (
                            <div className="col-lg-3 col-md-3 col-6 mt-4">
                              <div>
                                <img className="my-img-thumbnail"
                                     alt="Product img"
                                     height={"150px"}
                                     src={gallery[i]} />
                              </div>
                              <FileUploadComponent
                                sendRequest={props.uploadImage}
                                uploadSuccess={props.uploadImageSuccess}
                                uploadImageError={props.uploadImageError}
                                uploadImageLoader={props.uploadImageLoader}
                                updateCoverImg={setCoverImg}
                                courseId={courseId}
                                displayOrder={i}
                                saveDisabled={updateDisabled}
                              />
                            </div>
                          ))}
                        </div>

                      </div>
                    </div>
                    <hr style={{borderTop: "1px solid #00000040"}}/>
                    <Box
                      sx={{
                        display: 'block',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                          // minHeight: 300,
                        },
                      }}
                    >
                      <div style={{padding: '8px 16px'}}>
                        <h5 className="page-header-content">Preview</h5>
                      </div>
                      <VehicleDetailComponent
                        images={images}
                        price={price}
                        prodTitle={prodTitle}
                        subtitle={subtitle}
                        model={bodyType}
                        year={year}
                        engineSize={engineSize}
                        fuel={fuel}
                        mileage={mileage}
                        colour={colour}
                        transmission={transmission}
                        description={description}
                        contact={contact}
                        location={location}
                      />

                    </Box>
                  </Col>
                </Row>
              </div>
          </Box>
        </div>
      </div>
      <Box sx={{ width: 500 }}>
      </Box>
    </div>

  )
}
