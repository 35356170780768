import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const data = {
  'Car Parts & Accessories': {
    '_id': '2',
    'Air and Fuel Delivery': {
      '_id': '3',
      'Fuel Injection Parts': {
        '_id': '4',
        'Additional Fuel Injection Parts': {'_id': '1047'},
        'High Pressure Fuel Pumps': {'_id': '1046'}
      }
    },
    'Advanced Driver Assistance Systems': {
      '_id': '7',
      'Parking Assistance': {
        '_id': '9',
        'Parking Cameras': {'_id': '1032'}
      }
    }
  }
};

const buildTreeItems = (categories) => {
  if (typeof categories !== 'object' || categories === null) {
    return null;
  }

  return Object.entries(categories).map(([key, value]) => {
    if (typeof value === 'object' && '_id' in value) {
      const children = { ...value };
      delete children['_id'];

      const childItems = buildTreeItems(children); // Recursive call

      return (
        <TreeItem
          key={value._id}
          nodeId={value._id}
          label={<div className="treeItemLabel">{key}</div>}
        >
          {childItems && childItems.length > 0 && (
            <React.Fragment>{childItems}</React.Fragment>
          )}
        </TreeItem>
      );
    }

    return null;
  });
};


const TreeMenuComponent = ({categories, onTreeSelect}) => {
  const topLevelId = categories._id;

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreRoundedIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      // onNodeSelect={(event, value) => console.log('Selected:', value)}
      onNodeSelect={(event, value) => onTreeSelect(event, value)}
      defaultExpanded={[topLevelId]}
    >
      {buildTreeItems(categories)}
    </TreeView>
  );
};

export default TreeMenuComponent;
