import React from "react";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {withRouter} from "react-router-dom";
import genuineParts from "../assets/images/auto-banner1.png";


const PartsHomeComponent = (props) => {

  return<div>
    <Card
      raised={false}
      elavation={0}
      style={{ maxWidth: "100%", borderRadius: "8px" }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={genuineParts}
          style={{objectFit: "cover", borderRadius: "8px"}}
          alt="Genuine parts"
        />
      </CardActionArea>
    </Card>
    <div>
      <br/>
      <Typography className="page-sub-header" gutterBottom variant="h6" component="div">
        Parts
      </Typography>
      <Typography variant="body2" color="text.secondary">
        The Parts Department at Magari World maintains a comprehensive inventory of high quality
        genuine OEM parts. Our highly knowledgeable staff is here to answer your parts inquiries.
        Should we not carry a part for which you’re searching, we can always order it for you and receive it
        within a timely manner.
      </Typography>
      <br/>
      <p/>
      <Typography className="page-sub-header" gutterBottom variant="h6" component="div">
        What happens after I order a part?
      </Typography>
      <Typography variant="body2" color="text.secondary">
        If you know the name or inventory number of your part, please use our online store.
        Otherwise, feel free to call us, our Parts Department expert will determine exactly what you need.
        Upon submitting your information we will:
      </Typography>
      <br/>
      <Typography variant="body2" color="text.secondary">
        - Respond to you promptly via your preferred contact method.
        <br/>
        - Ask any additional questions required to identify your parts request.
        <br/>
        - Confirm your order and pricing.
      </Typography>
    </div>
  </div>



}

export default withRouter(PartsHomeComponent);