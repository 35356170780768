import React from "react";
import {Card, CardBody, CardImg, CardSubtitle, CardText} from "reactstrap";
import NumberFormat from "react-number-format";
import {withRouter} from "react-router-dom";


const applyDiscount = price => (price + (0.10 * price)).toFixed(2);

const routeToDetails = (props) => {
  const { history } = props;
  const productId = props.d.id;
  if (props.d.product_class_id === 2) {
    history.push(`/details/${productId}/parts`);
  } else {
    history.push(`/details/${productId}`);
  }
};

const noImage = "https://image.shutterstock.com/image-vector/no-image-available-sign-260nw-1135028591.jpg"

const SearchResGrid = (props) => {

  return<div onClick={() => routeToDetails(props)}
       className="col-lg-3 col-md-3 col-6 mt-4"
       style={{marginBottom: '1.5rem',  cursor: 'pointer', minWidth: '160px'}} key={props.i}>
    <Card className="search-results-card">
      <CardImg
        top
        width="100%"
        className="mx-auto car-img img-responsive card-img-top-promo"
        src={ props.d.primary_image ? props.d.primary_image : noImage }
        alt="image"
      />
      <CardBody style={{ textAlign: 'left', padding: '16px 0px 8px 8px'}}>
        <div className="car-body-wrapper cards-body">
          <CardSubtitle className="search-results" style={{ paddingBottom: '10px'}}>
            {/*eslint-disable-next-line*/}
            <div className="link-text">
              {props.d.title}
            </div>{' '}
          </CardSubtitle>
          <CardText>
            <section

              style={{float: 'left'}}
            >
              <del style={{display: "None"}}>
                <NumberFormat
                className="home-price"
                value={applyDiscount(props.d.price)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'KES'}
                />
              </del>
              { '  ' }
              { '  ' }
              <NumberFormat
                className="sale-price"
                value={props.d.price && (props.d.price).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'KES'}
              />
              <div className="subtitle">
                {props.d.subtitle_clean}
              </div>
            </section>

          </CardText>
        </div>

      </CardBody>
    </Card>
  </div>;

}

export default withRouter(SearchResGrid);