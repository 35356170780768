import React, { useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { } from '@mui/material/Select';
import Button from '@mui/material/Button';
import {Helmet} from "react-helmet";
import {Loading} from "../LoadingComponent";
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';

import {
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import {Link} from "react-router-dom";
import FileUploadComponent from "./FileUploadComponent";


export default function CreateSparesListingComponent(props) {

  const msgRef = React.useRef()

  const [productTypesList, setProductTypesList] = useState([])
  const [productLevelList, setProductLevelList] = useState([])
  const [productSubjectList, setProductSubjectList] = useState([])
  const [level3Arr, setLevel3Arr] = useState({})
  const [level4Arr, setLevel4Arr] = useState({})
  const [level5Arr, setLevel5Arr] = useState({})
  const [isBranchEnd, setIsBranchEnd] = useState(false)
  const [product, setProduct] = React.useState('');
  const [level, setLevel] = useState('');
  const [level3, setLevel3] = useState('');
  const [level4, setLevel4] = useState('')
  const [level5, setLevel5] = useState('')
  const [level2, setLevel2] = useState('');
  const [subjectId, setSubjectId] = useState('');
  const [prodName, setProdName] = useState('');
  const [selectedPart, setSelectedPart] = useState('');

  const [error, setError] = useState(false);
  const [lastLevel, setLastLevel] = useState(false);
  // const [message, setMessage] = useState('');


  const [message, setMessage] = React.useState('')

  useEffect(() => {
    props.getProductType("spares")
  }, []);

  useEffect(() => {
    if (props.getProductTypeSuccess && props.getProductTypeSuccess) {
      let prodTypes = Object.keys(props.getProductTypeSuccess.data)
      setProductTypesList(prodTypes)
      setProduct(prodTypes[0])
      let prodLevels = Object.keys(props.getProductTypeSuccess.data[prodTypes[0]])
      setProductLevelList(prodLevels)
      setLevel("Car Parts & Accessories")
      let prodSubjects = props.getProductTypeSuccess.data[prodTypes[0]]["Car Parts & Accessories"]
      setProductSubjectList(prodSubjects)
      if (Object.keys(props.getProductTypeSuccess.data[prodTypes[0]]["Car Parts & Accessories"]).length === 0){
        setIsBranchEnd(true)
      } else {
        setIsBranchEnd(false)
      }
    }
    if (props.createProductSuccess && props.createProductSuccess) {
      let pId = props.createProductSuccess.data.product_id
      props.history.push(`/vendor/list-auto-spares/${pId}`)
    }
    if (props.createProductError && props.createProductError) {
      if (props.createProductError.endsWith('401')) {
        setMessage("Ooops, it look like you're not logged. Sign in and try again")
      }
      if (props.createProductError.endsWith('403')) {
        setMessage("Ooops, your seller account has not been approved yet. Contact customer service")
        window.scrollTo({top: 0, behavior: 'smooth'});
        // document.body.scrollTop = 0
        // window.scrollTo(0,0);
      }
    }
  }, [props])

  const handleCreateCourse = () => {
    if (product === '' || level === '' || level2 === '' || prodName === ''){
      setMessage("Enter a listing category and title")
    } else {
      let newPayload = {subId: selectedPart, name: prodName, prodClass: 'parts', region: 'ke'}
      props.createProduct(newPayload)
    }
  }

  const handleChange = (event) => {
    setProduct(event.target.value);
    setLevel2('')
    setLevel('')
    setProdName('')
    let prodLevels = Object.keys(props.getProductTypeSuccess.data[event.target.value])
    setProductLevelList(prodLevels)
  };

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
    let prodSubjects = props.getProductTypeSuccess.data[product][event.target.value]
    setProductSubjectList(prodSubjects)
    if (Object.keys(props.getProductTypeSuccess.data[product][event.target.value]).length === 0){
      setIsBranchEnd(true)
    } else {
      setIsBranchEnd(false)
    }
  }

  const handleLevel2Change = (event, child) => {
    setLevel2(event.target.value);
    let level3Ar = props.getProductTypeSuccess.data[product][level][event.target.value]

    setSubjectId(child.props.id)
    setMessage('')
    setLevel3('')
    setLevel4('')
    setLevel5('')
    if('cat_id' in level3Ar){
      setLastLevel(true)
      setSelectedPart(level3Ar['cat_id'])
    } else {
      setLevel3('continue')
      setLevel3Arr(level3Ar)
      setLastLevel(false)
    }
  }

  const handleLevel3Change = (event, child) => {
    // setLevel4(event.target.value);
    let level4Ar = props.getProductTypeSuccess.data[product][level][level2][event.target.value]
    setLevel3(event.target.value)
    setLevel4('')
    setLevel5('')
    if('cat_id' in level4Ar){
      setLastLevel(true)
      setSelectedPart(level4Ar['cat_id'])
    } else {
      setLevel4('continue')
      setLastLevel(false)
      setLevel4Arr(level4Ar)
    }
  }

  const handleLevel4Change = (event, child) => {

    let level5Ar = props.getProductTypeSuccess.data[product][level][level2][level3][event.target.value]
    setLevel4(event.target.value);
    setLevel5('')

    if('cat_id' in level5Ar){
      setLastLevel(true)
      setSelectedPart(level5Ar['cat_id'])
    } else {
      setLevel5('continue')
      setLastLevel(false)
      setLevel5Arr(level5Ar)
    }
  }

  const handleProdNameChange = (event) => {
    setMessage('')
    setProdName(event.target.value);
  };


  return (
    <div>
      <Helmet>
        <title>Create Vehicle Listing</title>
        <meta name="description" content="Magari World" />
      </Helmet>
      <div className="container cpanel">
        <Breadcrumb>
          <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
          <BreadcrumbItem><Link to="/vendor-dashboard" >Dashboard</Link></BreadcrumbItem>
          <BreadcrumbItem active>Create auto spares listing</BreadcrumbItem>
        </Breadcrumb>
        <div className="row" style={{paddingTop: '20px', paddingBottom: '8px'}}>
          <div className="page-header col-12">
            <h4 className="page-header-content">List Auto Spares for sale</h4>
          </div>
        </div>
        <div id="contactContainer">
          <div className="row">
            {props.getProductTypeLoader ? (
              <div className="offset-5 offset-lg-6" style={{paddingTop: "64px", paddingBottom: "800px"}}>
                <Loading />
              </div>
            ) : (
              <div className="container" style={{padding: "0px 16px", textAlign: "center"}}>
                {message ? (
                  <div ref={msgRef}>
                    <Alert severity="error">{message}</Alert>
                  </div>
                ) : (
                  null
                )
                }
                <Box sx={{ maxWidth: "600px", margin: "0 auto", marginBottom: "64px"}}>
                  <div style={{display: 'None'}}>
                    <h6 className="headings-create-product">Select spares type</h6>
                    <FormControl fullWidth style={{paddingBottom: "16px"}}>
                      <InputLabel id="demo-simple-select-label">Type</InputLabel>
                      <Select
                        disabled={true}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={product}
                        label="Course Type"
                        className="mui-select"
                        onChange={handleChange}
                      >
                        { productTypesList.map((productType) => (
                          <MenuItem value={productType}>{productType}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  { product ? (
                    <div style={{display: 'None'}}>
                      <h6 className="headings-create-product">Select parent category</h6>
                      <FormControl fullWidth style={{paddingBottom: "16px"}}>
                        <InputLabel id="demo-simple-level-label">Parent category</InputLabel>
                        <Select
                          disabled={true}
                          labelId="demo-simple-select-level"
                          id="demo-simple-select"
                          value={level}
                          label="Course Type"
                          onChange={handleLevelChange}
                        >
                          { productLevelList.map((productLevel) => (
                            <MenuItem value={productLevel}>{productLevel}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ): (null)
                  }
                  { level && !isBranchEnd ? (
                    <div>
                      <h6 className="headings-create-product">Select category</h6>
                      <FormControl fullWidth style={{paddingBottom: "16px"}}>
                        <InputLabel id="demo-simple-level-label">Select</InputLabel>
                        <Select
                          labelId="demo-simple-select-level"
                          id="demo-simple-select"
                          value={level2}
                          label="Subject"
                          onChange={handleLevel2Change}
                        >
                          {Object.keys(productSubjectList).map((keyName, i) => (
                            <MenuItem id={i}
                              value={keyName}
                            >
                              {keyName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    </div>
                  ): (null)
                  }
                  { level3 ? (
                    <div>
                      <h6 className="headings-create-product">Select sub category</h6>
                      <FormControl fullWidth style={{paddingBottom: "16px"}}>
                        <InputLabel id="demo-simple-level-label">Sub category</InputLabel>
                        <Select
                          labelId="demo-simple-select-level"
                          id="demo-simple-select"
                          value={level3}
                          label="Subject"
                          onChange={handleLevel3Change}
                        >
                          {Object.keys(level3Arr).map((keyName, i) => (
                            <MenuItem id={i}
                                      value={keyName}
                            >
                              {keyName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    </div>
                  ): (null)}
                  { (level4 ) ? (
                    <div>
                      <h6 className="headings-create-product">Select Level 4</h6>
                      <FormControl fullWidth style={{paddingBottom: "16px"}}>
                        <InputLabel id="demo-simple-level-label">Level 4</InputLabel>
                        <Select
                          labelId="demo-simple-select-level"
                          id="demo-simple-select"
                          value={level4}
                          label="Subject"
                          onChange={handleLevel4Change}
                        >
                          {Object.keys(level4Arr).map((keyName, i) => (
                            <MenuItem id={i}
                                      value={keyName}
                            >
                              {keyName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    </div>
                  ): (null)}
                  { (level5) ? (
                    <div>
                      <h6 className="headings-create-product">Select Level 5</h6>
                      <FormControl fullWidth style={{paddingBottom: "16px"}}>
                        <InputLabel id="demo-simple-level-label">Level 5</InputLabel>
                        <Select
                          labelId="demo-simple-select-level"
                          id="demo-simple-select"
                          value={level5}
                          label="Subject"
                          onChange={handleLevel3Change}
                        >
                          {Object.keys(level5Arr).map((keyName, i) => (
                            <MenuItem id={i}
                                      value={keyName}
                            >
                              {keyName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    </div>
                  ): (null)}
                  { lastLevel || isBranchEnd ? (
                    <div>
                      <h6 className="headings-create-product">Enter full title</h6>
                      <p style={{fontSize: '0.75rem'}}>Use this format: Title → Year → Compatible Make & Model → Title → Part number</p>
                      <TextField
                        id="outlined-basic"
                        label="e.g ECU Control Module for 2017 Toyota Land Cruiser 2.8 D4D 89990-60205"
                        fullWidth
                        value={prodName}
                        onChange={handleProdNameChange}
                        variant="outlined" />
                      <div style={{padding: "32px 24px"}}>
                        <Button variant="contained"  className="mui-buttons" onClick={() => handleCreateCourse()}>
                          Create listing
                        </Button>
                      </div>
                    </div>
                  ): (null)}
                </Box>
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  )
}