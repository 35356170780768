import React from 'react'
import { Alert } from "reactstrap";



const ConfirmResendActivation = ({responseCode}) => {

  const renderResponse = (resCode) => {
    if (resCode === 2) {
    return (
      <div>
        <Alert color="success">
          Active Account
        </Alert>
        <div className="col-12 page-information exp">
          You already have an active account. <p> Click <a href="/login" className="a-links"> here to log in or reset your password</a></p>
        </div>
      </div>
    );}
    else if (resCode === 3) {
      return (
        <div>
        <Alert color="danger">
          Account not registered
        </Alert>
        <div className="col-12 page-information exp">
          This email is not registered. <p> Click <a href="/login" className="a-links"> here to register</a> </p>
        </div>
      </div>
      )
    } else if (resCode === 4){
      return (
      <div>
        <Alert color="success">
          Activation link re-sent
        </Alert>
        <i className="fa fa-check-circle" style={{fontSize:"40px", color:"#009800"}}></i>
        <div className="col-12 page-information exp">
          An activation link has been resent to your email address.
          <p> Please click on the activation link within 48 hours </p>
          <p style={{fontWeight: 600}}> Tip: Check your spam folder in case the email was incorrectly identified </p>
          <p> Click<a href="/login" className="a-links"> here to log in</a></p>
        </div>
      </div>
      )

    } else if(resCode === 5){
      return (
        <div>
        <Alert color="danger">
          Error
        </Alert>
        <div className="col-12 page-information exp">
          We're sorry, but something went wrong. Contact customer service support
        </div>
      </div>
      )
    }
  }

  return (
    <div>
      <header className="headerbg d-flex">
        <div className="container my-auto">
          <div className="row">
            <div className="offset-1 col-10 offset-lg-4 col-lg-4">
              <div id="search-form-div" className="container">
                <div className="row">
                  <div className="col-12 my-4">
                    <div className="fat-div" style={{textAlign: 'center'}}>
                    {renderResponse(responseCode)}
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </header>

      </div>
  )
}

export default ConfirmResendActivation
