import ActivateConfirmComponent from './../components/ActivateConfirmComponent';
import { connect } from "react-redux";
import { authAction } from "./../store/actions";

const mapStateToProps = state => {
    const {
        authReducer: {
            activateAccConfirmSuccess,
            activateAccConfirmError,
            activateAccConfirmLoader
        }
    } = state;
    return {
        activateAccConfirmSuccess,
        activateAccConfirmError,
        activateAccConfirmLoader
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateAccountConfirm: payload =>
          dispatch(authAction.activateAccountConfirm(payload))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivateConfirmComponent);