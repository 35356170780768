import ImgAndForm from './../components/ImgAndForm';
import { connect } from "react-redux";
import { vehicleAction } from "./../store/actions";

const mapStateToProps = state => {
  const {
    vehicleReducer: {
      getProductCategoriesLoader,
      getProductCategoriesSuccess,
      getProductCategoriesError
    }
  } = state;
  return {
    getProductCategoriesLoader,
    getProductCategoriesSuccess,
    getProductCategoriesError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProductCategories: payload =>
      dispatch(vehicleAction.getProductCategories(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImgAndForm);