import React, {Component} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import EarnedPositionComponent from "./../../../shared/EarnedPositionComponent"
import pdf from "./../../../shared/Earned-Positions-Ex-UK-Online-v2.pdf";



class IntroComponent extends Component {

  render() {
    return (
      <div style={{paddingBottom: '20px'}}>
        <div className="supporter-program-text">
          <div style={{ paddingBottom: '40px' }}>
            <div className="training-titles">
              Introduction to training
            </div>
            <div style={{ fontStyle: "italic" }} className="training-headings">
              The aim of this training is to provide you with all the necessary information you'll need to thrive
              as an Chicargos Supporter. <br/>
              Take your time to read all the chapters and remember to click the button at the
              end of each chapter to confirm completion. <br/><br/>
              If you have any questions or need clarification feel free to
              contact the support team - support@seetutors.co.uk
            </div>
          </div>
          <div style={{ paddingBottom: '40px' }}>
            <div className="training-titles">
              How to buy on CHICARGOS?
            </div>
            <div className="training-headings">
              <a href="/how-to-buy" className="link-text">Click here for instructions</a>
            </div>
          </div>

          <div style={{ paddingBottom: '40px' }}>
            <div className="training-titles">
              What is the supporter programme?
            </div>
            <div className="training-headings">
              The Supporter Programme is a referral program where CHICARGOS pays commissions to supporters who send
              traffic to the site, that results in a sale.
            </div>
            <br/>

            <div>
              The programme serves as the intermediary between traffic partners (supporters)
              and the site.
              In order to make sure our supporters thrive, we provide you with
              the tools, reports, and resources you need to succeed.
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default IntroComponent;
