import React, {Component} from 'react';
import ReferralPageBlueprint from './../Blueprints/ReferralPageBlueprint';
import CoverImage from './../../assets/single-elements/cover-image-v3.png'
import NavElements from "./NavElements";
import {Helmet} from "react-helmet";
import pdf from "../../shared/Earned-Positions-Chicargos.pdf";
import EarnedPositionComponent from "../../shared/EarnedPositionComponent";



class CompensationPartnerProgramme extends Component {
  constructor(props) {
    super(props);
    this.supporterGuideRef = React.createRef()
    this.state = {
        isPaneOpen: false,
        isPaneOpenLeft: false,
    };

    this.onOpenSidePane = this.onOpenSidePane.bind(this)

  }

  componentDidMount() {
    if (this.supporterGuideRef && this.supporterGuideRef.current) {
      this.supporterGuideRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  onOpenSidePane = (e, ele) => {
    const { history } = this.props;
    if (ele === 'About') {
      history.push('/supporter-programme/home')
    }
    if (ele === 'How') {
      history.push('/supporter-programme/how')
    }
    if (ele === 'Compensation') {
      history.push('/supporter-programme/compensation')
    }
    if (ele === 'Tools') {
      history.push('/supporter-programme/tools')
    }
  }

  render() {
    const title = "CHICARGOS Supporters Referral Programme"
    const bImage = CoverImage
    const subHeading = "Referral Programme Rewards"
    const pubDate = "09 Jan - 3 min read"
    return (
      <div>
        <Helmet>
          <title>{ "CHICARGOS Supporter - Affiliate Programme - Earn" }</title>
          <meta name="description" content="Earn money for every sale you make, Get paid when your friends join
          CHICARGOS Supporter Programme" />
        </Helmet>
        <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                <div className="" style={{ textAlign: 'center' }}>
                  <ReferralPageBlueprint
                    title={title}
                    bImage={bImage}
                    subHeading={subHeading}
                    publishingDate={pubDate}
                  >
                  </ReferralPageBlueprint>
                  <div ref={this.supporterGuideRef} className="container">
                    <NavElements
                      currentPage={"Compensation"} onOpenSidePane={this.onOpenSidePane}
                    />
                    <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
                    </div>
                  </div>
                  <div className="supporter-program-text">
                    <div className="supporter-program-text-header">
                      REWARDS
                    </div>
                    <div>
                      The CHICARGOS Opportunity has been designed to help you build a business that can produce
                      immediate and long-term income. <p> As a new Independent Business Owner (IBO),
                      you should set yourself a goal to learn the Compensation Plan in detail.
                      The better you understand it, the better you will make it work for you.</p>
                    </div>
                    <div className="supporter-program-text-header">
                      POSITIONS & QUALIFICATIONS
                    </div>
                    <div>
                      Everyone starts the supporters programme as an Independent Business Owner.
                      <p> Your goal should be to work your way through the earned positions detailed below. The positions
                      you achieve are based on the total value of sales acquired between you and your team.
                      </p>
                      <p style={{fontSize: '16px', paddingTop: '0px'}}>
                        <a className="a-links" href={pdf}>Click here to learn more about earned positions</a>
                      </p>
                    </div>
                    <div className="supporter-program-text-header">
                      COMMISSION FEE TABLE FOR SPECIFIC POSITIONS
                    </div>
                    <div style={{paddingBottom: '20px'}}>
                      Commission rate, based on percent of sale price, for items sold on CHICARGOS
                    </div>
                    <EarnedPositionComponent/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }

}

export default CompensationPartnerProgramme;
