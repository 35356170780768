import React from 'react';
import { Card, CardBody, Jumbotron } from 'reactstrap';
import {Link} from 'react-router-dom';

const CarBuyAck  = () => {
        
        return (
            <div>
                <div className="">
                    <h5 className="text-center">Order Confirm</h5>
                    <hr/>
                    <Card className="bg-light">
                            <CardBody style={{color:"#6fa8dd"}} className="text-center">
                            Thank you! Your order has been received
                            </CardBody>
                        </Card>
                </div>
                <br/>
               <div className="row text-center">
                  <Jumbotron style={{width:"100%"}}>
                        <i className="far fa-envelope" style={{fontSize:"85px"}}></i>
                  

                   <p style={{marginTop:"40px",color:"#6fa8dd"}}>
                    You will receive an email shortly with the payment instructions. <br/>
                    If you have more questions, please reply to the email so we can <br/>
                    assist you
                   </p>
                   </Jumbotron>
               </div>

                <div className="row mbtn">
                    <div className="col-12 col-md-4">
                        <Link to="/profile" className="btn btn-primary btn-block" >GO TO YOUR ACCOUNT</Link>
                    </div>
                    <div className="col-12 offset-md-4 col-md-4">
                        <Link to="/" className="btn btn-primary btn-block" >VIEW MORE VEHICLES</Link>
                    </div>
                </div>

             
            </div>


        );
    }


export default CarBuyAck;