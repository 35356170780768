import React, {Component} from 'react';
import {withCookies} from "react-cookie";
import {Alert, Button, FormGroup, Input, Row} from "reactstrap";
import NumberFormat from "react-number-format";
import search from "./../helpers/SearchMethod"
import {Loading} from "../LoadingComponent";


class UserBasket extends Component {

  constructor(props) {
    super(props);

    this.removeItemFromBasket = this.removeItemFromBasket.bind(this);
    this.proceedToCheckout = this.proceedToCheckout.bind(this);
    this.handleShippingQuoteReq = this.handleShippingQuoteReq.bind(this);
    this.applyDiscountCode = this.applyDiscountCode.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.updateState = this.updateState.bind(this)

    this.state = {
      count: 0,
      results: [],
      shippingChargeOption: {},
      status: "",
      total: "",
      image: "",
      destinationPort: "",
      destinationCode: "",
      discountCode: "",
      error: false,
      message: "",
      isButtonDisabled: false,
      userSelectedDestination: "",
      basketID: ""
    }
  }

  async componentDidMount() {
    const { history, location, isLoggedIn } = this.props
    const token = localStorage.getItem('token')
    if (!isLoggedIn) {
      history.push("/login", location)
    }

    let headers = { Authorization: `token ${token}` };

    this.props.getUserBasket && this.props.getUserBasket(headers)
  }


  componentDidUpdate(prevProps, prevState) {

    if (this.props.getUserBasketSuccess && prevProps.getUserBasketSuccess !== this.props.getUserBasketSuccess) {
      this.setState({ ...this.props.getUserBasketSuccess})
    }
    if (this.props.getShippingQuoteSuccess && prevProps.getShippingQuoteSuccess !== this.props.getShippingQuoteSuccess) {
      window.location.reload();
    }
    if (this.props.getUserBasketError === "AjaxError: ajax error 401") {
      const { history, location } = this.props;
      history.push("/login", location);
    }
    if (this.props.applyDiscountCodeError && prevProps.applyDiscountCodeError !== this.props.applyDiscountCodeError) {
      this.setState({error: true, message: "Discount code you entered is invalid or not applicable to the contents of your basket"})
    }
    if (this.props.applyDiscountCodeSuccess && prevProps.applyDiscountCodeSuccess !== this.props.applyDiscountCodeSuccess) {
      const token = localStorage.getItem('token')
      let headers = { Authorization: `token ${token}` };
      this.setState({error: false, message: "Discount code applied."})
      this.props.getUserBasket && this.props.getUserBasket(headers)
    }
  }

  handleChange(event) {
      this.setState({value: event.target.value});
      const target = event.target;
      const name = target.name;
      this.setState({
          [name]: target.value
      })
  }

  updateState(destinationName, destinationCode, calculatedShippingOption, totalCost, userSelectedDestination){
    this.setState({
    destinationPort: destinationName,
    destinationCode: destinationCode,
    shippingChargeOption: calculatedShippingOption,
    totalCost: totalCost,
    userSelectedDestination: userSelectedDestination
    })
  }


  renderBasketData(banana){
    return banana.map(
      ({ productTitle, price, quantity, url, productImage, shippingCost, totalCost, destinationPort, lineAttribute,
         hasDiscount, discountValue}, i) => {
        return (
          <div>
          <div className="row">
            <div className="col-3 col-3-custom">
              <span className="helper"></span><img src={productImage} alt="boohoo" className="img-basket"/>
            </div>
            <div className="col-6 col-3-custom basket-page-information ">
              <p className="basket-item-title">{productTitle}</p>
              Qty: {quantity}
              <br>
              </br>
              {/*eslint-disable-next-line*/}
              <a className="a-links" href="#" onClick={() => this.removeItemFromBasket(url)}>
                - Remove
              </a>
            </div>
            <div className="col-3 col-3-custom basket-page-information">
              <NumberFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={'.00'}/>
            </div>
          </div>
          {/*<hr width="80%"/>*/}
          {hasDiscount ?
          <div>
            <div className="row">
              <div className="col-3 col-3-custom">
              </div>
              <div className="col-6 col-3-custom basket-page-information">
                <p className="basket-item-title" style={{color: '#980000'}}>Discounts & Savings </p>
              </div>
              <div className="col-3 col-3-custom basket-page-information" style={{color: '#980000'}}>
                - <NumberFormat className="col-3-custom basket-page-information"
                                style={{color: '#980000'}}
                                value={discountValue}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                />
              </div>
            </div>
          </div>
          :
            null
          }
          <hr className="thicker-hr" width="80%"/>
          <div className="bottom-out-space">

          </div>
          </div>
        );
      }
    )
  }

  // TODO Align this implementation
  removeItemFromBasket(url){
    const token = localStorage.getItem('token')
    const fetchPromise = fetch(url, {
     method: 'DELETE',
     headers: {"Content-Type": "application/json",
               "Authorization": `token ${token}`}
    });
    fetchPromise.then(response => {
      return response;
    }).then(people => {
      this.setState({results: [], status: "", count: "0", total: "0"})
    });
  }

  handleShippingQuoteReq(event, url, lineAttribute) {
    let basketData = JSON.stringify(lineAttribute)
    this.sendRequest(
      process.env.REACT_APP_EMAILJS_TEMPLATEID, "notapplicable", "noapplicate@gmail.com",
      process.env.REACT_APP_EMAILJS_RECEIVER, "Shipping quotation request: " + url + " - " + basketData ,
      "070000000")

    alert('A shipping quote request was submitted, Basket will be updated within 20 minutes')
    event.preventDefault()
    const { basketID } = this.state
    let payload = { basketID: basketID}
    this.props.getShippingQuote( payload )
  }

  sendRequest(templateId, userName, userEmail, receiverEmail, userRequest, contactNumber) {
    window.emailjs.send(
      'sendgrid',
      templateId,
        {
          userName,
          userEmail,
          receiverEmail,
          contactNumber,
          userRequest
        })
        .catch(err => console.error('Failed to send feedback. Error: ', err))
  }

  proceedToCheckout (event, shippingCost) {
    if (parseInt(this.state.shippingChargeOption.value) < 1){
      alert('Unable to proceed. Shipping cost has not been determined. Click the link in your basket to request for a quote')
      return
    }
    const {history} = this.props
    history.push({
      pathname: "/checkout",
      state: this.state
    })
  }

  proceedToHome () {
    const {history} = this.props
    history.push({
      pathname: "/",
    })
  }

  applyDiscountCode () {

     const { discountCode, results } = this.state

    if (results[0].has_discount) {
      this.setState({error: true, message: "Discount already applied to your basket"})
      return;
    }

    if (discountCode === "") {
      // this.electronicsRequestRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      this.setState({error: true, message: "Error - Discount Code Required"})
      return;
    }
    if (discountCode.length < 5) {
      this.setState({error: true, message: "Error - Code length must be minimum 5 character"})
    }

    this.props.discountCodeApplication && this.props.discountCodeApplication({vouchercode: discountCode});
  }

  render() {
    const {count , results, message, error, is_shipping_requested } = this.state;

    const { getUserBasketLoader,  applyDiscountCodeLoader } = this.props


    if (results.length !== 0 ) {
      const {getShippingMethodsSuccess} = this.props
      let userSelectedDestination = results[0].attributes.find(function (o) {return o.value === '0'}).option
      userSelectedDestination = "/options/" + userSelectedDestination.match(/([^\/]*)\/*$/)[1] + "/"
      userSelectedDestination = search(userSelectedDestination, getShippingMethodsSuccess, "description")
      let allShippingOptions = (results.map(x => x.attributes)).flat()

      let calulatedShippingOption = getShippingOption(allShippingOptions)
      let selectedShippingOption = calulatedShippingOption.option
      selectedShippingOption = "/options/" + selectedShippingOption.match(/([^\/]*)\/*$/)[1] + "/"
      let destination = search(selectedShippingOption, getShippingMethodsSuccess, "description")
      let destinationName = destination['name']
      let destinationCode = destination['code']
      let totalCost = results.map(x => parseFloat(x.price_incl_tax))
      totalCost.push(parseFloat(calulatedShippingOption.value))
      totalCost = reduceTotalCost(totalCost)
      if (this.state.destinationPort === "") {
        this.updateState(destinationName, destinationCode, calulatedShippingOption, totalCost, userSelectedDestination)
        }
    }

    function reduceTotalCost (arr) {
      let total = arr.reduce((a, b) => a + b, 0)
      return total
    }

    function getShippingOption(d){
      let res = Math.max.apply(Math, d.map(function (o) {return o.value;}))
      let maxShipping = d.find(function (o){return o.value == res})
      return maxShipping
    }

    function getBasketInfo(item) {
      let url = item.url
      let lineAtt = item.attributes[0].url
      if (process.env.REACT_APP_API_ENDPOINT === 'prod') {
        url = item.url.replace('http', 'https')
        lineAtt = item.attributes[0].url.replace('http', 'https')
      }
      let shippingCost = getShippingOption(item.attributes)

      return {
        "productTitle": item.title, "price": item.price_incl_tax_excl_discounts, "quantity": item.quantity, "url": url,
        "productImage": item.primary_image, "shippingCost": shippingCost.value,
        "totalCost": parseFloat(item.price_incl_tax) + parseFloat(shippingCost.value),
        "lineAttribute": lineAtt, "hasDiscount": item.has_discount,
        "discountValue": (parseFloat(item.price_incl_tax_excl_discounts).toFixed(2) - parseFloat(item.price_incl_tax).toFixed(2)).toFixed(2)
        }
    }
    const mappedData = results.map(getBasketInfo)
    const urls = mappedData.map(x => x.url)
    let shippingRequestBtn = "Click here to request a quote"
    let shippingRequestMsg = "Please Note: Shipping cost not determined"

    if (is_shipping_requested) {
      shippingRequestBtn = "Request is Pending"
      shippingRequestMsg = "Shipping cost request submitted"
    }

    let backgoundColor = "yellow"


    const itemInBasket = results.length > 0
    return(
      <div>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                {parseInt(this.state.shippingChargeOption.value) < 1 ?
                  <div className="basket-shipping-notification">
                    <p className="basket-item-title"
                       style={{paddingTop: '2px', marginBottom: '0px'}}>
                      {shippingRequestMsg} {" - "}
                        <button style={{borderRadius: "4px"}}
                                onClick={(event) => this.handleShippingQuoteReq(event, urls, mappedData)}
                        disabled={this.state.is_shipping_requested}>{shippingRequestBtn}</button>

                    </p>
                  </div> : null }
                <div className="static-info-page">
                  <div className="" style={{ textAlign: 'center' }}>

                    <h4 className="page-header-content">My Basket</h4>
                    <hr className="fancy-line" />
                      {getUserBasketLoader | applyDiscountCodeLoader ? (
                        <div className="">
                          <Loading />
                        </div>
                      ) : (
                      <div>

                        <div className="row basket-checkout-row">
                          <div className="col-sm-6 basket-page-information">
                            Basket subtotal ({count} item): USD<NumberFormat className="col-3-custom basket-page-information" value={this.state.totalCost} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                          </div>


                          { itemInBasket ? (
                            <div className="col-sm-6" style={{overflow: 'auto'}}>
                              <Button className="delete-basket-button" size="sm" onClick={(event) => this.proceedToCheckout(event, mappedData[0].shippingCost)}>Proceed to Checkout</Button>
                            </div>
                          ): (
                            <div className="col-sm-6" style={{overflow: 'auto'}}>
                              <Button className="delete-basket-button" size="sm" onClick={() => this.proceedToHome()}>Continue Shopping</Button>
                            </div>
                          )}
                        </div>
                        <hr style={{marginBottom: '0px'}}/>
                        {itemInBasket ? (
                        <div className="container">
                          <div className="row basket-header">
                            <div className="col-9 col-3-custom basket-page-information basket-page-information-header">
                             PRODUCT
                            </div>
                            <div className="col-3 col-3-custom basket-page-information basket-page-information-header">
                              PRICE
                            </div>
                          </div>
                          {this.renderBasketData(mappedData)}
                          <div className="row">
                            <div className="col-3 col-3-custom">
                              <input type="radio" name="radio-group" value="1" defaultChecked />
                            </div>
                            <div className="col-6 col-3-custom basket-page-information">
                              <p className="basket-item-title">{this.state.destinationPort} </p>
                              <p className="basket-item-title" style={{color: 'red'}}>
                                {parseInt(this.state.shippingChargeOption.value) < 1 ?
                                  <div>
                                    <p className="basket-item-title"> {shippingRequestMsg} {" "}
                                    </p>
                                  </div>
                                : <div style={{color: '#aaa'}}> [ Shipping Cost, incl handling fee ] </div> }</p>
                            </div>
                            <div className="col-3">
                              <NumberFormat className="col-3-custom basket-page-information" value={this.state.shippingChargeOption.value} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={'.00'}/>
                            </div>
                            <hr className="thicker-hr" width="80%"/>
                          </div>
                          <div className="row">
                            <div className="col-3 col-3-custom">
                            </div>
                            <div className="col-6 col-3-custom basket-page-information ">
                              <p style={{fontWeight: 800, fontSize: "16px"}} className="basket-item-title">Total</p>
                            </div>
                            <div className="col-3 col-3-custom" >
                              <NumberFormat className="basket-page-information"
                                            style={{fontWeight: 800, fontSize: "16px"}}
                                            value={this.state.totalCost}
                                            displayType={'text'} thousandSeparator={true}
                                            prefix={'$'} s
                                            />
                            </div>
                          </div>
                          <div style={{paddingBottom: '20px'}}>
                            <hr className="thicker-hr" width="80%"/>
                          </div>
                        </div>): (
                          <div className="page-information"> You have no items in your basket. <p>
                            </p>
                          </div>
                        )}
                        { itemInBasket ? (
                        <div>
                          <Row>
                            <div className="col-12">
                              <div ref={this.electronicsRequestRef}>
                                {message &&
                                <Alert color={error ? "danger" : "success"} style={{display: 'inline-block'}}>
                                  {message}
                                </Alert>}
                              </div>
                            </div>
                          </Row>
                          <div className="row basket-checkout-row">
                            <div className="col-sm-6 basket-page-information" style={{paddingLeft: '20px', paddingRight: '20px'}}>
                              <FormGroup style={{display: 'inline-block'}}>
                                <Input
                                  onChange={this.handleChange}
                                  type="text"
                                  name="discountCode"
                                  id="discount-code-element"
                                  placeholder="Enter Discount Code here"
                                  value={this.state.discountCode}
                                  maxLength={255}
                                />
                              </FormGroup>
                            </div>
                            <div className="col-sm-6" style={{overflow: 'auto'}}>
                              <Button className="delete-basket-button" size="sm" onClick={() => this.applyDiscountCode()}>Apply Discount Code</Button>
                            </div>
                          </div>
                        </div>
                        ): (
                        null
                      )}
                      </div>)}
                  </div>
                </div>
              </div>
            </div>
           <div className="row help-row">
             <div className="col-lg-12">
             </div>
           </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withCookies(UserBasket)