import credentials from "./credentials";

export default class path {
  static VEHICLE_MAKE_COUNT = `${credentials.API_URL}/vehicle-make-count`;
  static SEARCH_VEHICLE = `${credentials.PRODUCT_URL}/`;
  static SEARCH_ITEM = `${credentials.API_URL}/partner-one-search`;
  static GET_PRODUCTS = `${credentials.API_URL}/products`;
  static GET_ALL_PRODUCTS = `${credentials.API_URL}/one-search`;
  static GET_COUNTRIES = `${credentials.API_URL}/countries`;
  static GET_SPECIFICATIONS = `${credentials.API_URL}/vehicle-specs`;
  static ADD_PRODUCT_TO_BASKET = `${credentials.API_URL}/basket/add-product/`;
  static PLACE_ORDER = `${credentials.API_URL}/checkout/`
  static REGISTER_USER = `${credentials.API_URL}/register/`;
  static ACCOUNT_CONFIRM = `${credentials.API_URL}/activate/`
  static RESEND_ACTIVATION_LINK = `${credentials.API_URL}/resend-activation-link/`
  // static API_TOKEN_AUTH = `${credentials.API_URL}/rest-auth/login/`;
  static API_TOKEN_AUTH = `${credentials.API_URL}/auth/login/`;
  static SOCIAL_API_AUTH = `${credentials.API_URL}/social/google-oauth2/`
  static RESET_PASSWORD = `${credentials.API_URL}/rest-auth/password/reset/`;
  static RESET_PASSWORD_CONFIRM = `${credentials.API_URL}/rest-auth/password/reset/confirm/`;
  static API_TOKEN_DESTROY = `${credentials.API_URL}/auth/logout/`;
  // static API_TOKEN_DESTROY = `${credentials.API_URL}/logout/`;
  // static API_TOKEN_DELETE = `${credentials.API_URL}/login/`;
  // static GET_BASKET_DETAIL= `${credentials.API_URL}/promotional-homepage/`;
  static GET_DOWNLOAD = `${credentials.API_URL}/pdf-file/`
  static UPGRADE_TO_AGENT = `${credentials.API_URL}/upgrade-to-agent-account/`;
  static GET_USER_PROFILE = `${credentials.API_URL}/profile/`;
  static GET_AGENT_PROFILE = `${credentials.API_URL}/agent-overview/`;
  static UPDATE_TRAINING_PROGRESS = `${credentials.API_URL}/update-training-progress/`;
  static GET_USER_ORDERS = `${credentials.API_URL}/orders/`;
  static GET_USER_BASKET = `${credentials.API_URL}/basket/`;
  static GET_SHIPPING_QUOTE = `${credentials.API_URL}/request-shipping-quote/`;
  static APPLY_DISCOUNT = `${credentials.API_URL}/basket/add-voucher/`;
  static GET_PROMOTIONAL_API_DATA = `${credentials.PROMOTIONAL_API_URL}`;
  static GET_VEHICLE_DETAILS = `${credentials.PRODUCT_DETAILS_URL}`;
  static GET_SHIPPING_METHODS = `${credentials.API_URL}/basket/shipping-methods/`;
  static GET_PRODUCT_CATEGORIES = `${credentials.API_URL}/category-search`;
  static GET_ARTICLES = `${credentials.API_URL}/article-list/`;
  static ADD_CONTENT = `${credentials.API_URL}/vendor/post-content`;
  static GET_CONTENT = `${credentials.API_URL}/vendor/post-content`;
  static GET_COMP = `${credentials.API_URL}/compatibility`
  static FITMENT = `${credentials.API_URL}/fitment/`
  static SET_FITMENT = `${credentials.API_URL}/set-fitment/`
  static GET_PRODUCT_TYPES = `${credentials.API_URL}/vendor/product-types`;
  static CREATE_PRODUCT = `${credentials.API_URL}/vendor/create-product`;
  static CREATE_LECTURE = `${credentials.API_URL}/vendor/create-lecture`;
  static UPLOAD_IMAGE = `${credentials.API_URL}/vendor/upload-image`;
  static DELETE_CONTENT = `${credentials.API_URL}/vendor/delete-content`;
  static GET_VENDOR_PRODUCTS = `${credentials.API_URL}/vendor/get-products`;
}
