import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';


class ItemImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      modal: false,
      currentImage: ''
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  toggle(img) {
    this.setState({
      modal: !this.state.modal,
      currentImage: img

    })
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.props.images.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.props.images.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const {images} = this.props;

    const slides = images.map((image) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={image.remote_image}
        >
          <div onClick={() => this.toggle(image.remote_image)}>
            <img className="d-block curr-img hover-zoom" src={image.remote_image} alt="Image" />
            <Modal
              size="lg"
              isOpen={this.state.modal}
              toggle={() => this.toggle(image.remote_image)}
              className="image-container"
            >
              <ModalHeader toggle={() => this.toggle(image.remote_image)}>{" "}</ModalHeader>
              <ModalBody style={{padding: "0px"}}>
                <img src={this.state.currentImage}/>
              </ModalBody>
              <ModalFooter>
                <Button className="filter-button" onClick={() => this.toggle(image.remote_image)}>Close</Button>{' '}
              </ModalFooter>
            </Modal>
          </div>
          {/*<CarouselCaption captionText={item.caption} captionHeader={item.caption} />*/}
        </CarouselItem>
      );
    });

    return (
      <div>
        { images.length ?
          <div>
            <Carousel
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
              interval={null}
            >
              <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
              {slides}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </Carousel>

          </div>


          :
          <div>Loading</div>
        }
      </div>

    );
  }
}


export default ItemImageCarousel;