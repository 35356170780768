import {
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE,
    API_TOKEN_AUTH,
    API_TOKEN_AUTH_SUCCESS,
    API_TOKEN_AUTH_FAILURE,
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,
    GET_USER_BASKET,
    GET_USER_BASKET_SUCCESS,
    GET_USER_BASKET_FAILURE,
    GET_SHIPPING_QUOTE,
    GET_SHIPPING_QUOTE_SUCCESS,
    GET_USER_ORDERS,
    GET_USER_ORDERS_SUCCESS,
    GET_USER_ORDERS_FAILURE,
    PASSWORD_RESET,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILURE,
    PASSWORD_RESET_CONFIRM,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAILURE,
    ACTIVATE_ACCOUNT_CONFIRM,
    ACTIVATE_ACCOUNT_CONFIRM_SUCCESS,
    ACTIVATE_ACCOUNT_CONFIRM_FAILURE,
    API_TOKEN_DESTROY,
    API_TOKEN_DESTROY_SUCCESS,
    API_TOKEN_DESTROY_FAILURE,
    RESEND_ACTIVATION_LINK,
    RESEND_ACTIVATION_LINK_SUCCESS,
    RESEND_ACTIVATION_LINK_FAILURE,
    DISCOUNT_CODE_APPLICATION,
    DISCOUNT_CODE_APPLICATION_SUCCESS,
    DISCOUNT_CODE_APPLICATION_FAILURE,
    GET_AGENT_PROFILE,
    GET_AGENT_PROFILE_SUCCESS,
    GET_AGENT_PROFILE_FAILURE,
    UPGRADE_TO_AGENT_ACCOUNT,
    UPGRADE_TO_AGENT_ACCOUNT_SUCCESS,
    UPGRADE_TO_AGENT_ACCOUNT_FAILURE,
    GET_SHIPPING_QUOTE_FAILURE,
    GET_DOWNLOAD_FILE,
    GET_DOWNLOAD_FILE_SUCCESS,
    GET_DOWNLOAD_FILE_FAILURE,
    UPDATE_TRAINING_PROGRESS,
    UPDATE_TRAINING_PROGRESS_SUCCESS,
    UPDATE_TRAINING_PROGRESS_FAILURE
} from "../constants";

const initialState = {
    registerUserSuccess: null,
    registerUserError: null,
    registerUserLoader: false,

    apiTokenAuthSuccess: false,
    apiTokenAuthError: null,
    apiTokenAuthLoader: false,
    isLoggedIn: false,

    apiTokenDestroySuccess: null,
    apiTokenDestroyError: null,
    apiTokenDestroyLoader: false,

    getDownloadFileSuccess: null,
    getDownloadFileError: null,
    getDownloadFileLoader: false,
    
    getUserProfileSuccess: null,
    getUserProfileError: null,
    getUserProfileLoader: false,

    upgradeToAgentAccountSuccess: null,
    upgradeToAgentAccountError: null,
    upgradeToAgentAccountLoader: false,

    getAgentProfileSuccess: null,
    getAgentProfileError: null,
    getAgentProfileLoader: false,

    updateTrainingProgressSuccess: null,
    updateAgentTrainingError: null,
    updateAgentTrainingLoader: false,

    getUserBasketSuccess: null,
    getUserBasketError: null,
    getUserBasketLoader: false,

    getShippingQuoteSuccess: null,
    getShippingQuoteError: null,
    getShippingQuoteLoader: false,

    applyDiscountCodeSuccess: null,
    applyDiscountCodeError: null,
    applyDiscountCodeLoader: false,

    getUserOrdersSuccess: null,
    getUserOrdersError: null,
    getUserOrdersLoader: false,

    passwordResetSuccess: null,
    passwordResetError: null,
    passwordResetLoader: false,

    passwordResetConfirmSuccess: null,
    passwordResetConfirmError: null,
    passwordResetConfirmLoader: false,

    activateAccConfirmSuccess: null,
    activateAccConfirmError: null,
    activateAccConfirmLoader: false,

    resendActivationLinkSuccess: null,
    resendActivationLinkError: null,
    resendActivationLinkLoader: false

};

export default function authReducer(state = initialState, action) {
    
    switch (action.type) {

        // Register User
        case REGISTER_USER:
            return {
                ...state,
                registerUserLoader: true,
                registerUserSuccess: null,
                registerUserError: null
            };

        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                registerUserLoader: false,
                registerUserSuccess: action.payload,
                registerUserError: null
            };

        case REGISTER_USER_FAILURE:
            return {
                ...state,
                registerUserLoader: false,
                registerUserSuccess: null,
                registerUserError: action.error
            };

        // Activate Account Confirm
        case ACTIVATE_ACCOUNT_CONFIRM:
            return {
                ...state,
                activateAccConfirmLoader: true,
                activateAccConfirmSuccess: null,
                activateAccConfirmError: null
            }

        case ACTIVATE_ACCOUNT_CONFIRM_SUCCESS:
            return {
                ...state,
                activateAccConfirmLoader: false,
                activateAccConfirmSuccess: action.payload,
                activateAccConfirmError: null
            }

        case ACTIVATE_ACCOUNT_CONFIRM_FAILURE:
            return {
                ...state,
                activateAccConfirmLoader: false,
                activateAccConfirmSuccess: null,
                activateAccConfirmError: action.error
            }

        // Resend Activation Link
        case RESEND_ACTIVATION_LINK:
            return  {
                ...state,
                resendActivationLinkLoader: true,
                resendActivationLinkSuccess: null,
                resendActivationLinkError: null
            }

        case RESEND_ACTIVATION_LINK_SUCCESS:
            return {
                ...state,
                resendActivationLinkLoader: false,
                resendActivationLinkSuccess: action.payload,
                resendActivationLinkError: null
            }

        case RESEND_ACTIVATION_LINK_FAILURE:
            return {
                ...state,
                resendActivationLinkLoader: false,
                resendActivationLinkSuccess: null,
                resendActivationLinkError: action.error
            }

        // Api Token Auth
        case API_TOKEN_AUTH:
            return {
                ...state,
                apiTokenAuthLoader: true,
                apiTokenAuthSuccess: false,
                apiTokenAuthError: null
            };

        case API_TOKEN_AUTH_SUCCESS:
            return {
                ...state,
                apiTokenAuthLoader: false,
                apiTokenAuthSuccess: action.payload,
                apiTokenAuthError: null,
                isLoggedIn: true
            };

        case API_TOKEN_AUTH_FAILURE:
            return {
                ...state,
                apiTokenAuthLoader: false,
                apiTokenAuthSuccess: false,
                apiTokenAuthError: action.error
            };

        // API Destroy token
        case API_TOKEN_DESTROY:
            return {
                ...state,
                apiTokenDestroyLoader: true,
                apiTokenDestroySuccess: null,
                apiTokenDestroyError: null
            }

        case API_TOKEN_DESTROY_SUCCESS:
            return {
                ...state,
                apiTokenDestroyLoader: false,
                apiTokenDestroySuccess: action.payload,
                apiTokenDestroyError: null,
                isLoggedIn: false,
                updateTrainingProgressSuccess: null,
                getUserProfileSuccess: null
            }

        case API_TOKEN_DESTROY_FAILURE:
            return {
                ...state,
                apiTokenDestroyLoader: false,
                apiTokenDestroySuccess: null,
                apiTokenDestroyError: action.error
            }

        // Request Password reset
        case PASSWORD_RESET:
            return {
                ...state,
                passwordResetLoader: true,
                passwordResetSuccess: null,
                passwordResetError: null
            };

        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                passwordResetLoader: false,
                passwordResetSuccess: action.payload,
                passwordResetError: null
            }

        case PASSWORD_RESET_FAILURE:
            return {
                ...state,
                passwordResetLoader: false,
                passwordResetSuccess: null,
                passwordResetError: action.error
            }

        // Password Reset Confirm
        case PASSWORD_RESET_CONFIRM:
            return  {
                ...state,
                passwordResetConfirmLoader: true,
                passwordResetConfirmSuccess: null,
                passwordResetConfirmError: null
            }

        case PASSWORD_RESET_CONFIRM_SUCCESS:
            return {
                ...state,
                passwordResetConfirmLoader: false,
                passwordResetConfirmSuccess: action.payload,
                passwordResetConfirmError: null
            }

        case PASSWORD_RESET_CONFIRM_FAILURE:
            return {
                ...state,
                passwordResetConfirmLoader: false,
                passwordResetConfirmSuccess: false,
                passwordResetConfirmError: action.error
            }

        // Get Download File
        case GET_DOWNLOAD_FILE:
            return {
                ...state,
                getDownloadFileLoader: true,
                getDownloadFileSuccess: null,
                getDownloadFileError: null
            };

        case GET_DOWNLOAD_FILE_SUCCESS:
            return {
                ...state,
                getDownloadFileLoader: false,
                getDownloadFileSuccess: action.payload,
                getDownloadFileError: null
            };

        case GET_DOWNLOAD_FILE_FAILURE:
            return {
                ...state,
                getDownloadFileLoader: false,
                getDownloadFileSuccess: null,
                getDownloadFileError: action.error
            };


        // Update Agent Training
        case UPDATE_TRAINING_PROGRESS:
            return {
                ...state,
                updateTrainingProgressSuccess: null,
                updateAgentTrainingError: null,
                updateAgentTrainingLoader: true
            };

        case UPDATE_TRAINING_PROGRESS_SUCCESS:
            return {
                ...state,
                updateTrainingProgressSuccess: action.payload,
                updateAgentTrainingError: null,
                updateAgentTrainingLoader: false
            };

        case UPDATE_TRAINING_PROGRESS_FAILURE:
            return {
                ...state,
                updateTrainingProgressSuccess: null,
                updateAgentTrainingError: action.error,
                updateAgentTrainingLoader: false
            };


        // Get User Profile
        case GET_USER_PROFILE:
            return {
                ...state,
                getUserProfileLoader: true,
                getUserProfileSuccess: null,
                getUserProfileError: null
            };

        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                getUserProfileLoader: false,
                getUserProfileSuccess: action.payload,
                getUserProfileError: null,
                isLoggedIn: true
            };

        case GET_USER_PROFILE_FAILURE:
            return {
                ...state,
                getUserProfileLoader: false,
                getUserProfileSuccess: null,
                getUserProfileError: action.error,
                isLoggedIn: false
            };

        // Get Agent Profile
        case GET_AGENT_PROFILE:
            return {
                ...state,
                getAgentProfileLoader: true,
                getAgentProfileSuccess: null,
                getAgentProfileError: null
            };

        case GET_AGENT_PROFILE_SUCCESS:
            return {
                ...state,
                getAgentProfileLoader: false,
                getAgentProfileSuccess: action.payload,
                getAgentProfileError: null,
            };

        case GET_AGENT_PROFILE_FAILURE:
            return {
                ...state,
                getAgentProfileLoader: false,
                getAgentProfileSuccess: null,
                getAgentProfileError: action.error
            };


        // Upgrade to Agent Account
        case UPGRADE_TO_AGENT_ACCOUNT:
            return {
                ...state,
                upgradeToAgentAccountLoader: true,
                upgradeToAgentAccountSuccess: null,
                upgradeToAgentAccountError: null
            }

        case UPGRADE_TO_AGENT_ACCOUNT_SUCCESS:
            return {
                ...state,
                upgradeToAgentAccountLoader: false,
                upgradeToAgentAccountSuccess: action.payload,
                upgradeToAgentAccountError: null
            }

        case UPGRADE_TO_AGENT_ACCOUNT_FAILURE:
            return {
                ...state,
                upgradeToAgentAccountLoader: false,
                upgradeToAgentAccountSuccess: null,
                upgradeToAgentAccountError: action.error
            }


        // Get User Basket
        case GET_USER_BASKET:
            return {
                ...state,
                getUserBasketLoader: true,
                getUserBasketSuccess: null,
                getUserBasketError: null,
            };

        case GET_USER_BASKET_SUCCESS:
            return {
                ...state,
                getUserBasketLoader: false,
                getUserBasketSuccess: action.payload,
                getUserBasketError: null,
                isLoggedIn: true
            };

        case GET_USER_BASKET_FAILURE:
             return {
                ...state,
                getUserBasketLoader: false,
                getUserBasketSuccess: null,
                getUserBasketError: action.error,
            }

        // Get Shipping Quote
        case GET_SHIPPING_QUOTE:
            return {
                ...state,
                getShippingQuoteLoader: true,
                getShippingQuoteSuccess: null,
                getShippingQuoteError: null,
            };

        case GET_SHIPPING_QUOTE_SUCCESS:
            return {
                ...state,
                getShippingQuoteLoader: false,
                getShippingQuoteSuccess: action.payload,
                getShippingQuoteError: null,
            };

        case GET_SHIPPING_QUOTE_FAILURE:
            return {
                ...state,
                getShippingQuoteLoader: false,
                getShippingQuoteSuccess: null,
                getShippingQuoteError: action.error,
            };

        case DISCOUNT_CODE_APPLICATION:
            return {
                ...state,
                applyDiscountCodeLoader: true,
                applyDiscountCodeSuccess: null,
                applyDiscountCodeError: null,
            };

        case DISCOUNT_CODE_APPLICATION_SUCCESS:
            return {
                ...state,
                applyDiscountCodeLoader: false,
                applyDiscountCodeSuccess: action.payload,
                applyDiscountCodeError: null
            }

        case DISCOUNT_CODE_APPLICATION_FAILURE:
            return {
                ...state,
                applyDiscountCodeLoader: false,
                applyDiscountCodeSuccess: null,
                applyDiscountCodeError: action.error
            }


        // Get User Orders
        case GET_USER_ORDERS:
            return {
                ...state,
                getUserOrdersLoader: true,
                getUserOrdersSuccess: null,
                getUserOrdersError: null,
            };

        case GET_USER_ORDERS_SUCCESS:
            return  {
                ...state,
                getUserOrdersLoader: false,
                getUserOrdersSuccess: action.payload,
                getUserOrdersError: null,
                isLoggedIn: true
            };

        case GET_USER_ORDERS_FAILURE:
            return {
                ...state,
                getUserOrdersLoader: false,
                getUserOrdersSuccess: null,
                getUserOrdersError: action.error,
            }

        default:
            return state;
    }
}
