import ResendActivationComponent from './../components/ResendActivationComponent';
import { connect } from "react-redux";
import { authAction } from "./../store/actions";

const mapStateToProps = state => {
    const {
        authReducer: {
            resendActivationLinkLoader,
            resendActivationLinkSuccess,
            resendActivationLinkError,
        }
    } = state;
    return {
        resendActivationLinkLoader,
        resendActivationLinkSuccess,
        resendActivationLinkError,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resendActivationLink: payload =>
          dispatch(authAction.resendActivationLink(payload))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResendActivationComponent);