import { connect } from "react-redux";
import {vehicleAction} from "../store/actions";
import AddSparesContentComponent from "../components/Vendor/AddSparesContentComponent"


const mapStateToProps = state => {
  const {
    vehicleReducer: {postContentSuccess, postContentError, postContentLoader,
      getContentSuccess, getContentError, getContentLoader, createLectureSuccess,
      createLectureError, createLectureLoader, uploadImageSuccess, uploadImageError,
      uploadImageLoader, deleteContentSuccess, deleteContentError, deleteContentLoader,
      getCompatibilitySuccess, getCompatibilityError, getCompatibilityLoader,
      retrieveFitmentSuccess, retrieveFitmentError, retrieveFitmentLoader, setCompatibilityLoader,
      setCompatibilitySuccess
    }
  } = state
  return {
    postContentSuccess,
    postContentError,
    postContentLoader,
    getContentSuccess,
    getContentError,
    getContentLoader,
    createLectureSuccess,
    createLectureError,
    createLectureLoader,
    uploadImageSuccess,
    uploadImageError,
    uploadImageLoader,
    deleteContentSuccess,
    deleteContentError,
    deleteContentLoader,
    getCompatibilitySuccess,
    getCompatibilityError,
    getCompatibilityLoader,
    retrieveFitmentSuccess,
    retrieveFitmentError,
    retrieveFitmentLoader,
    setCompatibilityLoader,
    setCompatibilitySuccess
  };

};

const mapDispatchToProps = dispatch => {
  return {
    postContent: payload =>
      dispatch(vehicleAction.postContent(payload)),
    getContent: payload =>
      dispatch(vehicleAction.getContent(payload)),
    createLecture: payload =>
      dispatch(vehicleAction.createLecture(payload)),
    uploadImage: payload =>
      dispatch(vehicleAction.uploadImage(payload)),
    deleteContent: payload =>
      dispatch(vehicleAction.deleteContent(payload)),
    getCompatibility: payload =>
      dispatch(vehicleAction.getCompatibility(payload)),
    retrieveFitment: payload =>
      dispatch(vehicleAction.retrieveFitment(payload)),
    setCompatibility: payload =>
      dispatch(vehicleAction.setCompatibility(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSparesContentComponent);