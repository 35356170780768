import ShopByMake from './../components/ShopByMake';
import { connect } from "react-redux";
import { vehicleAction } from "./../store/actions";

const mapStateToProps = state => {
    const {
        vehicleReducer: { vehicleMakeCountSuccess, vehicleMakeCountError, vehicleMakeCountLoader }
    } = state;
    return {
        vehicleMakeCountSuccess,
        vehicleMakeCountError,
        vehicleMakeCountLoader
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getVehicleMakeCount: _ =>
            dispatch(vehicleAction.getVehicleMakeCount())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopByMake);