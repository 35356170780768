import React, { Component } from "react";
import {Alert, Button, Form, FormGroup, Input, Label} from 'reactstrap';
import Countdown from 'react-countdown-now'

class FlashDiscount extends Component{
  constructor(props) {
    super(props);
    this.autoRequestRef = React.createRef()
    this.state = {
        userEmail: "",
        error: false,
        message: "",
        redirect: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount() {
    document.body.scrollTop = 0
    window.scrollTo(0,0);
  }

  handleContinue() {
    this.props.history.push("/")
  }

  handleChange(event) {
      this.setState({value: event.target.value});
      const target = event.target;
      const name = target.name;

      this.setState({
          [name]: target.value
      })
  }

  handleSubmit(event) {
    event.preventDefault()
    const { userEmail } = this.state

    if (userEmail === "") {
      this.autoRequestRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      this.setState({error: true, message: "Enter email to reveal discount code"})
      return;
    }

    this.sendRequest(process.env.REACT_APP_EMAILJS_EMAIL_LIST_TEMPLATEID, this.state.userEmail,
        process.env.REACT_APP_EMAILJS_RECEIVER)

    this.props.history.push("/flash-discount-confirm")
  }

  sendRequest(templateId, userEmail, receiverEmail) {
    window.emailjs.send(
      'sendgrid',
      templateId,
        {
          userEmail,
          receiverEmail
        })
        .then(res => {
          this.setState({
              userEmail: ""
            })
        })
        .catch(err => console.error('Failed to send feedback. Error: ', err))
  }

  render(){
    const { message } = this.state;
    const { discountSuccess } = this.props;
    const promoDate = '2020-01-20T23:59:00'

    return(

      <div>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">

              <div className="static-info-page-promo" style={{backgroundImage: "radial-gradient(circle at center,#fff 0,#009898 100%);"}}>
                <div className="" style={{ textAlign: 'center' }}>
                  <h4 className="page-header-content" style={{
                    color: 'black',
                    textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white'
                    }}>$200 DISCOUNT OFFER</h4>
                  <hr />
                  {discountSuccess && discountSuccess ?
                    <div>
                      <div style={{
                        fontWeight: 800,
                        color: '#484848',
                        fontFamily: 'monospace',
                        fontSize: '16px'}}>Discount Code: PROMO-200
                      </div>
                      <br/>
                      <div style={{fontSize: '13px', fontWeight: 500, paddingBottom: '20px'}}>
                        Use this code within the next 3 days to get <span style={{fontWeight: 700}}>$200 OFF</span> on your next purchase
                      </div>
                      <br/>
                      <div className="col-lg-6 col-sm-12 offset-lg-3">
                        <img
                          src={require("../assets/single-elements/mercedes-banner-image2.png")}
                          className="mx-auto car-img img-responsive"
                          alt="how to buy"
                          style={{maxHeight: '100px'}}
                        />
                      </div>
                      <br/>
                      <div style={{paddingTop: '20px'}} className="col-lg-6 col-sm-12 offset-lg-3">
                        <Button style={{background: '#242424'}} className="delete-basket-button" size="sm" onClick={() => this.handleContinue()}>Continue shopping</Button>
                      </div>
                      <br/>
                    </div>
                    :
                    <div>
                      <div style={{fontSize: '13px', fontWeight: 500}}>
                        <p>Expires on 20/01/2020</p>
                        <p>Hurry up before registration closes</p>
                      </div>
                      {/*<br />*/}
                      <div style={{fontSize: '30px', fontWeight: 900, color: '#484848'}}
                           className="col-lg-6 col-sm-12 offset-lg-3">
                        <Countdown
                          date={promoDate} />
                      </div>
                      <br />
                      <div className="col-lg-6 col-sm-12 offset-lg-3">
                        <img
                          src={require("../assets/single-elements/mercedes-banner-image2.png")}
                          className="mx-auto car-img img-responsive"
                          alt="how to buy"
                          style={{maxHeight: '100px'}}
                        />
                      </div>
                      <br />
                      <div className="col-lg-6 col-sm-12 offset-lg-3" style={{padding: '10px'}}>
                        <Form onSubmit={this.handleSubmit}>
                          <div ref={this.autoRequestRef}>
                            {message &&
                            <Alert color="danger">
                              {message}
                            </Alert>}
                          </div>
                          <FormGroup>
                            <Label for="userEmail" className="form-input">Email</Label>
                            <Input
                              name="userEmail"
                              type="email"
                              value={this.state.userEmail}
                              onChange={this.handleChange}
                              placeholder="Your email address"
                              maxLength={255}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Input type="submit" name="signup" id="signup" style={{color: 'white', background: 'black'}}
                                   className="btn btn-primary" value="REVEAL CODE"/>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>}
                    <div style={{fontSize: '10px', fontWeight: 500, paddingBottom: '50px', paddingTop: '20px'}}>
                      <p>*Terms and Condition</p>
                       Offer valid until 20/01/2020. The discount value is $200 United States dollars.
                      Discount offer applicable to products in vehicle category only. Minimum overall order value of $USD1000.
                      The code can only be used once per customer
                    </div>
                </div>
              </div>
           </div>
           </div>
          </div>
        </section>
      </div>
      );
    }
}
export default FlashDiscount;
