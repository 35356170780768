import React, { Component } from "react";
import { withRouter} from "react-router-dom";
// import "react-alice-carousel/lib/alice-carousel.css"
import { withCookies } from "react-cookie";
import { compose } from "redux";
import AccountConfirmation from "./ConfirmActivateAccount"


class ActivateConfirmComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uid: "",
      token: "",
      message: "",
      error: false
    };

  }

  componentWillReceiveProps(nextProps) {
  }

  componentDidUpdate(prevProps) {
    // TODO Show more specific error message
    let { activateAccConfirmSuccess, activateAccConfirmError } = this.props
    if ( activateAccConfirmSuccess && prevProps.activateAccConfirmSuccess !== activateAccConfirmSuccess ) {
      let message = activateAccConfirmSuccess.msg
      this.setState({error: false, message: message})
    }
    if ( activateAccConfirmError && prevProps.activateAccConfirmError !== activateAccConfirmError ) {
      let message = activateAccConfirmError.msg
      this.setState({error: true, message: message})
    }
  }

  componentDidMount() {
    const { activateAccountConfirm } = this.props;
    let uid = window.location.pathname.split('/')[2];
    let token = window.location.pathname.split('/')[3];
    activateAccountConfirm({
      uid: uid,
      token: token
    });
    this.setState({uid: uid, token: token})
  };

  render() {
    var { state } = this;
    let error = state.error
    let message = state.message

    return (
      <div>
        <AccountConfirmation response={error} message={message} />
      </div>
    );
    }
}

export default compose(
    withCookies,
    withRouter
)(ActivateConfirmComponent);
