import React, { Component } from "react";

class CommercialVehicles extends Component{
    render(){
        return(
            <div>
                <h1>Commercial Vehicles</h1>
            </div>
        );
    }
}
export default CommercialVehicles ;