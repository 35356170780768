import { connect } from "react-redux";
import {vehicleAction} from "../store/actions";
import AddContentComponent from "../components/Vendor/AddContentComponent"


const mapStateToProps = state => {
  const {
    vehicleReducer: {postContentSuccess, postContentError, postContentLoader,
      getContentSuccess, getContentError, getContentLoader, createLectureSuccess,
      createLectureError, createLectureLoader, uploadImageSuccess, uploadImageError,
      uploadImageLoader, deleteContentSuccess, deleteContentError, deleteContentLoader
    }
  } = state
  return {
    postContentSuccess,
    postContentError,
    postContentLoader,
    getContentSuccess,
    getContentError,
    getContentLoader,
    createLectureSuccess,
    createLectureError,
    createLectureLoader,
    uploadImageSuccess,
    uploadImageError,
    uploadImageLoader,
    deleteContentSuccess,
    deleteContentError,
    deleteContentLoader
  };

};

const mapDispatchToProps = dispatch => {
  return {
    postContent: payload =>
      dispatch(vehicleAction.postContent(payload)),
    getContent: payload =>
      dispatch(vehicleAction.getContent(payload)),
    createLecture: payload =>
      dispatch(vehicleAction.createLecture(payload)),
    uploadImage: payload =>
      dispatch(vehicleAction.uploadImage(payload)),
    deleteContent: payload =>
      dispatch(vehicleAction.deleteContent(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddContentComponent);