import React, { Component } from "react";
import { Loading } from "../LoadingComponent";
import { Link } from "react-router-dom";
import {
  FormGroup,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Form,
  Collapse,
  Card,
  CardImg,
  CardTitle, BreadcrumbItem, Breadcrumb
} from "reactstrap";
import onImage from "../../assets/images/no-image.jpg";

import { veh_data } from "../../shared/vehicle_make_and_models";

class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: null,
      results: props.searchVehicleSuccess && props.searchVehicleSuccess.count,
      searchVehicleSuccess:
        props.searchVehicleSuccess && props.searchVehicleSuccess.results,
      currentPage: 1,
      pageNumbers: [],
      dropdownOpen: false,
      makeSelected: this.props.url.sv,
      modelSelected: this.props.url.svm,
      minValue: 0,
      maxValue: 0,
      sorted: "0",
      values: [
        500,
        1000,
        1500,
        2000,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        5500,
        6000,
        6500,
        7000,
        7500,
        8000,
        8500,
        9000,
        9500,
        10000,
        10500,
        11000,
        11500,
        12000,
        12500,
        13000,
        13500,
        14000,
        14500,
        15000,
        15500,
        16000,
        16500,
        17000,
        17500,
        18000,
        18500,
        19000,
        19500,
        20000,
        20500,
        21000,
        21500,
        22000,
        22500,
        23000,
        23500,
        24000,
        24500,
        25000,
        25500,
        26000,
        26500,
        27000,
        27500,
        28000,
        28500,
        29000,
        29500,
        30000,
        30500,
        31000,
        31500,
        32000,
        32500,
        33000,
        33500,
        34000,
        34500,
        35000,
        35500,
        36000,
        36500,
        37000,
        37500,
        38000,
        38500,
        39000,
        39500,
        40000,
        40500,
        41000,
        41500,
        42000,
        42500,
        43000,
        43500
      ]
    };
    this.handlePaginationClick = this.handlePaginationClick.bind(this);
    this.handlePreviousForward = this.handlePreviousForward.bind(this);
    this.toggle = this.toggle.bind(this);
    this.keyToOption = this.keyToOption.bind(this);
    this.handleMakeChange = this.handleMakeChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleMin = this.handleMin.bind(this);
    this.handleMax = this.handleMax.bind(this);
    this.sortAscending = this.sortAscending.bind(this);
    this.sortDescending = this.sortDescending.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleBreadCrumb = this.handleBreadCrumb.bind(this);
    this.paginate = this.paginate.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.props.sideMenuAction(true);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.props.sideMenuAction(false);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidUpdate() {
    // window.scrollTo(0, this.topElement.offsetTop);
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  handleMakeChange(event) {
    const query = `${this.props.url.page}/${this.props.url.min}/${
      this.props.url.max
    }/${this.props.url.st}/${event.target.value}/${this.props.url.svm}/${this.state.sorted}`;

    this.props.history.push(`/search/${query}`);
    this.setState({ makeSelected: event.target.value });
  }

  handleModelChange(event) {
    const query = `${this.props.url.page}/${this.props.url.min}/${
      this.props.url.max
    }/${this.props.url.st}/${this.state.makeSelected}/${event.target.value}/${this.state.sorted}`;
    this.props.history.push(`/search/${query}`);

    this.setState({ modelSelected: event.target.value });
  }

  handleMin(event) {
    this.setState({ minValue: event.target.value });
  }

  handleMax(event) {
    const query = `${this.props.url.page}/${this.state.minValue}/${
      event.target.value
    }/${this.props.url.st}/${this.props.url.sv}/${this.props.url.svm}`;

    this.props.history.push(`/search/${query}`);

    this.setState({ maxValue: event.target.value });
  }

  handleBreadCrumb(type, value) {
    let query = `1/0/0/${this.props.url.st}/${this.props.url.sv}/any`;

    if (type === "class") {
      query = `1/0/0/${this.props.url.st}/*/*/${this.state.sorted}`;
    }
    if (type === "model") {
      query = `1/0/0/${this.props.url.st}/${this.props.url.sv}/*/${this.state.sorted}`;
    }


    this.props.history.push(`/search/${query}`);
  }

  keyToOption(key) {
    return key
      .split("-")
      .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
      .join(" ");
  }


  static getDerivedStateFromProps(props, state) {
    const { searchVehicleSuccess } = props;
    // Logic for displaying limited searched Vehicles
    if (searchVehicleSuccess) {
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(searchVehicleSuccess.count / 25); i++) {
        pageNumbers.push(i);
      }
      return {
        results: props.searchVehicleSuccess.count,
        searchVehicleSuccess: searchVehicleSuccess.results,
        pageNumbers
      };
    }
    return null;
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  sortAscending() {
    // var ascending = this.state.searchVehicleSuccess.sort(
    //   (a, b) => a.price - b.price
    // );
    // this.setState({
    //   searchVehicleSuccess: ascending
    // });
    if (this.state.sorted === "0") {
      return
    }

    const query = `${this.props.url.page}/${this.props.url.min}/${
      this.props.url.max
    }/${this.props.url.st}/${this.props.url.sv}/${this.props.url.svm}/0`;

    this.props.history.push(`/search/${query}`);
    this.setState({ sorted: "0"  });
  }

  sortDescending() {
    // var decending = this.state.searchVehicleSuccess.sort(
    //   (a, b) => b.price - a.price
    // );
    // this.setState({
    //   searchVehicleSuccess: decending
    // });
    if (this.state.sorted === "1") {
      return
    }
    const query = `${this.props.url.page}/${this.props.url.min}/${this.props.url.max
                    }/${this.props.url.st}/${this.props.url.sv}/${this.props.url.svm}/1`;

    this.props.history.push(`/search/${query}`);
    this.setState({ sorted: "1"  });

  }

  routeToDetails = (id) => {
    const { history } = this.props;
    history.push(`/details/${id}`);
  };

  renderResults(currentResult) {
    if (currentResult && currentResult.length) {
      return currentResult.map(
        ({ price, title, remote_image, fuel_type, transmission, id }, i) => {
          return (
            <div
              className="col-12 col-md-12 col-lg-12 mt-12 search-card"
              key={i}>
              <Card onClick={() => this.routeToDetails(id)} key={"card-" + i} className="cardSearch">
                <Link to="#">
                  <div className="row">
                    <div className="col col-sm-4 col-md-2 col-lg-3 search-card-img-div">
                      <CardImg
                        src={
                          remote_image !== "No_Image_found"
                            ? remote_image
                            : onImage
                        }
                        className="mx-auto car-img img-responsive card-img-top"
                        id="search-card-imgsdf"
                      />
                    </div>
                    <div className="col col-sm-8 col-md-6 col-lg-6 contentTitles">
                      <div className="topPriceContainer">
                        <span className="price">
                          $
                          {price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </span>
                      </div>
                      <CardTitle className="cardSearchTitle">{title}</CardTitle>
                      <div className="container cardProperties" style={{ boxShadow: "4px 4px 1px #EEE"}}>
                        {transmission && ` ${transmission} `}
                        {transmission && fuel_type && "- "}
                        {fuel_type &&
                          ` 
                        ${fuel_type}`}
                      </div>
                    </div>
                    <div className="col col-sm-3 col-md-3 col-lg-3 d-flex flex-column justify-content-between align-items-center price-container">
                      <span className="price">
                        $
                        {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </span>
                      <Link
                        to="#"
                        className="btn btn-primary d-lg-block inquiryBtn">
                        Inquire
                      </Link>
                    </div>
                  </div>
                </Link>
              </Card>
            </div>
          );
        }
      );
    } else {
      return (
        <div className="col-12 col-md-12 col-lg-12 mt-12">
          <Card>No record Found</Card>
        </div>
      );
    }
  }

  handlePaginationClick(number) {
    const query = `${number}/${this.props.url.min}/${this.props.url.max}/${
      this.props.url.st
    }/${this.props.url.sv}/${this.props.url.svm}/${this.state.sorted}`;

    this.props.history.push(`/search/${query}`);

    this.setState({
      currentPage: number
    });
  }

  handlePreviousForward(number, switchState) {
    const reqNumber = switchState ? this.state.currentPage + 1 : this.state.currentPage - 1
    const query = `${reqNumber}/${this.props.url.min}/${this.props.url.max}/${
      this.props.url.st
    }/${this.props.url.sv}/${this.props.url.svm}/${this.state.sorted}`;
    this.props.history.push(`/search/${query}`);
    if (switchState) {
      this.setState({
        currentPage: this.state.currentPage + number
      });
    } else {
      this.setState({
        currentPage: this.state.currentPage - number
      });
    }
  }

  paginate(array, page_size, page_number) {
    --page_number
    return array.slice(page_number, (page_number + page_size));
  }

  renderPagination(pageNumbers) {
    const renderedPages = this.paginate(pageNumbers, 5, this.state.currentPage)
    return renderedPages.map(number => {
      return (
        <li
          className={`page-item${
            this.state.currentPage === number ? " active" : ""
          }`}
          key={number}
          onClick={() => this.handlePaginationClick(number)}>
          <span className="page-link">{number}</span>
        </li>
      );
    });
  }

  render() {
    const vehicles = veh_data.reduce(
      (acc, veh, i) => {
        let make = Object.keys(veh)[0];
        let vehModels = veh[make];
        return {
          makes: [
            ...acc.makes,
            <option key={make + i} value={make}>
              {this.keyToOption(make)}
            </option>
          ],
          models: {
            ...acc.models,
            [make]: [
              <option key={make + i + 0} value={0}>
                Any Model
              </option>,
              vehModels.map((model, i) => {
                return (
                  <option key={make + model + i} value={model}>
                    {this.keyToOption(model)}
                  </option>
                );
              })
            ]
          }
        };
      },
      { makes: [], models: [] }
    );


    const selectedModels =
      this.state.makeSelected && this.state.makeSelected.length ? (
        vehicles.models[this.state.makeSelected]
      ) : (
        <option disabled value={0}>
          All models
        </option>
      );

    const selectMinValues = this.state.values.map((val, i) => {
      return (
        <option key={val + i} value={val}>
          {val}
        </option>
      );
    });

    const selectMaxValues = this.state.values.map((val, i) => {
      return (
        <option key={val + i} value={val}>
          {val}
        </option>
      );
    });

    const { searchVehicleLoader, url } = this.props;

    const makeSel = this.state.makeSelected
    // const svEl = this.props.url.sv
    const defaultFilterOption = makeSel === "*" ? 0 : makeSel
    const defaultMinValue = this.state.minValue === 0 ? 0 : this.state.minValue
    const defaultMaxValue = this.state.maxValue === 0 ? 0 : this.state.maxValue

    const {
      currentPage,
      searchVehicleSuccess,
      pageNumbers,
      results,
      width
    } = this.state;
    return (

      <div ref={topElement => (this.topElement = topElement)}>
        <div className="container text-center" id="contactContainer">
          <div className="row">
            {searchVehicleLoader ? (
              <div className="offset-5 offset-lg-6">
                <Loading />
              </div>
            ) : (
              <div className="container">
                <Breadcrumb style={{marginBottom: "10px", borderBottom: "1px solid #DDD"}}>
                  <BreadcrumbItem>
                    <Link to="/" className="listedLink">
                      Home
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link
                      to="#"
                      className="listedLink"
                      onClick={() => this.handleBreadCrumb("class", url.st)}>
                        {url.st === "1" ? "cars" : "commercial"}
                      </Link>{" "}
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link
                        to="#"
                        className="listedLink"
                        onClick={() => this.handleBreadCrumb("model", url.sv)}>
                        {url.sv}
                      </Link>{" "}
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">
                      {url.svm === "any" ? "all" : url.svm}
                    </Link>
                  </BreadcrumbItem>
                </Breadcrumb>
                <Collapse isOpen={width > 769 || this.props.filterReducer}>
                  <div>
                    <Form>
                      <Row>
                        <Col sm={12} md={2} lg={2}>
                          <FormGroup style={{display: 'None'}}>
                            <Input
                              className="filter_Button"
                              onChange={e => this.handleMakeChange(e)}
                              type="select"
                              value={defaultFilterOption}
                              name="q"
                              id="q">
                              <option disabled value={0}>
                                All makes
                              </option>
                              {vehicles.makes}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={2} lg={2} style={{display: 'None'}}>
                          <FormGroup>
                            <Input
                              className="filter_Button"
                              onChange={e => this.handleModelChange(e)}
                              type="select"
                              value={
                                this.state.modelSelected ||
                                this.props.url.svm ||
                                "any"
                              }
                              name="m"
                              id="m">
                              {selectedModels}
                            </Input>
                          </FormGroup>
                        </Col>

                        <Col sm={12} md={2} lg={2} style={{display: 'None'}}>
                          <FormGroup>
                            <Input
                              className="filter_Button"
                              onChange={e => this.handleMin(e)}
                              type="select"
                              value={defaultMinValue}
                              name="m"
                              id="m">
                              <option disabled value={0}>
                                Min Price
                              </option>
                              {selectMinValues}
                            </Input>
                          </FormGroup>
                        </Col>

                        <Col sm={12} md={2} lg={2} style={{display: 'None'}}>
                          <FormGroup>
                            <Input
                              className="filter_Button"
                              onChange={e => this.handleMax(e)}
                              type="select"
                              value={defaultMaxValue}
                              name="m"
                              id="m">
                              <option disabled value={0}>
                                Max Price
                              </option>
                              {selectMaxValues}
                            </Input>
                          </FormGroup>
                        </Col>
                        {/*// TODO: Add more filters*/}
                        {/*<Col sm={12} md={2} lg={2}>*/}
                        {/*  <FormGroup>*/}
                        {/*    <Input*/}
                        {/*      className="filter_Button"*/}
                        {/*      onChange={e => this.handleMax(e)}*/}
                        {/*      type="select"*/}
                        {/*      value={0}*/}
                        {/*      name="m"*/}
                        {/*      id="m">*/}
                        {/*      <option disabled value={0}>*/}
                        {/*        More filters*/}
                        {/*      </option>*/}
                        {/*    </Input>*/}
                        {/*  </FormGroup>*/}
                        {/*</Col>*/}
                      </Row>
                    </Form>
                  </div>
                </Collapse>

                {/*<div className="listedSection">*/}
                {/*  <div className="col-sm-4">*/}
                {/*    /!*<p className="text-left listedPTag">*!/*/}
                {/*    /!*  Listed in:{" "}*!/*/}
                {/*    /!*  <Link to="#" className="listedLink">*!/*/}
                {/*    /!*    {url.st === "1" ? "cars" : "commercial"}*!/*/}
                {/*    /!*  </Link>{" "}*!/*/}
                {/*    /!*  >{" "}*!/*/}
                {/*    /!*  <Link*!/*/}
                {/*    /!*    to="#"*!/*/}
                {/*    /!*    className="listedLink"*!/*/}
                {/*    /!*    onClick={() => this.handleBreadCrumb()}>*!/*/}
                {/*    /!*    {url.sv}*!/*/}
                {/*    /!*  </Link>{" "}*!/*/}
                {/*    /!*  >{" "}*!/*/}
                {/*    /!*  <Link to="#">{url.svm === "any" ? "all" : url.svm}</Link>*!/*/}
                {/*    /!*</p>*!/*/}
                {/*    <h6 className="text-left">*/}
                {/*      <b className="resultBold">*/}
                {/*        {results ? results : 0} results*/}
                {/*      </b>*/}
                {/*    </h6>*/}
                {/*  </div>*/}
                {/*  <div className="col-sm-4">*/}
                {/*    <ButtonDropdown*/}
                {/*      isOpen={this.state.dropdownOpen}*/}
                {/*      toggle={this.toggle}>*/}
                {/*      <DropdownToggle caret className="filter_Button">*/}
                {/*        Sort by price*/}
                {/*      </DropdownToggle>*/}
                {/*      <DropdownMenu>*/}
                {/*        <DropdownItem onClick={this.sortAscending}>*/}
                {/*          Ascending*/}
                {/*        </DropdownItem>*/}
                {/*        <DropdownItem onClick={this.sortDescending}>*/}
                {/*          Descending*/}
                {/*        </DropdownItem>*/}
                {/*      </DropdownMenu>*/}
                {/*    </ButtonDropdown>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <h6 className="text-left">
                      <b className="resultBold">
                        {results ? results : 0} results found
                      </b>
                    </h6>
                    </div>
                    <div className="col" style={{textAlign: "right"}}>
                      <ButtonDropdown
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggle}>
                      <DropdownToggle caret className="filter_Button">
                        Sort by price
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={this.sortAscending}>
                          Ascending
                        </DropdownItem>
                        <DropdownItem onClick={this.sortDescending}>
                          Descending
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                    </div>
                  </div>
                </div>
                <hr />
                {searchVehicleSuccess &&
                  this.renderResults(searchVehicleSuccess)}
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="pagination-container mt-10 mb-10">
                    <ul className="pagination mb-0">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? " disabled" : ""
                        }`}
                        onClick={() =>
                          currentPage !== 1
                            ? this.handlePreviousForward(1, false)
                            : {}
                        }>
                        <span className="page-link">&lt;</span>
                      </li>
                      {this.renderPagination(pageNumbers)}
                      <li
                        className={`page-item ${
                          currentPage >= pageNumbers.length ? " disabled" : ""
                        }`}
                        onClick={() =>
                          currentPage < pageNumbers.length
                            ? this.handlePreviousForward(1, true)
                            : {}
                        }>
                        <span className="page-link">&gt;</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SearchResult;
