import React from 'react';
import { Col, Form} from 'reactstrap';
import {Loading} from "../LoadingComponent";
import ProgressBar from 'react-bootstrap/ProgressBar'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class SupporterProgressTracker extends React.Component {


  render() {

  const {now, completeProfile} = this.props

  const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

  return <div>
    <div className="container"
         style={{backgroundColor: 'whitesmoke', marginTop: '35px', paddingTop: '20px', fontSize: '15px'}}>
      <div className="row">
        <Col sm={12} className="">
          {!completeProfile ?
            <div>
              <div>Your profile is incomplete</div>
              <div style={{fontWeight: 500}}>Finish training to start earning points now</div>
            </div>
            :
            <div>
              <div>Congratulation! You're a qualified IBO</div>
              <div style={{fontWeight: 500}}>You have been rewarded 100 points!</div>
            </div>
          }
          <div style={{padding: '15px'}}>{progressInstance}</div>
        </Col>
      </div>
      <div className="row" style={{textAlign: 'left', paddingLeft: '15px'}}>
        <Col sm={12} md={6} className="">
          <div>
            <FormControlLabel
              disabled={false}
              control={<Checkbox
                color="primary"
                checked={true}
                name="checkedE" />}
              label="Register" />
          </div>
        </Col>
        <Col sm={12} md={6} className="">
          <div>
            <FormControlLabel
              disabled={!completeProfile}
              control={<Checkbox
                color="primary"
                checked={true}
                name="checkedE" />}
              label="Training" />
          </div>
        </Col>
      </div>
    </div>
  </div>;
  }
}

export default SupporterProgressTracker;