export const countries = [
'Tanzania',
'Zambia',
'Malawi',
'Mozambique',
'South Africa',
'Lesotho',
'Botswana',
'Zimbabwe',
'Kenya',
'Uganda',
'Mauritius',
'Namibia',
'Eswatini'
// 'Bangladesh',
// 'Pakistan',
// 'New Zealand',
// 'Bahamas',
// 'Jamaica',
// 'Angola',
// 'Barbados',
// 'Belize',
// // 'Bénin',
// // 'Burkina Faso',
// // 'Burundi',
// // "Ivory coast",
// // 'Cabo Verde',
// // 'Cameroon',
// // 'Chad',
// // 'Congo',
// // 'Equatorial Guinea',
// // 'Eswatini',
// // 'Gabon',
// 'Gambia',
// 'Ghana',
// // 'Guyana',
// // 'Liberia',
// // 'Madagascar',
// // 'Mali',
// 'Mauritius',

// // 'Niger',
// 'Nigeria',
// 'Rwanda',
// // 'Senegal',
// // 'Seychelles',
// // 'Sierra Leone',
// // 'Somalia',
// // 'South Sudan',
// // 'Togo',
// 'United Kingdom',
// 'United States of America'
]