import { combineReducers } from "redux";
import authReducer from "./authReducer";
import vehicleReducer from "./vehicleReducer";
import filterReducer from "./filterCollapseReducer";
import sideMenuReducer from "./sideMenuReducer";
import searchReducer from "./searchReducer";

const rootReducer = combineReducers({
  authReducer,
  vehicleReducer,
  filterReducer,
  sideMenuReducer,
  searchReducer,
});

export default rootReducer;
