import Car from './../components/Car/CarComponent';
import { connect } from "react-redux";
import { vehicleAction } from "./../store/actions";

const mapStateToProps = state => {
    const {
        vehicleReducer: { 
            getProductsSuccess, 
            getProductsError, 
            getProductsLoader,
            getCountriesSuccess,
            getCountriesError,
            getCountriesLoader,
            getSpecificationsSuccess,
            getSpecificationsError,
            getSpecificationsLoader,
            getPriceSuccess,
            getPriceError,
            addProductToBasketSuccess,
            addProductToBasketError,
            addProductToBasketLoader,
            placeOrderSuccess,
            placeOrderError,
            placeOrderLoader,

      }
    } = state;
    return {
        getProductsSuccess,
        getProductsError,
        getProductsLoader,
        getCountriesSuccess,
        getCountriesError,
        getCountriesLoader,
        getSpecificationsSuccess,
        getSpecificationsError,
        getSpecificationsLoader,
        getPriceSuccess,
        getPriceError,
        addProductToBasketSuccess,
        addProductToBasketError,
        addProductToBasketLoader,
        placeOrderSuccess,
        placeOrderError,
        placeOrderLoader,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProducts: payload =>
            dispatch(vehicleAction.getProducts(payload)),
        addProductToBasket: payload =>
            dispatch(vehicleAction.addProductToBasket(payload)),
        placeOrder: payload =>
          dispatch(vehicleAction.placeOrder(payload)),
        getCountries: payload =>
          dispatch(vehicleAction.getCountries())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Car);