export const dev_landing_data = [{'title': 'Parents & Guardians', 'price': 4500,
  'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/i-45u-17-08-22-11-58-01-exam-revision.jpg',
  'fuel_type': 'Petrol', 'id': '/partner-one-search/mercedes%20c%20class/1500/75000/1/b'},
  {'title': 'Teachers & Instructors', 'price': 3950,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/i-45u-17-08-22-12-02-39-exam-revision.jpg',
    'fuel_type': 'Petrol', 'id': '/partner-one-search/audi%20q7/3000/75000/1/b'},
  // {'title': 'VW Amarok Highline 1.6 diesel zetec  2008', 'price': 6500,
  //   'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-19-06-21-20-23-26-exam-revision.jpg',
  //   'fuel_type': 'Diesel', 'id': '/partner-one-search/VW%20Amarok%20Highline/3000/75000/1/b'},
  // {'title': 'BMW X6 Sport 163BHP Blue Sunroof', 'price': 11950,
  //   'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-19-06-21-20-19-23-exam-revision.jpg',
  //   'fuel_type': 'Diesel', 'id': '/partner-one-search/bmw%20x6/8000/75000/1/b'},
  // {'title': 'Range Rover Vogue 2014 model', 'price': 9895,
  //   'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-19-06-21-20-20-49-exam-revision.jpg',
  //   'fuel_type': 'Diesel', 'id': '/partner-one-search/Range%20Rover%20Vogue/5000/75000/1/b'},
  // {'title': 'Ford Ranger Wildtrak 97000 miles', 'price': 12495,
  //   'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-19-06-21-20-21-57-exam-revision.jpg',
  //   'fuel_type': 'Petrol', 'id': '/partner-one-search/ford%20ranger/1000/75000/1/b'}
];

export const accorladesData = [{'title': 'Free', 'price': 4500,
  'remote_image': require('../assets/single-elements/icons/icons8-free-65.png'),
  'fuel_type': 'Petrol', 'id': '/partner-one-search/mercedes%20c%20class/1500/75000/1/b'},
  {'title': 'Convenient', 'price': 3950,
    'remote_image': require('../assets/single-elements/icons/icons8-car-shop-100.png'),
    'fuel_type': 'Petrol', 'id': '/partner-one-search/audi%20q7/3000/75000/1/b'},
  {'title': 'Trusted', 'price': 3950,
    'remote_image': require('../assets/single-elements/icons/icons8-shield-64.png'),
    'fuel_type': 'Petrol', 'id': '/partner-one-search/audi%20q7/3000/75000/1/b'}
]

export const homeTechData = [{'title': 'Games console', 'price': 100,
  'remote_image': 'https://i.ebayimg.com/images/g/jrQAAOSwpvZaDCup/s-l500.jpg',
  'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/console/'},
  {'title': 'Apple MacBook Pro', 'price': 250,
    'remote_image': 'https://i.ebayimg.com/images/g/LAoAAOSwhh9c0aHn/s-l500.jpg',
    'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/macbook'},
  {'title': 'Laptops Lenovo Dell', 'price': 95,
    'remote_image': 'https://i.ebayimg.com/images/g/oW0AAOSw0ptbZGPc/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/laptops'},
  {'title': 'Desktop Towers Dual ', 'price': 100,
    'remote_image': 'https://i.ebayimg.com/images/g/Uj4AAOSwFSxaM--k/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/desktop'},
  {'title': 'Apple iMac Machines', 'price': 75,
    'remote_image': 'https://i.ebayimg.com/images/g/bMMAAOSw0lldCnMm/s-l500.jpg',
    'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/mac'},
  {'title': 'Tablets Quad Core', 'price': 35,
    'remote_image': 'https://i.ebayimg.com/images/g/wRcAAOSwOX1eSnvt/s-l500.jpg',
    'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/tablet'}];

export const homeCommercialData = [{'title': 'Live session classes', 'price': 5000,
  'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/i-45u-18-08-22-16-18-50-exam-revision.jpg',
  'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/com-agric'},
  {'title': 'Book a class', 'price': 4000,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/i-45u-18-08-22-16-17-16-exam-revision.jpg',
    'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/com-lorries'},
  // {'title': 'Bus & Coach', 'price': 1000,
  //   'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-19-06-21-20-32-59-exam-revision.jpg',
  //   'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/com-bus'},
  // {'title': 'Trailers & Transporters', 'price': 5000,
  //   'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-19-06-21-20-34-16-exam-revision.jpg',
  //   'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/com-trailers'},
  // {'title': 'Van Pick ups', 'price': 1000,
  //   'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-19-06-21-20-35-57-exam-revision.jpg',
  //   'fuel_type': 'Diesel', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/mac'},
  // {'title': 'Other & Commercial', 'price': 700,
  //   'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-19-06-21-20-37-11-exam-revision.jpg',
  //   'fuel_type': 'Petrol', 'id': '/partner-one-search-cat/cat-search/0/75000/1/b/tablet'}

];
