import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Alert,
} from 'reactstrap';
import 'react-input-range/lib/css/index.css';
import { Link } from 'react-router-dom';
import './ImgAndForm.css';
import {Loading} from "./LoadingComponent";

class ImgAndForm extends Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();

    this.handleSearch = this.handleSearch.bind(this);
    this.keyToOption = this.keyToOption.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleMakeChange = this.handleMakeChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      value4: {
        min: 100,
        max: 5000
      },
      fromPrice: 0,
      toPrice: 1500000,
      makeSelected: null,
      modelSelected: 'any',
      typeSelected: 'cars',
      message: '',
      carPrices: [
        100000,
        150000,
        200000,
        250000,
        300000,
        350000,
        400000,
        450000,
        500000,
        550000,
        600000,
        650000,
        700000,
        750000,
        800000,
        850000,
        900000,
        950000,
        1000000,
        1100000,
        1200000,
        1300000,
        1400000,
        1500000,
        1600000,
        1700000,
        1800000,
        1900000,
        2000000,
        2250000,
        2500000,
        2750000,
        3000000,
        3500000,
        4000000,
        4500000,
        5000000,
        5500000,
        6000000,
        6500000,
        7000000,
        7500000,
        15000000
      ]
    };
  }
  componentDidMount() {
    let payload = {parts: "false", catID: null}
    this.props.getProductCategories(payload)
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  toggle(typeSelected) {
    if (this.state.typeSelected !== typeSelected) {
      this.setState({ typeSelected });
    }
  }

  error(message) {
    this.setState({ message });
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };
  handleMakeChange(event) {
    this.setState({ makeSelected: event.target.value });
  }

  handleModelChange(event) {
    this.setState({ modelSelected: event.target.value });
  }

  handleFromPriceChange = event => {
    this.setState({ fromPrice: event.target.value });
  };
  handleToPriceChange = event => {
    this.setState({ toPrice: event.target.value });
  };

  async handleSearch(event) {
    event.preventDefault();
    let {
      makeSelected,
      fromPrice,
      toPrice
    } = this.state;
    if (makeSelected === null) {
      makeSelected = 'cars'
      // this.error('Make is missing');
      // this.searchRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      // return;
    }
    const query = `search/${makeSelected}/${fromPrice}/${toPrice}`;
    this.props.onSearch(query);
  }

  keyToOption(key) {
    return key
      .split('-')
      .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
      .join(' ');
  }

  render() {
    let  {
      getProductCategoriesLoader,
      getProductCategoriesSuccess,
      getProductCategoriesError
    } = this.props
    let vehicles = []
    let selectedModels = []

    if (getProductCategoriesSuccess) {
      vehicles = getProductCategoriesSuccess.reduce(
        (acc, veh, i) => {
          let make = Object.keys(veh)[0];
          let vehModels = veh[make];
          return {
            makes: [
              ...acc.makes,
              <option key={make + i} value={make}>
                {this.keyToOption(make)}
              </option>
            ],
            models: {
              ...acc.models,
              [make]: [
                <option key={make + i + 0} value="any">
                  Any Model
                </option>,
                vehModels.map((model, i) => {
                  return (
                    <option key={make + model + i} value={model}>
                      {this.keyToOption(model)}
                    </option>
                  );
                })
              ]
            }
          };
        },
        { makes: [], models: [] }
      );

      selectedModels =
        this.state.makeSelected && this.state.makeSelected.length ? (
          vehicles.models[this.state.makeSelected]
        ) : (
          <option selected disabled>
            {/*Model (select make first)*/}
          </option>
        );
    }

    const selectMinValues = this.state.carPrices
      .filter((val) => {
        return val < this.state.toPrice
      })
      .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });

    const selectMaxValues = this.state.carPrices
      .filter((val) => {
        return val > this.state.fromPrice
      })
      .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });

    if (getProductCategoriesLoader) {
      return  (
        <div className="col-lg-12">
          <header className="headerbg d-flex">
            <div
              className="col-sm-12 col-xs-12 offset-lg-0 col-lg-4 col-md-4"
              style={{ margin: '32px 0px'}}
              >
              <div className="container my-auto">
                <div className="bg-text" style={{height: '200px'}}>
                  <Loading />
                </div>
              </div>
            </div>
          </header>
        </div>
      )
    } else if (
      getProductCategoriesError
    ) {
      return  (
        <div className="col-lg-12">
          <header className="headerbg d-flex">
            <div
              className="col-sm-12 col-xs-12 offset-lg-0 col-lg-4 col-md-4"
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            >
              <div className="container my-auto">
                <div className="bg-text" style={{height: '200px'}}>
                  <div className="col-12">
                    <h4 style={{padding: '16px 0px'}}><strong>500.</strong> Thats an error</h4>
                    <p>The server encountered an error and could not complete your request</p>
                    <p>If the problem persist, please report your problem via chat </p>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      )
    } else
    return (
      <div
        className="col-lg-12"
        style={{ padding: '0px' }}
      >
        <header className="headerbg d-flex">
          <div
            className="col-sm-12 col-xs-12 offset-lg-0 col-lg-4 col-md-4"
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
          >
            <div className="container my-auto">
              <div className="bg-text">
                <h5 className="img-form-header"
                    style={{fontSize: '20px', fontWeight: 800, marginLeft: '16px', marginTop: '16px'}}>
                  Search used cars for sale</h5>
                <div
                  className="container"
                  style={{
                    backgroundColor: 'white',
                    padding: '5px 10px 20px 10px',
                    borderRadius: '0px'
                  }}
                >
                  <div className="tab"
                    style={{display: "None"}}
                  >
                    <button
                      id="cars"
                      className={
                        this.state.typeSelected === 'cars' ? 'tab-active' : ''
                      }
                      onClick={() => {
                        this.toggle('cars');
                      }}
                    >
                      Cars
                    </button>
                    <button
                      id="commercial"
                      className={
                        this.state.typeSelected === 'commercials'
                          ? 'tab-active'
                          : ''
                      }
                      onClick={() => {
                        this.toggle('commercials');
                      }}
                    >
                      Commercials
                    </button>
                  </div>
                  <Form
                    onSubmit={this.handleSearch}
                    style={{ marginTop: '20px' }}
                  >
                    <div ref={this.searchRef}>
                    <FormGroup>
                      {this.state.message && (
                        <Alert color="danger">{this.state.message}</Alert>
                      )}
                    </FormGroup>
                    </div>
                    <FormGroup>
                      <Input
                        className="search-make"
                        onChange={e => this.handleMakeChange(e)}
                        type="select"
                        name="q"
                        id="q"
                      >
                        <option selected disabled>
                          All Vehicles Type
                        </option>
                        {vehicles.makes}
                      </Input>

                      <Input
                        // style={{}}
                        onChange={e => this.handleModelChange(e)}
                        type="select"
                        name="m"
                        id="m"
                      >
                        {selectedModels}
                      </Input>
                      <Input
                        placeholder="Select"
                        type="select"
                        name="select"
                        value={this.state.fromPrice}
                        onChange={e => this.handleFromPriceChange(e)}
                        id="exampleSelect"
                        style={{ margin: '10px 0 10px 0' }}
                      >
                        <option selected disabled>
                          Price From
                        </option>
                        {this.state.carPrices
                          .slice(0, this.state.carPrices.length - 1)
                          .map(value => (
                            <option
                              value={`${value}`}
                            >{`From KES${value}`}</option>
                          ))}
                      </Input>
                      <Input
                        defaultValue="Price To"
                        type="select"
                        name="select"
                        value={this.state.toPrice}
                        onChange={e => this.handleToPriceChange(e)}
                        id="exampleSelect"
                        disabled={!this.state.fromPrice}
                      >
                        <option selected disabled>
                          Price To
                        </option>

                        {this.state.carPrices
                          .slice(
                            this.state.carPrices.findIndex(
                              value => value === this.state.fromPrice
                            ) + 1,
                            this.state.carPrices.length
                          )
                          .map(item => (
                            <option value={`${item}`}>{`To KES${item}`}</option>
                          ))}
                      </Input>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: '0.5rem' }}>
                      <Input
                        type="submit"
                        name="search"
                        id="search"
                        className="btn btn-primary"
                        value="Search"
                      />
                    </FormGroup>
                    <Link to="/" className="advance-search">
                      Advanced Search <i className="fas">&#xf105;</i>
                    </Link>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className="home-banner-img col-lg-8 col-md-8">
          </div>
        </header>
        <div className="col-lg-10" />
      </div>
    );
  }
}
// const genSlideStyle = (value) => {
//     return {
//         point: {
//             left: `calc(${value * 20}% - ${5 + 3 * value}px)`,
//         },
//         range: {
//             width: `${value * 20}%`,
//         },
//     };
// };

export default ImgAndForm;
