import React from 'react';

export const Loading = () => {
    return (

        <section className = "gen-section2">
        <div className="container text-center">
            <div className="row">
                <div className="col-12">
                    <span className="fa fa-spinner fa-pulse fa-3x fa-fw blue-loader"></span>
                    <p style={{fontSize: '13px'}} className="mt-2">Loading...</p>
                </div>
            </div>
        </div>
        </section>
    );
};