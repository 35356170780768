
const routeTo = (history, page) => {

  if (page === 'Downline') {
    history.push({
      pathname: "/my-downline",
    })
  }
  if (page === 'Advertising') {
    history.push({
      pathname: "/advertising-tools",
    })
  }
  if (page === 'Training') {
    history.push({
      pathname: "/supporter-training",
    })
  }
  if (page === 'Dashboard') {
    history.push({
      pathname: "/supporter-dashboard",
    })
  }
  if (page === 'Vendor') {
    history.push({
      pathname: "/vendor-dashboard",
    })
  }
  if (page === 'Create') {
    history.push({
      pathname: "/vendor/create-product"
    })
  }
  if (page === 'Spares') {
    history.push({
      pathname: "/vendor/create-spares-listing"
    })
  }
}


export default(routeTo)