export const Ports = [
  {"Tanzania": ["Dar Es Salaam"]},
  {"Zambia": ["Walvis Bay","Durban", "Dar Es Salaam"]},
  {"Malawi": ["Walvis Bay","Dar Es Salaam", "Beira"]},
  {"Moçambique": ["Beira", "Maputo"]},
  {"South Africa": ["Durban"]},
  {"Lesotho": ["Durban"]},
  {"Botswana": ["Walvis Bay","Durban"]},
  {"Zimbabwe": ["Walvis Bay", "Dar Es Salaam", "Durban"]},
  {"Kenya": ["Mombasa"]},
  {"Uganda": ["Mombasa", "Dar Es Salaam"]},
  {"Bangladesh": ["Chittagong"]},
  {"Pakistan": ["Karachi"]},
  {"New Zealand": ["Auckland"]},
  {"Bahamas": ["Nassau"]},
  {"Jamaica": ["Kingston"]}
]

