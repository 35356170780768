import React from "react";
import {Card, CardBody, CardImg, CardSubtitle, CardText} from "reactstrap";
import NumberFormat from "react-number-format";
import {withRouter} from "react-router-dom";

const stringSorten = str => {
  const arrayOfString = str ? str.trim().split(" ") : null;
  if (!arrayOfString) return
  if ((arrayOfString && arrayOfString[0].length > 8) || arrayOfString[0].length > 8) {
    return arrayOfString[0];
  }
  return arrayOfString[0] + " " + arrayOfString[1];
};

const routeToDetails = (props) => {
    const { history } = props;
    history.push(`/item-details/${props.d.id}/${props.d.price}`)

};

const PromotionUpSelling = (props) => {
   return(
     <div onClick={() => routeToDetails(props)} className="col-lg-2 col-md-4 col-6 mt-4" key={props.i}>
       <Card>
         <CardImg
           top
           width="100%"
           className="mx-auto car-img img-responsive"
           src={
             props.d.remote_image.substr(
               0,
               props.d.remote_image.lastIndexOf('/')
             ) + '/s-l225.jpg'
           }
           alt="Card image cap"
         />
         <CardBody style={{ textAlign: 'center' }}>
           <div className="car-body-wrapper cards-body">
             <CardSubtitle>
               {/*eslint-disable-next-line*/}
               <a href="#" className="link-text">
                 {stringSorten(props.d.title)}
               </a>{' '}
             </CardSubtitle>
             <CardText>
               {' '}
               {/*eslint-disable-next-line*/}
               <a
                 href="#"
                 className="home-price"
                 style={{float: 'left', textDecoration: 'line-through', fontSize: '11px', color: '#999', fontWeight: 500}}
               >
                 <NumberFormat
                   value={(props.d.price / 0.9).toFixed(0)}
                   displayType={'text'}
                   thousandSeparator={true}
                   prefix={'$'}
                 />
               </a>


               <a
                 href="#"
                 className="home-price"
                 style={{float: 'right'}}
               >
                 <NumberFormat
                   value={props.d.price}
                   displayType={'text'}
                   thousandSeparator={true}
                   prefix={'$'}
                 />
               </a>

             </CardText>
           </div>

         </CardBody>
         <div style={{
           textAlign: 'center',
           color: '#fff',
           fontSize: '10px',
           backgroundColor: '#009898',
           // backgroundColor: '#B12C2C',
           borderRadius: '2px',
           padding: '4px 6px',
           margin: 'auto' }}>
             Save: 10%
         </div>
       </Card>
      </div>
    );

}

export default withRouter(PromotionUpSelling);