import React, { useState, useEffect } from "react";
import {Col, Row} from "reactstrap";
import Button from '@mui/material/Button';
import ImageGallery from "react-image-gallery";
import NumberFormat from "react-number-format";
import Paper from "@mui/material/Paper";

const VehicleDetailComponent = ({
                                  images,
                                  price,
                                  prodTitle,
                                  subtitle,
                                  model,
                                  year,
                                  engineSize,
                                  fuel,
                                  mileage,
                                  colour,
                                  transmission,
                                  description,
                                  contact,
                                  location
}) => {
  const [revealNumber, setRevealNumber] = React.useState(false);

  return (
    <div>
      <Paper elevation={0} style={{padding: '16px 0px', margin: '40px 0px 80px 0px'}}>
        <Row>
          <Col className="preview-boxes" xs={12} sm={12} md={7} lg={7}>
            <div>
              <ImageGallery
                items={images}
                showPlayButton={false}
              />
            </div>
          </Col>
          <Col className="preview-boxes" xs={12} sm={12} md={5} lg={5}>
            <h5 className="page-header-content">{prodTitle}</h5>
            <h4 className="page-header-content" style={{color: '#0055d4'}}>
              <NumberFormat
                value={price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'KES'} />
            </h4>
            <div style={{color: "#878787"}}>
              {subtitle}
            </div>
            <div style={{color: '#0055d4', paddingTop: '16px'}}>
              {location}
            </div>
            <div>
              <h5
                className="page-header-content"
                style={{paddingTop: "32px"}}
              >
                Specification
              </h5>
              <Row>
                <Col xs="3">
                  <i className="fas fa-car spec-icons"></i>
                </Col>
                <Col xs="auto">
                  {model}
                </Col>
              </Row>
              <Row>
                <Col xs="3">
                  <i className="far fa-calendar-alt spec-icons"></i>
                </Col>
                <Col xs="auto">
                  {year}
                </Col>
              </Row>
              <Row>
                <Col xs="3">
                  <i className="fas fa-cogs spec-icons"></i>
                </Col>
                <Col xs="auto">
                  {engineSize}
                </Col>
              </Row>
              <Row>
                <Col xs="3">
                  <i className="fas fa-gas-pump spec-icons"></i>
                </Col>
                <Col xs="auto">
                  {fuel}
                </Col>
              </Row>
              <Row>
                <Col xs="3">
                  <i className="fas fa-tachometer-alt spec-icons"></i>
                </Col>
                <Col xs="auto">
                  {mileage}
                </Col>
              </Row>
              <Row>
                <Col xs="3">
                  <i className="fas fa-paint-brush spec-icons"></i>
                </Col>
                <Col xs="auto">
                  {colour}
                </Col>
              </Row>
              <Row>
                <Col xs="3">
                  <i className="fas fa-cog spec-icons "></i>
                </Col>
                <Col xs="auto">
                  {transmission}
                </Col>
              </Row>
              <Row>
                <Col xs="3">
                  <i className="fas fa-phone spec-icons"></i>
                </Col>
                {revealNumber ?
                  <Col xs="auto" style={{fontWeight: 800}}>
                    {contact}
                  </Col>
                  :
                  <Col xs="auto">
                    <Button variant="text"
                            onClick={() => { setRevealNumber(true); }}
                    >
                      Click here to reveal phone number
                    </Button>
                  </Col>
                }
              </Row>
            </div>
            <hr style={{borderTop: "1px solid #00000040"}}/>
            <div>
              <h6 className="page-header-content">Seller's description</h6>
              <div style={{minHeight: '80px', whiteSpace: 'pre-wrap'}}>
                {description}
              </div>
            </div>
          </Col>
        </Row>
      </Paper>
    </div>
  )
};

export default VehicleDetailComponent;