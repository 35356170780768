import React, {Component} from "react";

class ChapterTwoComponent extends Component {

  render() {
    return (
      <div style={{paddingBottom: '20px'}}>
        <div className="supporter-program-text">
          <div style={{ paddingBottom: '20px' }}>
            <div style={{ fontSize: "20px", fontWeight: 600 }}>
              How to promote?
            </div>
            <div className="training-headings">
              You have been assigned a promo code and you can access it from your
              <a href="/supporter-dashboard" className="link-text"> dashboard</a> under the "Promo code details" section
            </div>
            <br/>
            <div>
              Distribute this Promo code to your friends and colleagues. They can use it on checkout to get a discount
              and you will earn a commission from the sale
            </div>
          </div>

          <div style={{ paddingBottom: '20px' }}>
            <div style={{ fontSize: "20px", fontWeight: 600 }}>
              Want to earn more?
            </div>
            <div className="training-headings">
              Leverage the <a href="/advertising-tools" className="link-text">Marketing Material here </a>
              and start creating your very own successful business in a few simple steps
            </div>
            <br/>
            <ol>
              <li>
                <span style={{ fontWeight: 800 }}>Download Business cards media</span> and print in order to distribute your contact information and promo code.
                When you meet with a potential client or partner for instance you can leave a business card
                for that person to use in a follow-up call or email
              </li>
              <li>
                <span style={{ fontWeight: 800 }} >Download A5 Flyers media</span> and print in order to distribute your promo code.
                When you meet with a potential client or partner for instance you can leave a business card
                for that person to use in a follow-up call or email
              </li>
              <li>
                If you own a store. <span style={{ fontWeight: 800 }}>Download the banners vinyl media</span> and print in order display on your shop front.
                Up sell CHICARGOS products to walk in customers in store and earn commission for every sale.
              </li>
            </ol>
          </div>
        </div>
      </div>
    )
  }
}

export default ChapterTwoComponent;