import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from 'redux'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem, NavLink, Col, Input, FormGroup, InputGroup, InputGroupText, InputGroupAddon,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { filterAction } from "../store/actions/filterCollapseAction";
import logo from "../assets/images/logo.png"
import { authAction, vehicleAction } from "../store/actions";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';

const links = [
  { href: '/', text: 'Home'},
  { href: '/', text: 'Cars' },
  { href: '/auto-parts', text: 'Parts' },
  { href: '/about-us', text: 'About' }
];

const createNavItem = ({ href, text, className='my-nav-link' }) => (
  <NavItem>
    <NavLink href={href} className={className}>{text}</NavLink>
  </NavItem>
);

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    const { cookies } = props;

    this.state = {
      isOpen: false,
      email: cookies.get("email") || "",
      userLoggedIn: !!cookies.get("email"),
      redirect: false,
      searchInput: "",
      allProductList: [],
      isSidePaneOpen: false,
      dropdownOpen: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.state.email) {
      const email = nextProps.cookies.get("email");
      this.setState({
        email: email || "",
        userLoggedIn: !!email
      });
    }
  }

  toggle() {
    this.props.filterCollapseAction(!this.props.filterReducer);
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  closeNavbar() {
    if (this.state.isOpen === true) {
      this.toggleNav();
    }
  }

  handleSignOut = async event => {
  const { cookies } = this.props;
  const token = localStorage.getItem('token')
  event.preventDefault();
  if (this.props.apiTokenLogout) {
    await this.props.apiTokenLogout({
      cookies,
      sessionToken: {
        token: token
      }
  });

  this.setState({
    userLoggedIn: false,
    email: "",
    redirect: true
  })

  this.props.filterCollapseAction(!this.props.filterReducer);
    this.setState({
      isOpen: !this.state.isOpen
    });
  this.props.history.push('/')
  }
  }


  render() {
    const { isLoggedIn } = this.props.authReducer

    // var location = window.location.pathname;
    return (
      <div>
        <Navbar style={{ backgroundColor: "#1ddce8"}} expand="md">
          <NavbarBrand style={{ color: "#000000"}} href="/" className="logo-right">Magari World</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav  style={{ color: "#000000"}} className="ml-auto" navbar>
              {links.map(createNavItem)}
               <NavItem>
                 {!isLoggedIn && (
                   <NavLink
                     href="/login"
                     onClick={this.closeNavbar}
                     style={{ color: "#000"}}
                     >
                      Log in
                   </NavLink>
                 )}
                 {isLoggedIn && (
                   <NavLink
                     href="/"
                     onClick={this.handleSignOut}
                     style={{ color: "#000"}}
                   >
                     Log out
                   </NavLink>
                   // <Link
                   // to="/"
                   // onClick={this.handleSignOut}
                   // className="fancy">
                   // <span className="fancy-header-items"> Hi, Sign Out</span>
                   // </Link>
                 )}
               </NavItem>
               <NavItem className="my-account">
                 <Link
                   to="/profile"
                   className="fancy fancy-icons">
                   <ManageAccountsRoundedIcon style={{ color: 'black' }}/>
                 </Link>{" "}
               </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filterReducer: state.filterReducer,
  sideMenuReducer: state.sideMenuReducer,
  authReducer: state.authReducer,

});

const mapDispatchToProps = dispatch => {
  return {
    filterCollapseAction: payload =>
      dispatch(filterAction.setFilterCollapse(payload)),
    apiTokenLogout: payload =>
      dispatch(authAction.apiTokenLogout(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(
    withCookies,
    withRouter
)(Header));

